/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc withAllContexts HOC will send all the necessary Context such as AlertContext,
 * DialogContext, BackdropContext and DrawerContext as a prop.
 */

import React from 'react'
import {
  AlertContext,
  DialogContext,
  BackdropContext,
  DrawerContext,
  EntityRelationShipContext,
  EntityGroupRelationShipContext,
  DrawLineContext,
  EntityLeftContext,
  ResouceTemplates,
  CustomTemplate,
  SelectedTemplate,
  RightSideNav,
  MasterTemplates, ConfirmationContext,PropertiesContext,CollapseForEntity,RenameManagement
} from '../contexts'
import { DataJson } from '../contexts/enterprise/contextdata'

const withAllContexts = Component => props => {
  const alert = React.useContext(AlertContext)
  const dialog = React.useContext(DialogContext)
  const backDrop = React.useContext(BackdropContext)
  const drawer = React.useContext(DrawerContext)
  const relationshipcontext = React.useContext(EntityRelationShipContext)
  const grouprelationshipcontext = React.useContext(EntityGroupRelationShipContext)
  const drawLine = React.useContext(DrawLineContext)
  const leftContext = React.useContext(EntityLeftContext)
  const datajson = React.useContext(DataJson)
  const resouceTemplates = React.useContext(ResouceTemplates)
  const customTemplate = React.useContext(CustomTemplate)
  const selectedTemplate = React.useContext(SelectedTemplate)
  const rightNav = React.useContext(RightSideNav)
  const masterDatas = React.useContext(MasterTemplates)
  const confirmationContext = React.useContext(ConfirmationContext)
  const propertiesContext = React.useContext(PropertiesContext)
  const collapseForEntity = React.useContext(CollapseForEntity);
  const renameContext = React.useContext(RenameManagement);
  return (
    <Component
      {...props}
      datajson={datajson}
      leftContext={leftContext}
      drawLine={drawLine}
      resouceTemplates={resouceTemplates}
      customTemplate={customTemplate}
      relationshipcontext={relationshipcontext}
      grouprelationshipcontext={grouprelationshipcontext}
      selectedTemplate={selectedTemplate}
      confirmationContext={confirmationContext}
      rightNav={rightNav}
      alert={alert}
      masterDatas={masterDatas}
      dialog={dialog}
      backDrop={backDrop}
      drawer={drawer}
      queryParams={{}}
      properties={propertiesContext}
      collapseForEntity = {collapseForEntity}
      renameContext= {renameContext}
    >
      {props.children}
    </Component>
  )
}

export default withAllContexts