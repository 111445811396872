/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020 
 * @modify 12/11/2020 
 * @desc Collection of all the Context used in the application
 */

import React from "react";
import { Themes, AlertProps, DrawerProps } from "../utils";

/**
 * ThemeContext store the current theme of the app,which is provided 
 * at the /src/App.js using the /src/App.theme.js. 
 */
export let ThemeContext = React.createContext({
  name: Themes.default,
  setTheme: () => null,
});

/**
 * AuthContext store some basic detail of the user when the user logged
 * into the application, which is provided at the /src/App.js using 
 * the /src/App.auth.js.
 */
export let AuthContext = React.createContext({
  user: {},
  setAuth: () => null,
});

/**
 * AlertContext store the props which are neccessary to show the Alert component, 
 * which is provided at the /src/App.js using the /src/App.alert.js.
 */
export let AlertContext = React.createContext({
  open: false,
  severity: AlertProps.severity.success,
  msg: "",
  vertical: AlertProps.vertical.top,
  horizontal: AlertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
});

/**
 * DialogContext store the props of a Dialog, which is provided at the /src/App.js using 
 * the /src/App.dialog.js
 */
export let DialogContext = React.createContext({
  open: true,
  title: "",
  body: "",
  positiveBtn: "Ok",
  negativeBtn: "Cancel",
  onOk: () => null,
  setDialog: () => null,
});

/**
 * BackdropContext store the props of the Backdrop Component, which is provided at the /src/App.js using 
 * the /src/App.backdrop.js
 */
export let BackdropContext = React.createContext({
  open: true,
  message: "",
  setBackDrop: () => null
})

/**
 * DrawerContext store the props of the Drawer component, which is provided at the /src/App.js using 
 * the /src/App.drawer.js
 */
export const DrawerContext = React.createContext({
  open: true,
  direction: DrawerProps.direction.right,
  variant: DrawerProps.variant.temporary,
  onClose: () => null,
  setDrawer: () => null
})


/**
 * entity list data maintain
 */
export let EntityLeftContext = React.createContext({
  leftEntity: {},
  setEntity: () => null,
});

// Entity relationship data maintain
export let EntityRelationShipContext = React.createContext({
  relation: [],
  setRelation: () => null,
});


export let EntityGroupRelationShipContext = React.createContext({
  grouprelation: [],
  setGroupRelation: () => null,
});



/** 
* draw line from canvas function call based on count 
* and clear lines function call based on clearcount
 */
export let DrawLineContext = React.createContext({
  count: 0,
  clearCount: 0,
  setCount: () => null,
});

export let DrawLineGroupContext = React.createContext({
  groupcount: 0,
  cleargroupCount: 0,
  setgroupCount: () => null,
});
/**
 * zoom entity based on zoom count
 */
export let ZoomContext = React.createContext({
  zoom: { zoom: 1 },
  setZoom: () => null,
});


/**
 * json comparison context
 */
export let JsonCheckerContext = React.createContext({
  jsonChecker: {},
  setJsonChecker: () => null,
});


/* send properties on edit data */
export let PropertiesContext = React.createContext({
  properties: { isopen: false, editFieldData: {}, editTable: {},databaseProperties:[] },
  setProperties: () => null,
});

/* Right side side nav property hide and show */
export let RightSideNav = React.createContext({
  isopen: false,
  action: () => null
})

/* Entity default templates */
export let ResouceTemplates = React.createContext({
  resources: [],
  setResouceTemplate: () => null
})
/* Entity custom templates */
export let CustomTemplate = React.createContext({
  customTemplate: [],
  setCustom: () => null
})

/* Selected templates */
export let SelectedTemplate = React.createContext({
  templateId: null,
  isDefault: null,
  setTemplateId: () => null
})

/* Selected templates */
export let MasterTemplates = React.createContext({
  masters: null,
  masterEntity: null,
  setMasters: () => null
})

/* confirmation templates */
export let ConfirmationContext = React.createContext({
  open: false,
  title: null,
  dialog: null,
  type: null,
  entity: null,
  data: null,
  setConfirm: () => null
})


/* Selected templates */
export let CollapseForEntity = React.createContext({
  listOfCollapse: [],
  setCollaps: () => null
});
/* Selected templates */
export let LoadingManagement = React.createContext({
  isLoading: false,
  setLoading: () => null
});
//renameManagement
export let RenameManagement = React.createContext({
  rename: [],
  setRenameObj: () => null
});