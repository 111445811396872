import React from 'react';
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Grid, Paper
} from '@material-ui/core';


function ConfirmationBox(props) {
    const { open, title, dialog } = props;
    const handleClose = () => {
        props.handleCloseConfirmation();
        
    };
    const onSuccess = async() => {
        props.onDeleteSuccess();
    };

   

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Grid style={{ width: 450 }}>
                    {title}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{dialog}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleClose()} color="primary"> Cancel </Button>
                <Button onClick={() => onSuccess()} color="secondary"> delete </Button>
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmationBox;