import React from "react";
import PropTypes from "prop-types";
import { create } from "jss";
import { withStyles, jssPreset } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import NoSsr from "@material-ui/core/NoSsr";
import rtl from "jss-rtl";
import Frame from "react-frame-component";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    height: 10000,
    width: 5000,
    border: "none",
    boxShadow: theme.shadows[1]
  },
  canvasData : {
    backgroundImage: "url(/images/background.png)",
  }
});

class Iframe extends React.Component {
  state = {
    ready: false
  };

  handleRef = ref => {
    this.contentDocument = ref ? ref.node.contentDocument : null;
    this.contentWindow = ref ? ref.node.contentWindow : null;
  };

  onContentDidMount = () => {
    this.setState({
      ready: true,
      jss: create({
        plugins: [...jssPreset().plugins, rtl()],
        insertionPoint: this.contentWindow["entity-middle-component"]
      }),
      sheetsManager: new Map(),
      container: this.contentDocument.body
    });
  };

  onContentDidUpdate = () => {
    this.contentDocument.body.dir = this.props.theme.direction;
  };

  render() {
    const { children, classes,zoom } = this.props;

    // NoSsr fixes a strange concurrency issue with iframe and quick React mount/unmount
    return (
      <NoSsr>
        <Frame
          ref={this.handleRef}
          className={classes.root + ' IframeData'}
          contentDidMount={this.onContentDidMount}
          contentDidUpdate={this.onContentDidUpdate}
        >
          <div id="entity-middle-component" />
          {this.state.ready ? (
            <StylesProvider
              jss={this.state.jss}
              sheetsManager={this.state.sheetsManager}
            >
              {React.cloneElement(
                <React.Fragment>
                  {/* <canvas id="canvas" style={{ backgroundImage: "url(/images/background.png)" }} height={2400} width={2400} /> */}
                  <canvas id="canvas"  height={5000} width={10000} style={{zoom:zoom?.zoom}}/>

                  {children}
                </React.Fragment>
                , {
                  container: this.state.container
                })}
            </StylesProvider>
          ) : null}
        </Frame>
      </NoSsr>
    );
  }
}

Iframe.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  zoom: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(Iframe);
