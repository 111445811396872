/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from 'react'
import { Button, Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  CollapseListCard,
  Properties,
  Relationship,
  History,
  Alerts,
  GroupRelationship
} from '../../../components'
import { PropertiesContext, RightSideNav } from '../../../contexts'
import { converData } from '../../../contexts/entity'
import { withAllContexts } from '../../../HOCs'
import axios from 'axios';
import environment from '../../../config';
import { relationSplit, grouprelationSplit } from '../../../functions';
import { parse, stringify } from 'flatted';
import { removeData, defaultFieldAdd } from "../../../functions/common";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    minHeight: 'calc(100vh - 90px)',
    maxHeight: 'calc(100vh - 90px)',
    overflow: 'auto',
    borderRadius: 0
  },
  saveButtonPadding: {
    margin: '10px 0px 0px 10px',
    top: '48px',
    position: 'absolute',

    borderRadius: '7px',
    right: '150px'
  },
  LockButtonPadding: {
    margin: '10px 0px 0px 10px',
    top: '48px',
    position: 'absolute',

    borderRadius: '7px',
    right: '75px'
  },
  fontFormat: {
    textTransform: "capitalize"
  }
})

class RightCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackopen: false,
      snackmsg: 'Please select field first',
      snacktype: 'error',
      saveopen: false,
      property: "",
      autoHideDuration: 2000,
      isSave: true,
      isLock: true
      // validatePath:[]
    }
    this.closeProperties = this.closeProperties.bind(this);
    this.propertiesChanges = this.propertiesChanges.bind(this);
  }
  handleClick(num) {
    let { open } = this.state
    open = num === open ? null : num
    this.setState({
      open
    })
  }
  closeProperties() {
    let context = this.context
    if (context.editFieldData?.attributes?.id) {
      context.setProperties({
        ...context,
        isopen: !context.isopen
      })
    } else {
      this.setState({
        snackopen: true,
        snackmsg: 'Please select field first',
        snacktype: 'error'
      })
    }
  }
  closeSnackBar() {
    this.setState({ snackopen: false, saveopen: false })
  }
  validateIndexs = (fields) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.indexes?.length > 0) {
        let index_ids = fields[i].indexes.filter(_ => _.indexType.length <= 0).map(_m => _m.fieldId);
        ;
        let pathData = this.checkIndexFields(fields[i].fields, index_ids);
        ;
        let is_error = false;
        let errorPath = [];
        for (let pat in pathData) {
          if (pathData[pat].error) {
            errorPath = pathData[pat].path;
            errorPath.unshift(fields[i].entity);
            is_error = true;
          }
        }
        if (is_error) {
          return {
            is_error: is_error,
            path: errorPath
          }
        }
      }

    }
    return {
      is_error: false,
      path: []
    }
  }
  checkIndexFields = (field, index_ids, fieldpath = {}) => {
    ;
    for (let i = 0; i < field.length; i++) {
      if (!fieldpath[i]) {
        fieldpath[i] = { error: false, path: [] };

      }
      if (field[i].attributes !== undefined) {
      if (index_ids.indexOf(field[i].attributes.id) > -1) {
        fieldpath[i].path.push(field[i].name);
        fieldpath[i].error = true;

        return fieldpath
      }
    }
      if (field[i].fieldType === 'd') {

        fieldpath[i].path.push(field[i].name);
        this.checkIndexFields(field[i].properties.fields, index_ids, fieldpath);
      }
    }
    return fieldpath
  }
  validatePath = [];
  is_diamention_error = false;
  is_Sequence_error = false;
  //is_groupBy_error = false;
  validateDiamention = (fields) => {
    for (let i = 0; i < fields.length; i++) {
      let pathData = this.fieldCheck(fields[i].fields);
      for (let pat in pathData) {
        if (pathData[pat].error) {
          this.validatePath = pathData[pat].path
          this.validatePath.unshift(fields[i].entity)
        }
      }
      if (this.is_diamention_error) {
        break;
      }
      else if (this.is_Sequence_error) {
        break;
      }
      // else if (this.is_groupBy_error) {
      //   break;
      // }

    }
  }



  fieldCheck = (field, fieldpath = [], parent = null) => {
    for (let i = 0; i < field.length; i++) {
        if (!parent) {
            fieldpath[i] = { error: false, path: [] };
            parent = i;
        }

        if (field[i].fieldType === 'd') {
            if (field[i].properties.fieldCollection === false) {
                if (field[i].properties.isdimensionedge === true || field[i].properties.ref_entity.length > 0) {
                    fieldpath[parent].path.push(field[i].name);
                    this.fieldCheck(field[i].properties.fields, fieldpath, parent);
                } else {
                    fieldpath[parent].error = true;
                    fieldpath[parent].path.push(field[i].name);
                    this.is_diamention_error = true;
                    break;
                }
            } else {
                fieldpath[parent].path.push(field[i].name);
                this.fieldCheck(field[i].properties.fields, fieldpath, parent);

            }
            // this.validatePath[i] = fieldpath;

        }

        else if (field[i].properties.datatype === "string" || field[i].properties.datatype === "number" || field[i].properties.datatype === "integer" || field[i].properties.datatype === "positiveint" || field[i].properties.datatype === "unsignedint") {
          
            if (field[i].properties.Sequence !== undefined) {
                if (field[i].properties.Sequence.IsReset === true) {
                    if (field[i].properties.Sequence.reset_config !== undefined) {
                        if (field[i].properties.Sequence.reset_config.resetType !== undefined) {
                            if (field[i].properties.Sequence.reset_config.resetType === "custom") {
                                if (field[i].properties.Sequence.reset_config.resetvalue === "") {
                                    fieldpath[parent].error = true;
                                    fieldpath[parent].path.push(field[i].name);
                                    this.is_Sequence_error = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            // if(field[i].properties.Sequence !== undefined)
            // {
            //   if(field[i].properties.Sequence.groupBy !== undefined)
            //   {
            //     if([undefined , "" , null ].includes(field[i].properties.Sequence.groupBy.groupType) )
            //     {
            //       fieldpath[parent].error = true;
            //       fieldpath[parent].path.push(field[i].name);
            //       this.is_groupBy_error = true;
            //       break;

            //     }

            //     else if([undefined , "" , null ].includes(field[i].properties.Sequence.groupBy.groupQuery))
            //     {
            //       fieldpath[parent].error = true;
            //       fieldpath[parent].path.push(field[i].name);
            //       this.is_groupBy_error = true;
            //       break;
            //     }
            //   }

              
             
            // }
            
        }
        
       

    }
    return fieldpath
}
  handleRename = () => {
    let { renameContext } = this.props;

    return new Promise(async (resolve, reject) => {

      if (renameContext.rename.length > 0) {

        let result = await Promise.all(renameContext.rename.map(_r => {
          return axios
            .post(`${environment.api_url}/api/rename_collection`, {
              "db_name": _r.db_name,
              "entity": _r.entity,
              "renameEntity": _r.renameEntity,
              "metadata_dbname": `${environment.database}`
            })
        }))
          .then(_d => {
            renameContext.setRenameObj({
              rename: []
            });

            resolve(true);

          })

          .catch((error) => {

            resolve(false);

          })
      } else {

        resolve(true);
      }

    })
  }





  async deleteGroupListss(deletelistsss) {
    deletelistsss.map(async (deleteee) => {
      let datass = {
        db_name: `${environment.database}`,
        entity: 'entity_grouprelation_stg',
        is_metadata: false,
        filter: {
          groupId: `${deleteee.GroupId}`
        },
        doc: {
          active: "N"
        }
      }
      let data = JSON.stringify([datass])
      var config = {
        method: 'post',
        url: `${environment.api_url}/api/upsert_document`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }

      await axios(config)
        .then(async response => {

        })
      let publish_relation = {
        "db_name": `${environment.database}`,
        "entity": "entity_grouprelation_published",
        "filter": `entity_grouprelation_published.groupId=='${deleteee.GroupId}'`,
        "metadata_dbname": `${environment.database}`
      };

      await axios.post(`${environment.api_url}/api/delete_document`, publish_relation)
        .then(async res => {


        })

    })

  }


  async saveEntity() {
    const { leftContext, relationshipcontext, collapseForEntity, grouprelationshipcontext } = this.props
    let { list } = leftContext
    let values = Object.values(list)
    let datalist = []
    if (!values && values.length <= 0) {
      this.setState({
        saveopen: true,
        snackmsg: `Please create entity widget`,
        snacktype: 'info',
        autoHideDuration: 2000
      })
      return;
    }
    values.map(val => {
      if (val) {
        datalist = [...datalist, ...val]

      }
      return false
    });
    datalist = defaultFieldAdd(datalist);
    datalist = removeData(datalist);



    if (sessionStorage.getItem('is_db') === 'true') {
      let splitrelation = relationSplit(relationshipcontext.list)
      let splitgrouprelation = grouprelationSplit(grouprelationshipcontext.list)

      let payload = {
        client:
          sessionStorage.clientName && sessionStorage.clientName !== 'undefined'
            ? sessionStorage.clientName
            : 'test',
        db_name:
          sessionStorage.clientDB && sessionStorage.clientDB !== 'undefined'
            ? sessionStorage.clientDB.replace(/\s/g, "_")
            : 'test',
        clientId:
          sessionStorage.clientId && sessionStorage.clientId !== 'undefined'
            ? sessionStorage.clientId
            : '',
        metadataId:
          sessionStorage.getItem('metadataId') &&
            sessionStorage.getItem('metadataId') !== 'undefined'
            ? sessionStorage.getItem('metadataId')
            : '',
        metadataname:
          sessionStorage.getItem('metadataname') &&
            sessionStorage.getItem('metadataname') !== 'undefined'
            ? sessionStorage.getItem('metadataname')
            : '',
        attributes: [{ relationship: splitrelation?.dontsend ?? [], listOfCollapse: collapseForEntity?.listOfCollapse ?? [] }],
        metadata: datalist,
        entity_relationship: splitrelation.send,

        entity_grouprelationship: splitgrouprelation.send,
        projectId:
          sessionStorage.getItem('projectId') &&
            sessionStorage.getItem('projectId') !== 'undefined'
            ? sessionStorage.getItem('projectId')
            : '',
        metadata_dbname: environment.database

      }

      // let checkEntityFields = datalist.every(fieldList=>fieldList?.fields?.length>0);
      // if(!checkEntityFields){
      if (datalist.length <= 0) {
        this.setState({
          saveopen: true,
          snackmsg: 'Please  add entity',
          snacktype: 'info',
          autoHideDuration: 2000

        });
        return;
      }


      this.validatePath = [];
      this.is_diamention_error = false;
      this.is_Sequence_error = false;
      //this.is_groupBy_error = false;
      this.validateDiamention(datalist)
      if (this.is_diamention_error) {
        this.setState({
          saveopen: true,
          snackmsg: ` ${this.validatePath.join('/')} property dimension field ref entity should not be empty`,
          snacktype: 'info',
          autoHideDuration: 4000
        })
        return;
      }
      else if (this.is_Sequence_error) {
        this.props.alert.setSnack({
          open: true,
          msg: `${this.validatePath.join('/')} Is Sequence custome date field cannot be empty `,
          severity: 'info'
        });

        return;
      }
    //   else if (this.is_groupBy_error) {
         
    //     this.props.alert.setSnack({
    //         open: true,
    //         msg: `${this.validatePath.join('/')} Group Type or Group Query Cannot be empty`,
    //         severity: 'info'
    //     });

    //     return;
    // }
      let indexCheckFields = this.validateIndexs(datalist)
      if (indexCheckFields?.is_error) {
        this.setState({
          saveopen: true,
          snackmsg: ` ${indexCheckFields.path.join('/')} index field should not be empty`,
          snacktype: 'info',
          autoHideDuration: 4000
        })
        return;
      }
      let re_success = await this.handleRename();
      if (!re_success) {
        return;
      }
      let clone_pay = stringify(payload);
      clone_pay = { ...parse(clone_pay) };
      this.setState(prevState => ({
        ...prevState,
        isSave: false
      }))

      await axios
        .post(`${environment.api_url}/api/save_schema`, clone_pay)
        .then(async res => {
          // let value = sessionStorage.metaId;

          if (res.data.Code === 201) {
            // if (grouprelationshipcontext.deletelistsss !== undefined) {
            //   if (grouprelationshipcontext.deletelistsss.length > 0) {

            //     this.deleteGroupListss(grouprelationshipcontext.deletelistsss);
            //   }
            // }
            let value = res.data.clientdb.Result[0].properties.doc.metadataId

            await axios
              .post(`${environment.api_url}/api/get_schema`, {
                filter: {
                  columname: 'metadataId',
                  operator: 'equals',
                  value: value
                },
                metadata_dbname: environment.database
              })
              .then(response => {

                let data = converData(response.data)
                sessionStorage.setItem('metaId', response.data.Result.metadataId)
                sessionStorage.setItem('metadataId', response.data.Result.metadataId)

                sessionStorage.setItem(
                  'metadataname',
                  response.data.Result.metadataname
                )
                sessionStorage.setItem(
                  'entityCode',
                  response.data.Result.metadata[0].entitycode
                )
                sessionStorage.setItem(
                  'entity',
                  response.data.Result.metadata[0].entity
                )

                let { setEntity } = leftContext
                let { setRelation } = relationshipcontext;
                let { setgroupRelation } = grouprelationshipcontext;
                let { setListOfEntityCollaps } = collapseForEntity;
                setEntity({
                  ...leftContext,
                  list: data.entity,
                  saveToPulishParams: response?.data?.Result
                })
                let setRelationship = data.relation;
                if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                  let Multirelations = response.data.Result.attributes[0].relationship;
                  setRelationship = [...setRelationship, ...Multirelations]
                }
                setRelation({
                  ...relationshipcontext,
                  list: setRelationship
                });

                let setgroupRelationship = data.grouprelation;
                if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                  let Multirelations = response.data.Result.attributes[0].relationship;
                  setgroupRelationship = [...setgroupRelationship, ...Multirelations]
                }
                setgroupRelation({
                  ...grouprelationshipcontext,
                  list: setgroupRelationship
                });
                setListOfEntityCollaps({
                  ...collapseForEntity,
                  listOfCollapse: response?.data?.Result?.attributes?.[0]?.listOfCollapse ?? []
                })
                this.setState({
                  saveopen: true,
                  snackmsg: 'entity saved successfully',
                  snacktype: 'success',
                  autoHideDuration: 2000,
                  isSave: true

                })
              })
              .catch(err => {
                this.setState({
                  saveopen: true,
                  snackmsg: res.data.error,
                  snacktype: 'error',
                  autoHideDuration: 2000,
                  isSave: true

                })
              })
          } else {
            this.setState({
              saveopen: true,
              snackmsg: res.data.error,
              snacktype: 'error',
              autoHideDuration: 2000,
              isSave: true

            })
          }


        })
        .catch(err => {
          console.log("err", err)
          this.setState({
            saveopen: true,
            snackmsg: 'Entity is already exists ',
            snacktype: 'error',
            autoHideDuration: 2000
          })
        })
    }

  }
  closeVersion(version) {
    console.log(version)
    version.setRightSideNav({
      isopen: true,
      isVersion: !version.isVersion
    })
  }
  propertiesChanges(data) {
    let propertyContext = this.context;
    propertyContext.setProperties({
      ...data
    });
    this.setState({
      property: { ...data }
    });
  }
  render() {
    let { classes, c } = this.props
    let { isopen } = this.context
    let { snackopen, snackmsg, snacktype, saveopen, autoHideDuration, isSave, isLock } = this.state
    return (
      <RightSideNav.Consumer>
        {rightNav => (
          <React.Fragment>
            {saveopen && (
              <Alerts
                severity={snacktype}
                open={saveopen}
                vertical={'top'}
                horizontal={'right'}
                msg={snackmsg}
                autoHideDuration={autoHideDuration}
                onclose={() => this.closeSnackBar()}
              />
            )}
            {
              isSave ? <Button
                onClick={() => this.saveEntity()}
                size='small'
                className={classes.saveButtonPadding + ' ' + classes.fontFormat}
                variant='contained'
                color='secondary'

              >
                Save
              </Button> :
                <Button
                  // onClick={() => this.saveEntity()}
                  size='small'
                  className={classes.saveButtonPadding + ' ' + classes.fontFormat}
                  variant='contained'
                  color='secondary'
                >
                  Save
                  <CircularProgress style={{ width: "20px", height: "20px", margin: "auto", color: "white" }} color="secondary" />
                </Button>
            }


            {rightNav.isopen ? (
              <Grid container className={classes.root}>
                <Grid item md={12}>
                  <Paper className={classes.paper}>
                    <CollapseListCard
                      title={'Properties'}
                      iconRight={true}
                      isOpenCollapse={isopen}
                      onClickCollapse={this.closeProperties}
                      // defultOpen={true}
                      textStyle={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: 'black'
                      }}
                      collapseChildren={<Properties datalist={this.context} propertiesChanges={this.propertiesChanges} />}
                    />
                    <CollapseListCard
                      title={'Version History'}
                      iconRight={true}
                      isOpenCollapse={rightNav.isVersion}
                      onClickCollapse={() => this.closeVersion(rightNav)}
                      textStyle={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: 'black'
                      }}
                      collapseChildren={<History />}
                    />
                    <CollapseListCard
                      title={'Relationship'}
                      iconRight={true}
                      textStyle={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: 'black'
                      }}
                      collapseChildren={<Relationship />}
                    />
                    <CollapseListCard
                      title={'Group Relationship'}
                      iconRight={true}
                      textStyle={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: 'black'
                      }}
                      collapseChildren={<GroupRelationship />}
                    />
                    {/* <Button onClick={() => this.saveEntity()} size="small" className={classes.saveButtonPadding} variant="contained" color="secondary">Save</Button> */}
                  </Paper>
                </Grid>
                {snackopen && (
                  <Alerts
                    severity={snacktype}
                    open={snackopen}
                    vertical={'top'}
                    horizontal={'right'}
                    msg={snackmsg}
                    onclose={() => this.closeSnackBar()}
                  />
                )}
              </Grid>
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      </RightSideNav.Consumer>
    )
  }
}
RightCard.contextType = PropertiesContext
export default withStyles(useStyles)(withAllContexts(RightCard))
