/**
 * @author prakash p
 * @email prakash@qdmplatforms.com
 * @create 16/03/2021
 * @desc Exporting Enterprise Main section components from /src/components
 */

import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Body from './body';
import { withAllContexts } from "../../../HOCs";

// styles of component
const useStyles = theme => ({
  root: {
    flexGrow: 1,
    height: '85vh',
    with: '100%'
  }
})
// end

class EnterpriseMainsection extends React.Component {
  // state of the component
  constructor (props) {
    super(props)
    this.state = {}
  }
  //   end

  componentDidMount () {}

  render () {
    //   props variables of the components
    let { classes } = this.props

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Body {...this.props} />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(useStyles)(withAllContexts(EnterpriseMainsection))
// export of component
