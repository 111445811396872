import environment from '../config';
import axios from 'axios';

export const publishSplit = (metaData, metaid, dataList) => {
    return new Promise(async (resolve, reject) => {
        var temparray = [];
        temparray = metaData.filter(val => val.attributes.id === dataList.attributes.id)
        let calls = temparray.map(async (val) => {
            // return val;
            return await publishSchema(val, metaid)
        })
        Promise.all(calls).then(async (data) => {
            // let value = metaid;
            // let readData = await ExecuteAql();
            resolve(true)
        }).catch(e => {
            resolve(null)
        })
    })
}

export const publishSchema = (metaData, metaid) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            "client": sessionStorage.clientName && sessionStorage.clientName !== 'undefined'
                ? sessionStorage.clientName
                : '',
            db_name: "QDMMasters",
            clientId: sessionStorage.clientId && sessionStorage.clientId !== 'undefined'
                ? sessionStorage.clientId
                : '',
            metadataId: metaid,
            metadataname: "QDMMasters",
            attributes: [],
            "active": "Y",
            metadata: Array.isArray(metaData) ? metaData : [metaData],
            projectId: sessionStorage.getItem('projectId') &&
                sessionStorage.getItem('projectId') !== 'undefined'
                ? sessionStorage.getItem('projectId')
                : '',
            projectname: sessionStorage.getItem('metadataname') &&
                sessionStorage.getItem('metadataname') !== 'undefined'
                ? sessionStorage.getItem('metadataname')
                : '',
            entity_relationship: [],
            metadata_dbname:environment.database

        }
        await axios
            .post(`${environment.api_url}/api/publish_schema`, payload)
            .then(async res => {
                resolve(true);
            })
            .catch(err => {
                resolve(null);
            })
    })
}

export const getUploadedArray = (arr) => {
    return arr.filter(val => val.entity)
}

export const SaveSchema = (metaData, metaid) => {
    return new Promise(async (resolve, reject) => {
        let payload = {
            client: sessionStorage.clientName && sessionStorage.clientName !== 'undefined'
                ? sessionStorage.clientName
                : 'test',
            db_name: "QDMMasters",
            clientId: sessionStorage.clientId && sessionStorage.clientId !== 'undefined'
                ? sessionStorage.clientId
                : '',
            metadataId: metaid,
            metadataname: "QDMMasters",
            projectname: sessionStorage.getItem('metadataname') &&
                sessionStorage.getItem('metadataname') !== 'undefined'
                ? sessionStorage.getItem('metadataname')
                : '',
            attributes: [],
            metadata: metaData,
            entity_relationship: [],
            projectId: sessionStorage.getItem('projectId') &&
                sessionStorage.getItem('projectId') !== 'undefined'
                ? sessionStorage.getItem('projectId')
                : '',
                metadata_dbname:environment.database
            
        }

        await axios
            .post(`${environment.api_url}/api/save_schema`, payload)
            .then(async res => {
                let value = res.data.clientdb.Result[0].properties.doc.metadataId
                let readData = await readSchema(value);
                let dataarr = await publishSplit(readData.data?.Result?.metadata, metaid, metaData[0])
                resolve(dataarr);
            })
            .catch(err => {
                resolve(null);
            })
    })
}

export const readSchema = (value) => {
    return new Promise(async (resolve, reject) => {
        await axios
            .post(`${environment.api_url}/api/get_schema`, {
                filter: {
                    columname: 'metadataId',
                    operator: 'equals',
                    value: value
                },
                metadata_dbname:environment.database
            })
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                resolve(null);
            })
    })
}

export const ExecuteAql = (value) => {
    return new Promise(async (resolve, reject) => {

        await axios
            .post(`${environment.api_url}/api/execute_aql`, {
                "db_name": "QDMMasters",
                "query": "FOR coll in COLLECTIONS() FILTER !STARTS_WITH(coll.name,'_') RETURN coll"
            })
            .then(response => {

                resolve(response.data)
            })
            .catch(err => {

                resolve(null);
            })
    })
}

export const getCollectionData = (name) => {
    return new Promise(async (resolve, reject) => {
        await axios
            .post(`${environment.api_url}/api/execute_aql`, {
                "db_name": "QDMMasters",
                "query": `RETURN (${name})`
            })
            .then(response => {

                resolve(response.data)
            })
            .catch(err => {
                resolve(null);
            })
    })
}


export const saveResourceDataTypes = (props) => {
    return new Promise(async (resolve, reject) => {
        const { customTemplate } = props.customTemplate;
        const { resources } = props.resouceTemplates;
        await saveData(customTemplate, "DataTypes", "a14917a7-3a25-4053-ab3b-dbda7076c838", "dataTypeId", "158df5b0-422e-4fb4-99cf-921b8ad45c72")
        await saveData(resources, "Resources", "dad8c864-dc51-4907-b0f7-0df9b6cb6f98", "resouceId", "80797261-9fb6-45cf-9d68-e5ec50cf270b")
        resolve(true)
    })
}


const saveData = (metaData, dbName, metaid, enName, enid) => {
    return new Promise(async (resolve, reject) => {

        let dataList = {
            db_name: `${environment.database}`,
            entity: dbName,
            is_metadata: false,
            projectname: "QdmMasterEntity",
            doc: {
                metadataId: metaid,
                [enName]: enid,
                projectname: "QdmMasterEntity",
                attributes: metaData
            },
        };
        let data = JSON.stringify([dataList]);
        var config = {
            method: "post",
            url: `${environment.api_url}/api/upsert_document`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        await axios(config)
            .then(async res => {
                resolve(true);
            })
            .catch(err => {
                resolve(null);
            })
    })
}