/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Login Screen which shown when user in login route(/login)
 */

import React from 'react';

class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return <div>Login</div>
    }
}

export default Login;