/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc withNavBars HOC will give you the Navbars (Top/Bottom Navbars)
 */

import React from "react";
import { makeStyles, Grid, Paper, Typography, Avatar } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1,
    width: '100%',
    height: "46px",
    lineHeight: "46px",
    alignItems: 'center'
  },
  content: {
    width: '100%'
  },
  paper: {
    borderRadius: 0
  },
  title: {
    padding: "5px 10px",
    color:"#0b78fa"
    // color: "#5d5df7"
  },
  username: {
    fontSize: 14,
    fontWeight: 600,
    margin: "auto"
  },
  rounded: {
    width: "22px",
    height: "22px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "blue"
  },
  rightalign: {
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "36px 105px 26px",
    float: "right",
    padding: "8px"
  },
  textName:{
    background: "#0a78fa",
    width: "102px",
    textAlign: "center",
    /* top: 13px; */
    marginTop: "2px",
    color: "white",
    fontSize: "13px"
  },
  TextValue:{
    paddingLeft: "10px"
  }
}));


const withNavBars = (Component) => (props) => {
  console.log('withnavbars',Component);
  const classes = useStyles();
  debugger;
  return (
    <div className={classes.root}> 
      {/* Your nav bars here */}
      <Paper className={classes.paper}>
        <Grid container className={classes.root1} >
          <Grid item md={2}>
            <Typography variant="h6" className={classes.title} >QDM</Typography>
          </Grid>
          <Grid item md={8} style={{display:'flex'}}>
            <Grid item md={4}>
                <Typography  className={classes.textName}>
                  Client Name
                </Typography>
                <Typography className={classes.TextValue} >
                  {sessionStorage.getItem('clientName')
                    ? sessionStorage.getItem('clientName')
                    : 'Foodzo v0.1'}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Typography  className={classes.textName}>
                  Project Name
                </Typography>
                <Typography className={classes.TextValue} >
                  {sessionStorage.getItem('metadataname')
                    ? sessionStorage.getItem('metadataname')
                    : 'Foodzo v0.1'}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Typography  className={classes.textName}>
                  Database Name
                </Typography>
                <Typography  className={classes.TextValue}>
                  {sessionStorage.getItem('clientDB')
                    ? sessionStorage.getItem('clientDB')
                    : 'Foodzo v0.1'}
                </Typography>
            </Grid>
          </Grid>
          {/* <Grid item md={6}>
            <div className={classes.rightalign}>
              <Avatar variant="rounded" className={classes.rounded}>DP</Avatar>
              <Typography variant="h6" className={classes.username} >Dmirch Preach</Typography>
              <ExpandMoreIcon />
            </div>
          </Grid> */}
        </Grid>
      </Paper>
      {/* Content */}
      <div className={classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
