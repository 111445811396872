/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { EntityLeftContext } from '../../../contexts'
import { MiddleMiniCard, MiddleExpandCard } from './'
import Xarrow from 'react-xarrows';
const useStyles = ((theme) => ({

}));

class MiddleData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let { handleRealatioShipOpen , handleGroupRealatioShipOpen } = this.props;
        let { list, selected } = this.context;
         
         
        return (
            <React.Fragment>
                
                <Grid container>
                    {
                        list && Object.keys(list)?.length>0 &&Object.keys(list).map((val, i) => {
                             
                            return <React.Fragment key={i}>
                                {
                                    list[val] && list[val].map((value, index) => {
                                        return <React.Fragment key={index}>
                                            {!value.attributes.isDontShow && <React.Fragment>
                                                {(value.id === selected || true) ? <MiddleExpandCard handleRealatioShipOpen={handleRealatioShipOpen} handleGroupRealatioShipOpen={handleGroupRealatioShipOpen} tableIndex={index} data={value} /> : <MiddleMiniCard data={value} tableIndex={index} />}
                                            </React.Fragment>}
                                        </React.Fragment>
                                    })
                                }


                            </React.Fragment>
                        })
                    }
             
      
                           {/* <Xarrow
          start={"8c2a0399-1421-4f43-9c44-30282424e9ab"}
          end={"c7409cd4-fc1e-4ae8-a284-2f239bd0f34c"}
          // key={ar.start + '-.' + ar.start}
        //   path={'smooth'}
        //   strokeWidth={3}
        //   lineColor={'#007aff'}
        //   color={'#007aff'}
        //   headColor={'#007aff'}
        //   style={{ position: 'relative' }}
          // passProps={{ onClick: e => MorePop(e, ar, i) }}
        /> */}
                </Grid>

                {/* <Grid container>
                    {
                        list && Object.keys(list)?.length>0 &&Object.keys(list).map((val, i) => {
                            return <React.Fragment key={i}>
                                {
                                    list[val] && list[val].map((value, index) => {
                                        return <React.Fragment key={index}>
                                            {!value.attributes.isDontShow && <React.Fragment>
                                                {(value.id === selected || true) ? <MiddleExpandCard handleGroupRealatioShipOpen={handleGroupRealatioShipOpen} tableIndex={index} data={value} /> : <MiddleMiniCard data={value} tableIndex={index} />}
                                            </React.Fragment>}
                                        </React.Fragment>
                                    })
                                }
                            </React.Fragment>
                        })
                    }     
                </Grid> */}
                {/* {
                        list && Object.keys(list)[0]&&Object.keys(list)[0].length >1  ?
                        list && Object.keys(list).map((val, i) => {
                            return <React.Fragment key={i}>
                                {
                                    list[val] && list[val].map((value, index) => {
                                        return <React.Fragment key={index}>
                                            {(value.id === selected || true) ? <MiddleExpandCard tableIndex={index} data={value} /> : <MiddleMiniCard data={value} />}
                                        </React.Fragment>
                                    })
                                }
                            </React.Fragment>
                        }) : 
                        list && list.length>0&&list.map((value, index) => {
                            return <React.Fragment key={index}>
                                {(value.id === selected || true) ? <MiddleExpandCard tableIndex={index} data={value} /> : <MiddleMiniCard data={value} />}
                            </React.Fragment>
                        })
                    } */}
            </React.Fragment>
        )
    }
}

MiddleData.contextType = EntityLeftContext;

export default withStyles(useStyles)(MiddleData);