
export function getTemplatesList(props, isTemplate, isResource, isAll) {
    let { customTemplate } = props.customTemplate
    const { resources } = props.resouceTemplates;
    let { templateId } = props.selectedTemplate
    let list = [];
    if (!isResource) {
        customTemplate.map(val => {
            if (isAll || (val.attributes.id !== templateId || !isTemplate)) {
                list.push(createArrayJson(val, val.entity, val.attributes.id))
            }
            return false
        })
    }
    resources.map(val => {
        if (isAll || (val.attributes.id !== templateId || !isTemplate)) {
            list.push(createArrayJson(val, val.entity, val.attributes.id))
        }
        return false
    })
    return list;
}

const createArrayJson = (data, label, value) => {
    return {
        ...data,
        label,
        value
    }
}