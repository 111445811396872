import XLSX from 'xlsx';
import { uuid } from 'uuidv4';

const getJson = (file) => {
    return new Promise(async (resolve, reject) => {
        var f = file;
        var name = f.name;
        const reader = new FileReader();
        reader.onload = async (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            var json = convertToJson(data); // shows data in json format
            resolve(json)
        };
        reader.readAsBinaryString(f);
    })
}

export const loopFiles = (files, customTemplates, ismaster, isresource) => {
    return new Promise(async (resolve, reject) => {
        var calls = files.map(async (val) => {
            return await getJson(val)
        })
        Promise.all(calls).then(async (data) => {

            if (!ismaster) {
                const entities = getAllEntity(data);
                let list = [];
                Object.values(entities).map(val => {
                    let tableId = uuid();
                    let entity = getEntity(val[0].ResourceName, tableId, null, isresource, val[0].Category)
                    entity.fields = loopFields(val[0].fields, tableId, val[0].ResourceName, entities, customTemplates)
                    list.push(entity)
                })
                resolve(list);
            } else {
                let array = await getAllMasters(data[0])
                resolve(array);
            }
        })
            .catch(err => {
                resolve(err);
                console.log(err);
            })
    })
}

const checkDataType = (Name) => {
    if (!Name) {
        return Name;
    }
    let list = ["required", "description", "title", "type"]
    if (list.indexOf(Name) > -1) {
        return capitalizeFirstLetter(Name)
    }
    return Name
}

const loopFields = (arr, id, name, entities, customTemplates, isloop) => {
    let fields = [];
    arr.map(val => {
        let type = val.Datatype
        let columnName = checkDataType(val.Name)
        let Types = val.Type || val.Types;
        let ref = Types === "code" ? val["LOVReferenceCollection"] : null
        let Lov = {
            IsLOV: val["IsLOV"] === "TRUE" ? true : false,
            LOVType: val["LOV Type"],
            LOV_ref_collection: val["LOVReferenceCollection"]?.replace(/\s/g, ""),
            LOV_ref_fields: val["LOV Key Field"],
            LOV_return_field: val["LOV_return_field"]
        }
        let dimension = false;
        let entityFields = checkCustomEntities(capitalizeFirstLetter(Types), customTemplates);
        if (entityFields) {
            dimension = true
            let field1 = getField(columnName, type, dimension, id, name, ref, Lov);
            field1.properties.fields = entityFields
            if (val.Nestedcolumn === name) {
                fields.push(field1)
            } else {
                pushFields(fields, field1, val.Nestedcolumn)
            }
        } else if (entities[capitalizeFirstLetter(Types)]) {
            dimension = true
            let field2 = getField(columnName, type, dimension, id, name, ref, Lov);
            field2.properties.fields = loopFields(entities[capitalizeFirstLetter(Types)][0].fields, id, name, entities, customTemplates, true)
            if (val.Nestedcolumn === name) {
                fields.push(field2)
            } else {
                pushFields(fields, field2, val.Nestedcolumn)
            }
        } else {
            if (val.Nestedcolumn === name || isloop) {
                let field3 = getField(columnName, type, dimension, id, name, ref, Lov)
                fields.push(field3)
            } else {
                let field4 = getField(columnName, type, dimension, id, name, ref, Lov)
                pushFields(fields, field4, val.Nestedcolumn)
            }
        }
    })
    return fields
}

const pushFields = (arr, field, name) => {
    arr.map((val, i) => {
        if (val.name === name) {
            let f = arr[i].properties.fields ? JSON.parse(JSON.stringify(arr[i].properties.fields)) : [];
            f.push(field)
            arr[i].properties.fields = f
        }
    })

    return arr;
}

const checkCustomEntities = (name, customTemplates) => {
    let fields = null;
    customTemplates.map(val => {
        if (name === val.entity) {
            fields = val.fields
        }
    })
    return fields;
}

const capitalizeFirstLetter = (string) => {
    let name = "";
    try {
        name = string.charAt(0).toUpperCase() + string.slice(1);
    } catch (e) {
        name = ""
    }
    return name;
}

const getEntity = (title, id, data, isresources, catagory) => {
    let values = {};
    if (data) {
        values = { data };
    }
    var isresource = {}
    if (isresources) {
        isresource = { isresource: true }
    }
    if (catagory) {
        isresource = { ...isresource, Category: catagory }
    }
    return {
        entity: title,
        "status": "draft",
        "db_objectname": title.trim().replace(/\s/g, '_'),
        "entity_group_name": "others",
        fields: [],
        ...values,
        attributes: {
            ...isresource,
            id: id,
            selected_color: "red"
        }
    }
}

const getField = (title, type, isdimension, id, tableName, ref, lov) => {
    return {
        name: title,
        properties: {
            datatype: isdimension ? "dimension" : type,
            fieldCollection: (isdimension || type === "dimension") ? true : false,
            fields: [],
            "IsLOV": lov.IsLOV ? lov.IsLOV : false,
            "LOVType": lov?.LOVType ? lov?.LOVType : null,
            "LOV_ref_collection": lov?.LOV_ref_collection ? lov?.LOV_ref_collection : null,
            "LOV_ref_fields": lov?.LOV_ref_fields ? lov?.LOV_ref_fields : null,
            "LOV_return_field": lov?.LOV_return_field ? lov?.LOV_return_field : null,

        },
        fieldType: (isdimension || type === "dimension") ? "d" : "f",
        attributes: {
            reference: ref,
            id: uuid(),
            table: tableName,
            tableID: id,
            selected_color: "red"
        }
    }
}

const getAllEntity = (data) => {
    var arr = [];

    data[0].map(val => {
        if (val.Name) {
            arr.push(val)
        }
    })
    let entities = {}
    var index = null;
    arr.map(val => {
        if (val.ResourceName) {
            index = val.ResourceName
            entities[index] = [];
            entities[index].push(val)
            entities[index][0].fields = []
        }
        else {
            if (entities[index] && entities[index][0] && entities[index][0].fields) {
                entities[index][0].fields.push(val)
            }
        }
    })
    return entities
}

const convertToJson = (csv) => {
    return new Promise(async (resolve, reject) => {

        var lines = csv.split("\n");

        var result = [];
        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j] ? currentline[j].replace(/\|/g, ",") : currentline[j];
            }

            result.push(obj);
        }
        //return result; //JavaScript object
        resolve(result); //JSON
    })
}

const getAllMasters = (array) => {
    return new Promise(async (resolve, reject) => {
        let data = {};
        array.map(val => {
            if (val.Terminology) {
                data[val.Terminology] = data[val.Terminology] ? data[val.Terminology] : [];
                data[val.Terminology].push(getMasters(val))
            }
        })
        let masterEntity = GetMasterEntity(data);
        resolve({ data, masterEntity });
    })
}

const getMasters = (val) => {
    return {
        // value: val.Code,
        Code: val.Code,
        Definition: val.Definition,
        Display: val.Display,
        // label: val.Display,
        Terminology: val.Terminology,
        language: val.Language ? val.Language : "en"
    }
}

const GetMasterEntity = (data) => {
    let allEntity = [];
    Object.keys(data).map(val => {
        var tableId = uuid();
        let entity = getEntity(val, tableId, data[val]);
        entity.fields = LoopMaster(FieldsArray, tableId, val, tableId)
        allEntity.push(entity)
    })
    return allEntity
}

const LoopMaster = (arr, id, name) => {
    let list = [];
    arr.map(val => {
        list.push(getField(val, "String", false, id, name))
    })
    return list
}

const FieldsArray = ["Code", "Definition", "Display", "Terminology", "language"]