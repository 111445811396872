export const style = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: "calc(113vh - 176px)",
        overflow: "auto",
        padding: "10px"
    },
    AddentityField: {
        textAlign: "right",
        cursor: "pointer"
    },
    entityRoot: {
        display: "grid",
        gridTemplateColumns: "1fr 44px",
        backgroundColor: "#bbbbbb",
        // borderRadius: "7px",
        borderTopLeftRadius: "7px",
        borderTopRightRadius: "7px",
        padding: "12px 9px"
    },
    entityHeader: {
        borderRadius: "7px",
        padding: "4px",
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
    },
    fieldRoot: {
        border: "1px solid #bbbbbb",
        borderBottomLeftRadius: "7px",
        borderBottomRightRadius: "7px"
    },
    listData: {
        display: "grid",
        gridTemplateColumns: "1fr 77px",
        // gridTemplateColumns: "1fr 1fr 50px",
        padding: "12px 9px"
    },
    borderTopLine: {
        borderTop: "1px solid #bbbbbb"
    },
    alignRight: {
        textAlign: "right"
    },
    templateTable: {
        marginTop: 30
    },
    large: {
        maxWidth: 860,
        minWidth: 860,
        background: "#fff",
        overflowX: 'hidden',
        padding: "30px"
    }
    // jsonInput: {
    //     height: "100%"
    // }
})