/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Typography, Grid, Popover, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AddEntity, CollapseListCard, ListCard, EntityFilter } from '../../../components';
import { EntityLeftContext } from '../../../contexts';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FilterListIcon from '@material-ui/icons/FilterList';
const useStyles = ((theme) => ({
    bottomParenticon: {
        display: 'flex',
        margin: "auto 0px 0px 0px",
        borderTop: "0.5px solid #f3ecec"
    },
    topIcon: {
        margin: "12px auto",
        display: "inline",
        width: "auto"
    },
    bottomIcon: {
        margin: "12px auto",
        display: "inline",
        width: "auto",
        cursor: "pointer"
    },
    newIcon: {
        fontSize: "40px"
    },
    leftTitle: {
        fontSize: "15px",
        fontWeight: 600,
        display: "inline"
    },
    leftTitleIcon: {
        float: "right"
    },
    leftTitleParent: {
        margin: "13px 10px"
    },
    Filtercard: {
        "& .MuiPopover-paper": {
            borderRadius: "10px"
        }
    },
    notificationIcon:{
        marginLeft: "5px",
        background: "blue",
        color: "white",
        borderRadius: "50%",
        width: "18px",
    height: "18px !important"
       
    },
    notificationFont:{
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center"
    }
}));

class ExpandCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            filterAnchorEl: null,
            open: null
        }
        this.handleClose = this.handleClose.bind(this)
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    OpenFilter = (event) => {
        this.setState({ filterAnchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    filterClose = () => {
        this.setState({ filterAnchorEl: null });
    };
    countList = (list) =>{
        let count = 0;
        if(list){
            for(let obj in list){
                if(list[obj] && list[obj]?.length>0){
                    count += list[obj]?.length;
                }
            }

        }
        return count;
    }
    render() {
        let { classes } = this.props;
        let { anchorEl, filterAnchorEl } = this.state;
        let { list, selected } = this.context;
        return (
            <React.Fragment>
                <div className={classes.topParent}>

                    <Grid className={classes.leftTitleParent}>
                        <Grid style={{display:"flex"}}>
                            <Typography className={classes.leftTitle}>Entity</Typography>
                            <Grid className={classes.notificationIcon}>
                               <Typography className={classes.notificationFont}>{this.countList(list)}</Typography>
                            </Grid>
                        </Grid>
                        {/* <FilterListIcon onClick={this.OpenFilter} className={classes.leftTitleIcon} /> */}
                    </Grid>
                    <Divider />
                    <Grid>
                        <CollapseListCard
                            title={"PERSONAS"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["personas"]} />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg')} />}
                            NotificationCount={list?.["personas"] &&list?.["personas"].length>0 ? list["personas"].length :'0' }
                        />
                        <CollapseListCard
                            title={"THINGS"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["things"]} />}
                            // icon={<CancelPresentationIcon />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg')} />}
                            NotificationCount={list?.["things"] &&list?.["things"].length>0 ? list["things"].length :'0' }

                        />
                        <CollapseListCard
                            title={"ECOSYSTEM"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["ecosystem"]} />}
                            // icon={<CancelPresentationIcon />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg')} />}
                            NotificationCount={list?.["ecosystem"] &&list?.["ecosystem"].length>0 ? list["ecosystem"].length :'0' }

                        />
                        <CollapseListCard
                            title={"PRODUCTS & SERVICES"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["productServices"]} />}
                            // icon={<CancelPresentationIcon />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg')} />}
                            NotificationCount={list?.["productServices"] &&list?.["productServices"].length>0 ? list["productServices"].length :'0' }

                        />
                        <CollapseListCard
                            title={"OTHERS"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["others"]} />}
                            // icon={<CancelPresentationIcon />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg')} />}
                            NotificationCount={list?.["others"] &&list?.["others"].length>0 ? list["others"].length :'0' }

                        />
                    </Grid>
                </div>

                <div className={classes.bottomParenticon} onClick={this.handleClick}>
                    <div className={classes.bottomIcon}>
                        <Typography color="secondary" className={classes.leftTitle}>+ New Entity</Typography>
                    </div>
                </div>
                <Popover
                    id={'add_entity'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                >
                    <AddEntity isHeight={true} onclose={this.handleClose} />
                </Popover>
                {/* <Popover
                    id={'filter'}
                    className={classes.Filtercard}
                    open={Boolean(filterAnchorEl)}
                    anchorEl={filterAnchorEl}
                    onClose={this.filterClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                >
                    <EntityFilter onclose={this.filterClose} />
                </Popover> */}
            </React.Fragment>
        )
    }
}

ExpandCard.contextType = EntityLeftContext;

export default withStyles(useStyles)(ExpandCard);