import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import { TemplateRightCard, TemplateMaincard } from './'
import { withAllContexts } from '../../HOCs';
const useStyles = makeStyles(theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "416px 1fr"
    }
}))

function EntityTemplate(props) {
     
    const [isRight, setIsRight] = React.useState(props.isRight)
    const classes = useStyles()
    return <React.Fragment>
        <Grid container className={classes.root}>
            <Grid >
                <TemplateRightCard setValue={props.setValue} setIsRight={setIsRight} />
            </Grid>
            <Grid md={12} >
                {isRight === false ? '' : <TemplateMaincard setIsRight={setIsRight} />}
            </Grid>
        </Grid>
    </React.Fragment>
}

export default withAllContexts(EntityTemplate);