import { uuid } from 'uuidv4';

const entityJson = (title, fromTableId) => {
    return {
        entity: title,
        status: "draft",
        db_objectname: title,
        entity_group_name: "others",
        isActivity: false,
        isAcceptUserInput:false,
        activity: {},
        fields: [],
        attributes: {
            id: uuid(),
            baseTableId: fromTableId,
            left: 10,
            top: 10,
            type: { label: "Others", value: "others" },
            permission: {},
            isDontShow: true,
            style: {
                color: "red"
            }
        }
    }
}

const loopFiles = (relations, id, tableName, newEntity) => {
    let list = [];
    let relationList = [];
    relations.map(val => {
        let newField = {
            name: val.targetTable.entity + "_" + val.targetTableColumn.name,
            properties: {
                datatype: "String",
                fields: []
            },
            fieldType: "f",
            attributes: {
                id: uuid(),
                table: tableName,
                tableID: id,
                selected_color: "red"
            }
        }
        relationList.push(createRelationShip(newEntity, newField, val.targetTable, val.targetTableColumn))
        list.push(newField)
    })
    return { list, relationList }
}


export const loopRelationsShip = (relation, entity, field) => {
     
    let name = entity.entity + '_' + field.name + "_connections";
    let newEntity = entityJson(name, entity.attributes.id);
    let { list, relationList } = loopFiles(relation, newEntity.attributes.id, newEntity.entity, newEntity);
    newEntity.fields = list;
    let relations = relationList;
    let newField = createFields(entity.entity + '_' + field.name, newEntity.attributes.id, newEntity.entity)
    newEntity.fields.push(newField)
    relations.push(createRelationShip(entity, field, newEntity, newField))
    return { relations, newEntity }
}


const createFields = (title, id, tableName) => {
    return {
        name: title,
        properties: {
            datatype: "String",
            fields: []
        },
        fieldType: "f",
        attributes: {
            id: uuid(),
            table: tableName,
            tableID: id,
            selected_color: "red"
        }
    }
}

const createRelationShip = (entity, currentField, targetTable, targetTableColumn) => {
    return {
        attributes: {
            cuurententity: entity,
            targetTable: targetTable,
            targetTableColumn: targetTableColumn,
            currentField: currentField,
            id: uuid(),
            isDontShow: true,
        },
        status: "draft",
        entity: entity.entity,
        version: "1.0",
        target_entity: targetTable.entity,
        relationship: `${entity.entity}.${currentField.name} ${entity.entity + "_" + targetTable.entity} ${targetTable.entity}.${targetTableColumn.name}`,
        db_objectname: entity.entity + "_" + targetTable.entity
    }
}

export const relationSplit = (relation) => {
      
    let send = relation.filter(val => !val.attributes?.isDontSend)
     
    let dontsend = relation.filter(val => val.attributes?.isDontSend);
     
    return { dontsend, send }
}

export const grouprelationSplit = (grouprelation) => {
    let send = grouprelation.filter(val => val.isDontSend === false);
    let dontsend = grouprelation.filter(val => val.attributes?.isDontSend);
     
    return { dontsend, send }
     
}