/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/14/202 
 * @modify 12/14/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Divider, Grid, Paper, Typography, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Alerts } from '../../../components';
import { relationSplit , grouprelationSplit } from '../../../functions';
import { ZoomContext, RightSideNav } from '../../../contexts';
import axios from 'axios';
import { converData } from '../../../contexts/entity';
import { withAllContexts } from '../../../HOCs';
import environment from '../../../config';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = ((theme) => ({
    paperCard: {
        position: "absolute",
        bottom: "17px",
        display: "grid",
        gridTemplateColumns: "1fr 22px 1fr 22px 1fr",
        width: "50%",
        left: "21%",
        padding: "14px 3px 14px 20px",
        // height:"10%"
    },
    textAlign: {
        display: "grid",
        gridTemplateColumns: "33px 1fr",
        cursor: "pointer"
    },
    zoomParent: {
        position: "absolute",
        bottom: "16px",
        // right: "263px"
    },
    avatar: {
        background: "white",
        color: "black",
        cursor: "pointer"
    },
    paperZoomCard: {
        marginBottom: "10px",
        borderRadius: "45px",
        border: "1px solid #f7f4f4"
    },
    rightNav1: {
        right: "313px",
    },
    rightNav2: {
        right: "20px",
    },
    bottomIconBG:{
        background: "#f50057",
    color: "white",
    fontSize: "1rem"
    },
    btnHover:{
        "&:hover":{
            background: "#f24a58",
            display: "flex",
            alignItems: "center",
            color: "white",
            borderRadius: "10px"
        }
    }
   
}));
class BottomCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            snackopen: false,
            snackmsg: "",
            snacktype: "success",
            isPublish:true
        }
        this.publishEntity.bind(this)
    }
    clickZoom(clickpoint) {
        let { zoom, setZoom } = this.context;
        switch (clickpoint) {
            case 1:
                setZoom({
                    ...this.context,
                    zoom: zoom + 0.1
                })
                break
            case 2:
                setZoom({
                    ...this.context,
                    zoom: zoom - 0.1
                })
                break
            default:
                setZoom({
                    ...this.context,
                    zoom: 1
                })
                break
        }
    }
    closeSnackBar() {
        this.setState({ snackopen: false })
    }

    async publishEntity() {
        // alert('kjfdkf')
         
        const { leftContext, relationshipcontext,collapseForEntity, grouprelationshipcontext } = this.props; 
         
        let { list, saveToPulishParams } = leftContext;   
        let values = Object.values(list);
         
        let datalist = [];
         
        values.map(val => {
            datalist = [...datalist, ...val]
            return false
        })
         
        if (sessionStorage.getItem('metadataId') && sessionStorage.getItem('metadataId') !== 'undefined' && sessionStorage.getItem('metadataname') && sessionStorage.getItem('metadataname') !== 'undefined') {
            this.setState(prevState=>({
                ...prevState,
                isPublish:false
            }))
             
            let splitrelation = relationSplit(relationshipcontext.list)
             
            let payload = {
                "client": "CRAYOND1",
                "db_name": "DB_CRAYOND1",
                "metadataId": sessionStorage.getItem('metadataId'),
                "metadataname": sessionStorage.getItem('metadataname'),
                "attributes": [{ relationship: splitrelation.dontsend,listOfCollapse:collapseForEntity?.listOfCollapse ?? []  }],
                "metadata": datalist,
                "entity_relationship": splitrelation.send
            }
             
            await axios
                .post(`${environment.api_url}/api/publish_schema`, {...saveToPulishParams ,  metadata_dbname:environment.database}).then(async (res) => {
                    // let value = sessionStorage.metaId;
                    // let value = res.data.Result[0].properties.doc.metadataId;
                    if(res.data.Code===201){

                            let value = sessionStorage.getItem('metadataId');
                            console.log(res.data)
                            await axios
                                .post(`${environment.api_url}/api/get_schema`, {
                                    filter: {
                                        columname: 'metadataId',
                                        operator: 'equals',
                                        value: value
                                      },
                                      metadata_dbname: environment.database
                                }
                                ).then(response => {
                                        let data = converData(response.data);
                                        let { setEntity } = leftContext;
                                        let { setRelation } = relationshipcontext;
                                        let { setgroupRelation } = grouprelationshipcontext;
                                        let { setListOfEntityCollaps } =collapseForEntity;
                                         
                                        setEntity({
                                            ...leftContext,
                                            list: data.entity
                                        })
                                        let setRelationship = data.relation;
                                        if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                            let Multirelations = response.data.Result.attributes[0].relationship;
                                            setRelationship = [...setRelationship, ...Multirelations]
                                        }
                                        setRelation({
                                            ...relationshipcontext,
                                            list: setRelationship
                                        })

                                        let setGroupRelationship = data.grouprelation;
                                        if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                            let Multirelations = response.data.Result.attributes[0].relationship;
                                            setGroupRelationship = [...setGroupRelationship, ...Multirelations]
                                        }
                                        setgroupRelation({
                                            ...grouprelationshipcontext,
                                            list: setGroupRelationship
                                        })
                                        setListOfEntityCollaps({
                                            ...collapseForEntity,
                                            listOfCollapse:response?.data?.Result?.attributes?.[0]?.listOfCollapse  ?? []
                                          })
                                        this.setState({
                                            snackmsg: "published successfully", 
                                            snackopen: true, 
                                            snacktype: "success",
                                            isPublish:true
                                        })
                    
                                    
                                })
                                .catch(err => {
                                    this.setState({
                                        saveopen: true,
                                        snackmsg: 'Network call error',
                                        snacktype: 'error',
                                        autoHideDuration:2000,
                                        isPublish:true

                                    })
                                });
                    } else {
                        this.setState({ snackmsg: res.data.error, snackopen: true, snacktype: "error",
                        isPublish:true
                    })

                    }
                })
                .catch(err => {
                    this.setState({
                        saveopen: true,
                        snackmsg: 'Network call error',
                        snacktype: 'error',
                        autoHideDuration:2000,
                        isPublish:true

                      })
                });
        } else {
            this.setState({ snackmsg: "please select entity", snackopen: true, snacktype: "info" });

        }

    }
    // async publishEntity() {
     
         
    //     const { leftContext, relationshipcontext,collapseForEntity,grouprelationshipcontext } = this.props; 
    //     let { list, saveToPulishParams } = leftContext;
    //     let values = Object.values(list);
    //     let datalist = [];
         
    //     values.map(val => {
    //         datalist = [...datalist, ...val]
    //         return false
    //     })
         
    //     if (sessionStorage.getItem('metadataId') && sessionStorage.getItem('metadataId') !== 'undefined' && sessionStorage.getItem('metadataname') && sessionStorage.getItem('metadataname') !== 'undefined') {
    //         this.setState(prevState=>({
    //             ...prevState,
    //             isPublish:false
    //         }))
             
    //         let splitrelation = relationSplit(relationshipcontext.list)
    //         let splitgrouprelation = grouprelationSplit(grouprelationshipcontext.list);
             
    //         let payload = {
    //             "client": sessionStorage.getItem('clientName'),
    //             "db_name": sessionStorage.getItem('clientDB'),
    //             "metadataId": sessionStorage.getItem('metadataId'),
    //             "metadataname": sessionStorage.getItem('metadataname'),
    //             "attributes": [{ relationship: splitrelation.dontsend,listOfCollapse:collapseForEntity?.listOfCollapse ?? []  }],
    //             "metadata": datalist,
    //             "entity_relationship": splitrelation.send,
    //             "entity_grouprelationship":splitgrouprelation.send
    //         }

             
             
    //         await axios
    //             .post(`${environment.api_url}/api/publish_schema`, {...saveToPulishParams, metadata_dbname:environment.database }).then(async (res) => {
    //                 // let value = sessionStorage.metaId;
    //                 // let value = res.data.Result[0].properties.doc.metadataId;
                      
    //                 if(res.data.Code===201){

    //                         let value = sessionStorage.getItem('metadataId');
    //                         console.log(res.data)
    //                         await axios
    //                             .post(`${environment.api_url}/api/get_schema`, {
    //                                 "filter": {
    //                                     "columname": "metadataId",
    //                                     "operator": "equals",
    //                                     "value": value
    //                                 },
    //                                 metadata_dbname:environment.database
    //                             }
    //                             ).then(response => {
    //                                     let data = converData(response.data);
    //                                     let { setEntity } = leftContext;
    //                                     let { setRelation } = relationshipcontext;
    //                                     let {setgroupRelation} = grouprelationshipcontext;
    //                                     let { setListOfEntityCollaps } =collapseForEntity;
                                        

    //                                     setEntity({
    //                                         ...leftContext,
    //                                         list: data.entity
    //                                     })
    //                                     let setRelationship = data.relation;
    //                                     if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
    //                                         let Multirelations = response.data.Result.attributes[0].relationship;
    //                                         setRelationship = [...setRelationship, ...Multirelations]
    //                                     }
    //                                     setRelation({
    //                                         ...relationshipcontext,
    //                                         list: setRelationship
    //                                     })
                                        
    //                                     if(data.grouprelation){
    //                                         let setGroupRelationship = data.grouprelation;
    //                                         if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
    //                                             let Multirelations = response.data.Result.attributes[0].relationship;
    //                                             setGroupRelationship = [...setGroupRelationship, ...Multirelations]
    //                                         }
    //                                         setgroupRelation({
    //                                             ...grouprelationshipcontext,
    //                                             list: setGroupRelationship
    //                                         });
    //                                     }
    //                                     else{
    //                                         setgroupRelation({
    //                                             ...grouprelationshipcontext,
    //                                         });
    //                                     }
    //                                     setListOfEntityCollaps({
    //                                         ...collapseForEntity,
    //                                         listOfCollapse:response?.data?.Result?.attributes?.[0]?.listOfCollapse  ?? []
    //                                       })
    //                                     this.setState({
    //                                         snackmsg: "published successfully", 
    //                                         snackopen: true, 
    //                                         snacktype: "success",
    //                                         isPublish:true
    //                                     })
                    
                                    
    //                             })
    //                             .catch(err => {
    //                                 this.setState({
    //                                     saveopen: true,
    //                                     snackmsg: 'Network call error',
    //                                     snacktype: 'error',
    //                                     autoHideDuration:2000,
    //                                     isPublish:true

    //                                 })
    //                             });
    //                 } else {
    //                     this.setState({ snackmsg: res.data.error, snackopen: true, snacktype: "error",
    //                     isPublish:true
    //                 })

    //                 }
    //             })
    //             .catch(err => {
    //                 this.setState({
    //                     saveopen: true,
    //                     snackmsg: 'Network call error',
    //                     snacktype: 'error',
    //                     autoHideDuration:2000,
    //                     isPublish:true

    //                   })
    //             });
    //     } else {
    //         this.setState({ snackmsg: "please select entity", snackopen: true, snacktype: "info" });

    //     }

    // }
    render() {
        // let context = this.context;
        let { snackopen, snackmsg, snacktype,isPublish } = this.state;

        let { classes } = this.props;
        return (
            <RightSideNav.Consumer>
                {
                    rightSideNav => (
                        <React.Fragment>


                            <Grid container>
                                <Paper className={classes.paperCard}>
                                    {/* <Grid className={classes.textAlign}>
                                        <ChatOutlinedIcon />
                                        <img src={require('../../../assets/icons-8-comments.svg')} style={{ paddingTop: "4px" }} />
                                        <Typography>Comments</Typography>
                                    </Grid> */}
                                    {/* <Divider orientation="vertical" />
                                    <Grid className={classes.textAlign}>
                                        <RadioButtonCheckedIcon color="primary" />
                                        <Typography>Color</Typography>
                                    </Grid> */}
                                    {/* <Divider orientation="vertical" /> */}
                                    {/* <Grid className={classes.textAlign}>
                                        <ScreenShareIcon />
                                        <img src={require('../../../assets/icons-8-share-rounded.svg')} />
                                        <Typography>Share</Typography>
                                    </Grid>
                                    <Divider orientation="vertical" /> */}
                                    {
                                        isPublish ? 
                                        <Grid className={classes.textAlign} onClick={this.publishEntity.bind(this)}>
                                        {/* <PublicIcon /> */}
                                        <img src={require('../../../assets/icons-8-submit-document.svg')} />
                                        <Typography>Publish</Typography>
                                    </Grid>
                                        :
                                        <Grid className={classes.textAlign}>
                                        {/* <PublicIcon /> */}
                                        <img src={require('../../../assets/icons-8-submit-document.svg')} />
                                        <Typography>Publish
                                        <CircularProgress style={{width: "16px",height: "12px",margin: "auto"}} color="secondary" />

                                        </Typography>

                                    </Grid>
                                    }
                                
                                    
                                </Paper>
                            </Grid>
                            <Grid className={classes.zoomParent + ' ' + `${rightSideNav.isopen ? classes.rightNav1 : classes.rightNav2}`}>
                                <Paper className={classes.paperZoomCard}>
                                    <Avatar onClick={() => this.clickZoom(1)} className={classes.avatar + ' ' + classes.bottomIconBG}>
                                        +
                        </Avatar>
                                </Paper>
                                <Paper className={classes.paperZoomCard}>
                                    <Avatar onClick={() => this.clickZoom(2)} className={classes.avatar+ ' ' + classes.bottomIconBG}>
                                        -
                        </Avatar>
                                </Paper>
                                <Paper className={classes.paperZoomCard}>
                                    <Avatar onClick={() => this.clickZoom(3)} className={classes.avatar+ ' ' + classes.bottomIconBG}>
                                        {/* <ZoomOutMapOutlinedIcon /> */}
                                        <img src={require('../../../assets/icons8-full-screen.svg')} />

                                    </Avatar>
                                </Paper>
                                {snackopen && <Alerts severity={snacktype} open={snackopen} vertical={'top'} horizontal={'right'} msg={snackmsg} onclose={() => this.closeSnackBar()} />}
                            </Grid>
                        </React.Fragment>
                    )
                }
            </RightSideNav.Consumer>

        )
    }
}
BottomCard.contextType = ZoomContext;
// BottomCard.contextType  = RightSideNav;
export default withStyles(useStyles)(withAllContexts(BottomCard));