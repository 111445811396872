export const converData = (data) => {
    debugger
    let datalist = {}
    debugger
    data.Result.metadata.map(val => {
        datalist[val.entity_group_name] = datalist[val.entity_group_name] ? datalist[val.entity_group_name] : [];
        datalist[val.entity_group_name].push(val);
        return false
    })
    debugger
    return {
        relation : data.Result.entity_relationship,
        grouprelation:data.Result.entity_grouprelationship,
        entity : datalist
    }
}
export const converEntityData = (data) => {
    let datalist = {}
    data.map(val => {
        datalist[val.entity_group_name] = datalist[val.entity_group_name] ? datalist[val.entity_group_name] : [];
        datalist[val.entity_group_name].push(val);
        return false
    })
    return datalist;
}