import environment from '../config';
import axios from 'axios';
import { relationSplit , grouprelationSplit } from './';
import { v4 as uuid } from 'uuid';

export const createEntity = async (response, props) => {
    var res = response.data.Result[0].properties.doc.attributes;
    var dataList = [];
    //create list  for  entity from enterpriseModudler
    entityType.map(val => {
        if (Array.isArray(res[val.name])) {
            res[val.name].map(data => {
                dataList.push({ label: data.data.value, id: data.id, type: val.value })
            })
        }
    })
    if (dataList.length > 0) {
        let entityArr = []
        //get entity list from context
        Object.values(props.leftContext.list).map(val => { entityArr = [...entityArr, ...val] });
        //filter the entity designer id  
        let entIds = entityArr.map(val => val.attributes.id);
        
        let enArr = props.leftContext.list;
          //edit field data comparision
          dataList.map(val => {
           
            enArr[val.type] =  enArr[val.type]?.map(_edit=>{
                if(val.id === _edit?.attributes?.id  && val.label !== _edit?.entity){
                    _edit.entity = val?.label?.trim();
                    _edit.db_objectname = val?.label?.trim().replace(/\s/g, '_');

                }
                return _edit;
            })
            return false;
          });
        //data compare to entity and enterprise  moduler then filter
        dataList = dataList.filter(val => entIds.indexOf(val.id) === -1); 
        //filter data to create new entity table
        dataList.map(val => {
            enArr[val.type] = enArr[val.type] ? enArr[val.type] : [];
            enArr[val.type].push(getEntity(val))
        });
      
        let values = Object.values(enArr)
        let datalist = []
        values.map(val => {
            datalist = [...datalist, ...val]
            return false
        })
        let data = await SaveSchema(datalist, props)
        let setEntityData = convertEntity(data)
        let { setEntity } = props.leftContext;
         
        setEntity({
            ...props.leftContext,
            list: setEntityData?.entity,
            saveToPulishParams: setEntityData?.Result
        })
    }
}

export const convertEntity = (data) => {
    let datalist = {}
    let converdata = data?.data?.Result?.metadata?.map(val => {
        datalist[val.entity_group_name] = datalist[val.entity_group_name] ? datalist[val.entity_group_name] : [];
        datalist[val.entity_group_name].push(val);
        return false
    })
    return {
        relation: data?.data?.Result?.entity_relationship ?? [],
        entity: datalist
    }
}

export const SaveSchema = (metaData, props) => {
    debugger
    return new Promise(async (resolve, reject) => {
        let splitrelation = relationSplit(props.relationshipcontext.list);
        let splitgrouprelation = grouprelationSplit(props.grouprelationshipcontext.list);

        let payload = {
            client:
                sessionStorage.clientName && sessionStorage.clientName !== 'undefined'
                    ? sessionStorage.clientName
                    : 'test',
            db_name:
                sessionStorage.clientDB && sessionStorage.clientDB !== 'undefined'
                    ? sessionStorage.clientDB.replace(/\s/g, "_")
                    : 'test',
            clientId:
                sessionStorage.clientId && sessionStorage.clientId !== 'undefined'
                    ? sessionStorage.clientId
                    : '',
            metadataId:
                sessionStorage.getItem('metadataId') &&
                    sessionStorage.getItem('metadataId') !== 'undefined'
                    ? sessionStorage.getItem('metadataId')
                    : '',
            metadataname:
                sessionStorage.getItem('metadataname') &&
                    sessionStorage.getItem('metadataname') !== 'undefined'
                    ? sessionStorage.getItem('metadataname')
                    : '',
            attributes: [{ relationship: splitrelation.dontsend }],
            metadata: metaData,
            entity_relationship: splitrelation.send,
            entity_grouprelation:splitgrouprelation.send,
            projectId:
                sessionStorage.getItem('projectId') &&
                    sessionStorage.getItem('projectId') !== 'undefined'
                    ? sessionStorage.getItem('projectId')
                    : '',
            metadata_dbname:environment.database
        }
        await axios
            .post(`${environment.api_url}/api/save_schema`, payload)
            .then(async res => {
                let value = res.data.clientdb.Result[0].properties.doc.metadataId
                let readData = await readSchema(value);
                resolve(readData);
                // resolve(readData.data?.Result?.metadata);
            })
            .catch(err => {
                resolve(null);
            })
    })
}

export const readSchema = (value) => {
    return new Promise(async (resolve, reject) => {
        await axios
            .post(`${environment.api_url}/api/get_schema`, {
                "filter": {
                    "columname": "metadataId",
                    "operator": "equals",
                    "value": value
                },
                metadata_dbname:environment.database
            })
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                resolve(null);
            })
    })
}

const getEntity = (json) => {
    return {
        entity: json.label.trim().replace(/\s/g, '_'),
        status: "draft",
        db_objectname: json.label.trim().replace(/\s/g, '_'),
        fields:  [
            {
                "name": "_id",
                "properties": {
                  "datatype": "string",
                  "mandatory": false,
                  "length": {
                    "min": "",
                    "max": ""
                  },
                  "Sequence": {
                    "suffix": "",
                    "prefix": "",
                    "StartValue": 0,
                    "SequenceId": "",
                    "isSequence": false,
                    "IncrementBy": 0,
                    "MaxValue": 0,
                    "IsReset": false
                  },
                  "autoGenerate": false,
                  "defaultValue": "",
                  "IsLOV": false,
                  "LOVType": "",
                  "LOV": [
                    {
                      "key": "",
                      "value": ""
                    }
                  ],
                  "LOV_value_fields": "value",
                  "LOV_key_field": "key",
                  "LOV_ref_collection": "",
                  "isCaseSensitive": false,
                  "IsPseducode": false,
                  "Pseducode": "Now()",
                  "CaseSensitive": "uppercase",
                  "regexPattern": "",
                  "Unique": true,
                  "Security": {
                    "Encryption": {
                      "Script": "AES"
                    }
                  }
                },
                "fieldType": "f",
                "attributes": {
                  "id": uuid(),
                  "table": json.label.trim().replace(/\s/g, '_'),
                  "tableID": json.id,
                  "selected_color": "red"
                }
              }
        ],
        indexes:[],
        entity_group_name: json.type,
        isActivity: false,
        isAcceptUserInput: false,
        activity: {
            create: false,
            update: false, 
            read: false,
            delete: false
        },
        attributes: {
            description: "",
            id: json.id,
            type: Types[json.type],
            left: 10,
            top: 10,
            permission: {
                secondary_owner: null,
                primary_owner: null,
            },
            style: {
                color: "red"
            }
        }
    }
}

export const entityType = [
    { name: "things", value: "things" },
    { name: "others", value: "others" },
    { name: "product", value: "productServices" },
    { name: "eco", value: "ecosystem" },
    { name: "enterPriseList", value: "personas" },
]

const Types = {
    personas: { label: "Personas", value: "personas" },
    things: { label: "Things", value: "things" },
    ecosystem: { label: "Ecosystem", value: "ecosystem" },
    productServices: { label: "Products and Services", value: "productServices" },
    others: { label: "Others", value: "others" }
}