import React from 'react';
import {
    Grid, makeStyles, Typography, Box,
    Button, Fab
} from "@material-ui/core";
import { style } from "./style";
import { NormalListCard } from "../../";
import AddIcon from '@material-ui/icons/Add';
import { uuid } from 'uuidv4';
import { withAllContexts } from "../../../HOCs";
import { SelectBox } from '../../entity/common';
import { CollapseListCard } from '../../../components';
import AddTemplate from "./addTemplate";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function Tabs(props) {
    const { classes, children, value, text, index, onclick, ...other } = props;
    return (
        <Button
            className={
                value === index
                    ? classes.buttonactive
                    : classes.nonebuttonactive
            }
            onClick={onclick}
            variant='contained'
        >
            {text}
        </Button>
    );
}
const useStyles = makeStyles(style);

function TemplateRightCard(props) {
    debugger;
    const classes = useStyles();
    const [value, setValue] = React.useState(1);
    const [evt, setEvt] = React.useState(null);
    const [resList, setResList] = React.useState({});
    const [ismaster, setIsMaster] = React.useState(null);
    const { customTemplate, setCustomEnity } = props.customTemplate;
    const { setResouceTemplate, resources } = props.resouceTemplates;
    const { setTemplateId, selectedId } = props.selectedTemplate;
    const { masterEntity } = props.masterDatas;
    React.useEffect(() => {
        debugger;
        if (resources.length > 0) {
            let res = {}
            resources.map(val => {
                let arr = res[val.attributes.Category] ? res[val.attributes.Category] : [];
                arr.push(val)
                res[val.attributes.Category] = arr
            })
            setResList(res)
        }
    }, [resources])
    const OpenEvt = (event) => {
        if (value !== 0) {
            setEvt(event.currentTarget);
        } else {
            setIsMaster(event.currentTarget)
        }
    };
    const handleClose = () => {
        setEvt(null)
        setIsMaster(null)
    };
    const saveTemplate = (state) => {
        if (value === 1) {
            customTemplate.push(getJson(state));
            setCustomEnity({
                ...props.customTemplate,
                customTemplate
            })
        } else {
            resources.push(getJson(state, true));
            setResouceTemplate({
                ...props.resouceTemplates,
                resources
            })
        }
    }
    const selectedList = (data, type) => {
        debugger;
        props.setIsRight(true);
        setTemplateId({
            ...props.selectedTemplate,
            isDefault: type,
            type: value,
            templateId: type ? data : data.attributes.id,
            selectedId: type ? data._id : data.attributes.id,
            data
        })
    }
    const setTabValue = (value) => {
        debugger;
        setValue(value)
        setTemplateId({
            ...props.selectedTemplate,
            isDefault: null,
            templateId: null,
        
        })
        props.setValue(value)
        props.setIsRight(false);
    }
    return <Grid className={classes.root}>
        <Grid md={12}>
            <Tabs onclick={() => setTabValue(0)} classes={classes} value={value} index={0} text={"Masters"} />
            <Tabs onclick={() => setTabValue(1)} classes={classes} value={value} index={1} text={"Data Types"} />
            <Tabs onclick={() => setTabValue(2)} classes={classes} value={value} index={2} text={"Resources"} />
        </Grid>
        <TabPanel value={value} index={0}>
            <NormalListCard selected={selectedId} selectedList={(data, type) => selectedList(data, true)} isnostatus={true} type={{ isdefault: true }} titlekey={'entity'} isexpand={true} list={masterEntity} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <NormalListCard selected={selectedId} selectedList={(data, type) => selectedList(data, false)} isnostatus={true} type={{ isdefault: false }} titlekey='entity' isexpand={true} list={customTemplate} />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <SelectBox
                list={[{ label: "FHIR", value: "FHIR" }]}
                onchange={(e, value) => null}
                value={{ label: "FHIR", value: "FHIR" }}
                title='Resource From'
            />
            {(resList && Object.keys(resList)) && Object.keys(resList).map(val => < CollapseListCard
                title={val}
                textStyle={{ fontSize: "14px", fontWeight: 600, color: "rgb(0 0 0)" }}
                collapseChildren={
                    <NormalListCard selected={selectedId} selectedList={(data, type) => selectedList(data, false)} isnostatus={true} type={{ isdefault: false }} titlekey='entity' isexpand={true} list={resList[val]} />
                }
                // icon={<CancelPresentationIcon />}
                icon={<img src={require('../../../assets/icons8-bulleted-list.svg')} />}
            />)}
            {/* <NormalListCard selectedList={(data, type) => selectedList(data, false)} isnostatus={true} type={{ isdefault: false }} titlekey='entity' isexpand={true} list={resources} /> */}
        </TabPanel>
        <Fab color="secondary" onClick={OpenEvt} aria-label="add" className={classes.addTemplate}>
            <AddIcon />
        </Fab>
        <AddTemplate indexValue={value} ismaster={ismaster} closeFun={handleClose} open={evt} saveTemplate={saveTemplate} />
    </Grid>
}

const getJson = (state, isresources) => {
    var isresource = {}
    if (isresources) {
        isresource = { isresource: true }
    }
    return {
        entity: state.title,
        "status": "draft",
        "db_objectname": state.title.trim().replace(/\s/g, '_'),
        "entity_group_name": "",
        attributes: {
            ...isresource,
            id: uuid(),
            selected_color: state.selected_color
        }
    }
}

export default withAllContexts(TemplateRightCard);