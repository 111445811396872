/**
 * @author prakash P
 * @email prakash@qdmplatforms.com
 * @create 16/03/2021
 * @desc Providing the handle Json from /src/context which is used in /src/App.js
 */

import React from "react";
import { DataJson } from "./contextdata";
import axios from 'axios';
import environment from '../../config';
import { compareQPASandEnterprise } from  '../../functions/actionEnterprise';
import { Confirmation } from "../../components";
import { ConfirmationContext } from "..";
const ContextProvider = (props) => {
  let [handleJson, setData] = React.useState({
    Data: defaultJson,
  });
  let [confirm, setConfirm] = React.useState({
    open: false,
    title: null,
    dialog: null,
    type: null,
    entity: null,
    data: null
});
  React.useEffect(() => {
    
    setFun()

  }, [sessionStorage.metaId])
  const setFun = async () => {
    if (!sessionStorage.metaId) {
      return false
    }
    // let valueE = sessionStorage.projectId ?? 'ad8c5c3e-161f-4635-8b46-4b857fa6071e';
    // let list = {};
    // let fillValue = defaultJson; 
    // let QPASValues = {}
    // await axios
    //   .post(`${environment.api_url}/api/read_documents`, {
    //     db_name: `${environment.database}`,
    //     entity: `${environment.qdm_enterprisemodeler_entity}`,
    //     filter: `${environment.qdm_enterprisemodeler_entity}.EnterpriceModelerid == '${valueE}'`,
    //     return_fields: `{${environment.qdm_enterprisemodeler_entity}}`
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     if (
    //       Object.keys(
    //         response.data.result[response.data.result.length - 1].EnterpriseModuler.attributes
    //       ).length > 0
    //     ) {

    //       list = response.data.result[response.data.result.length - 1].EnterpriseModuler.attributes
    //       fillValue = Object.keys(list).length > 0 ? list : defaultJson;


    //     }
    //   })
    //   .catch((err) => { });
    // await axios
    //   .post(`${environment.api_url}/api/read_documents`, {
    //     db_name: `${environment.database}`,
    //     entity: `${environment.qdm_qpas_entity}`,
    //     filter: `${environment.qdm_qpas_entity}.qpasid == '${valueE}'`,
    //     return_fields: `{${environment.qdm_qpas_entity}}`,
    //   })

    //   .then((response) => {
    //     if (
    //       Object.keys(
    //         response.data.result[response.data.result.length - 1].QPAS
    //           .attributes
    //       ).length > 0
    //     ) {
    //       QPASValues = response.data.result[response.data.result.length - 1].QPAS
    //         .attributes;
    //     }
    //   })
    //   .catch((err) => { });
    let compareJson = await compareQPASandEnterprise(); 
    debugger;
    setData({
      Data: compareJson
    })
  }
  return (
    <DataJson.Provider value={{ ...handleJson, setData }}>
      {/* <ConfirmationContext.Provider value={{...confirm,setConfirm}}> */}
          {props.children}
          {/* <Confirmation />
      </ConfirmationContext.Provider> */}
    </DataJson.Provider>
  );
};

export default ContextProvider;

const defaultJson = {
  // borderColor: ['#007aff','#59b191', '#fcc223'],
  arrow: [],
  enterPriseList: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
    // {
    //   id: "2",
    //   no: "2",
    //   data: { value: "", borderColor: "#59b191" },
    // },
  ],
  things: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],
  eco: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],

  product: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],
  others: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],
  Personas: [
    {
      id: "1",

      imgUrl: "https://picsum.photos/200/300?random=7",
      name: "Patient",
    },
    {
      id: "2",
      imgUrl: "https://picsum.photos/200/300?random=8",
      name: "Receptionist",
    },
    {
      id: "3",
      imgUrl: "https://picsum.photos/200/300?random=9",
      name: "Front Desk",
    },
    {
      id: "4",
      imgUrl: "https://picsum.photos/200/300?random=4",
      name: "Doctor",
    },
    {
      id: "5",
      imgUrl: "https://res.cloudinary.com/demo/image/upload/smiling_man.jpg",
      name: "Nurse",
    },
  ],
  Medialibrary: [
    {
      id: "1",
      imgUrl: "https://picsum.photos/200/300?random=1",
    },
    {
      id: "2",
      imgUrl: "https://picsum.photos/200/300?random=2",
    },
    {
      id: "3",
      imgUrl: "https://picsum.photos/200/300?random=3",
    },
    {
      id: "4",
      imgUrl: "https://picsum.photos/200/300?random=4",
    },
    {
      id: "5",
      imgUrl: "https://picsum.photos/200/300?random=6",
    },
  ],
};


const compareArray = (enterprice, qpass) => {
  let product = enterprice?.product ?? [];
  let productIds = product.map(val => val.id);
  let story = qpass?.addstory ?? [];
  let qpersonas = qpass?.library?.Personas ?? [];
  let epersonas = enterprice?.enterPriseList;
  let epersonasIds = epersonas.map(val => val.id);
  qpersonas.map(val => {
    if (epersonasIds.indexOf(val.id) === -1) {
      let setpersonasData = createPersonas(val.name, val.id, epersonas.length + 1)
      enterprice.enterPriseList.push(setpersonasData);
    }
  })
  story.map(val => {

    if (productIds.indexOf(val.id) === -1) {
      let setstoryData = createStory(val.title, val.id, product.length + 1)
      enterprice.product.push(setstoryData);
    }
  })
  return enterprice;
}


const createStory = (text, id, count) => {
  return {
    name: "step",
    no: count,
    id: id,
    data: {
      value: text,
      chips: [],
      borderColor: "hsla(" + Math.random() * 360 + ", 100%, 50%, 1)",
    },
  };
}
const createPersonas = (text, id, count) => {
  return {
    name: "step",
    no: count,
    id: id,
    data: {
      value: text,
      chips: [],
      borderColor: "hsla(" + Math.random() * 360 + ", 100%, 50%, 1)",
    },
  };
}