/**
 * @author porkalanchiyam
 * @email kalanchiyam@crayond.co
 * @create 16/03/2021
 * @desc Exporting iconlist  components from /src/components
 */

import React from "react";
import { makeStyles } from "@material-ui/core";
// import DataJson from "../../../../data.json";
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined";
import AcUnitOutlinedIcon from "@material-ui/icons/AcUnitOutlined";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  textwidth: {
    borderRadius: 0,
    // padding: "8px 0px 8px 8px",
    display: "flex",
    flexWrap: "wrap",
  },
  headtext: {
    fontSize: 13,
    color: "black",
    fontWeight: 700,

    paddingLeft: "10px",
  },
  search: {
    color: "grey",
    fontSize: 19,
    justifyContent: "flex-end",
    textAlign: "right!important",
    marginLeft: 149,
  },
}));

export default function Iconlist() {
  const classes = useStyles();

  return (
    <div className={classes.textwidth}>
      <TouchAppOutlinedIcon className={classes.icon} />
      <AcUnitOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <AcUnitOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
    </div>
  );
}
