import React from 'react';
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Grid, Paper
} from '@material-ui/core';
import axios from 'axios';
import environment from '../../config';
import { deleteSaveAction } from '../../functions/actionEnterprise';
import { withAllContexts } from "../../HOCs";

function Confirmation(props) {
    const { open, title, dialog, type, setConfirm, entity, data } = props.confirmationContext;
    const handleClose = () => {
        setConfirm({
            ...props.confirmationContext,
            open: false
        })
    };
    const onSuccess = async() => {
        let { list, setEntity } = props.leftContext
        let arr = list[entity.entity_group_name]
        if (type === 1) {
            list[entity.entity_group_name] =  await deleteEntity(arr, data.attributes.id)
            props.alert.setSnack({
                open:true,
                msg:"Entity delete successfully!"
            });
        } else if (type === 2) {
            list[entity.entity_group_name] = deleteField(arr, entity.attributes.id, data.attributes.id);
            props.alert.setSnack({
                open:true,
                msg:"Field delete successfully!"
            });
        }
        setEntity({
            ...props.leftContext,
            list
        })
        handleClose()
    };

    const deleteEntity = (arr, entityId) => { 
       
        let deleteEntity = arr.filter(_=>_.attributes.id ===entityId)[0];
        let params =   { 
            "metadataId": deleteEntity?.metadataId,
            "entity": deleteEntity?.entity,
            "isPublished":true
            }
     return  new  Promise(async(resolve,reject)=>{
      await  axios.post(`${environment.api_url}/api/deletemetadata`,params)
        .then(async(data)=>{
            // arr.map((val, i) => {
            //     if (val.attributes.id === entityId) {
            //         arr.splice(i, 1)
            //     }
            //     return false
            // })
            arr =  arr.filter(_=>_.attributes.id !==entityId);
            await deleteSaveAction(entityId);
            resolve(arr) 
        })
        .catch(()=>{
            resolve(arr) 
        })
     
    });  
        

       
    }
    const deleteField = (arr, entityId, fieldId) => {
        arr.map((val, i) => {
            if (val?.attributes?.id === entityId) {
                arr[i].fields = findFiled(val.fields, fieldId)
            }
            return false
        })
        return arr
    }

    const findFiled = (arr, fieldId) => {
        arr.map((val, i) => {
            if (val?.attributes?.id === fieldId) {
                arr.splice(i, 1)
            }
            if (val?.properties?.fields) {
                findFiled(val.properties.fields, fieldId);
            }
            return false
        })
        return arr
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Grid style={{ width: 450 }}>
                    {title}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{dialog}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleClose()} color="primary"> Cancel </Button>
                <Button onClick={() => onSuccess()} color="secondary"> delete </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withAllContexts(Confirmation)