/**
 * @author porkalanchiyam
 * @email kalanchiyam@crayond.co
 * @create 16/03/2021
 * @desc Exporting medialibray  components from /src/components
 */

import React from "react";
import { Grid, makeStyles, withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import WebIcon from "@material-ui/icons/Web";
import Divider from "@material-ui/core/Divider";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Medialist from "./librarylist/Medialist";
// import DataJson from "../../../data.json";
import { withAllContexts } from "../../../HOCs";

const useStyles = makeStyles((theme) => ({
  head: {
    boxShadow: "none",
    // width: 210,
    borderTop: "0.5px solid rgb(17 15 71 / 5%)",
    borderBottom: "solid 1px rgba(17, 15, 71, 0.08)",
    borderRadius: "0!important",
    margin: 0 + " !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "14px 0px",
    },
    "& .MuiAccordionSummary-content": {
      display: "inline-block",
    },
  },

  headtext: {
    paddingLeft: 10,
    fontSize: 12,
    color: "#9696a9",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  webicons: {
    fontSize: 17,
    color: "grey",
  },
  imges: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
  },
  avttar: {
    display: "inline-flex",
    borderRadius: "4px",
    margin: "16px 19px 16px 21px",
  },
  add: {
    color: "#e34d65",
    fontSize: 16,
    justifyContent: "right!important",
    textAlign: "right!important",
  },
  gridadd: {
    marginLeft: "38px",
  },
  textwidth: {
    borderRadius: 0,
    // padding: "7px 0px 7px 0px",
    height: "45vh",
    overflowY: "auto",
  },
  arrowdown: {
    color: "black",
    marginTop: -5,
  },
  expmore: {
    display: "none",
  },
  accro: {
    marginTop: -5 + " !important",
    marginBottom: -5 + " !important",
    minHeight: "0!important",
  },
}));

const AccordionSummary = withStyles({
  root: {
    "& .MuiAccordionSummary-content": {
      margin: "9px 0px",
    },
  },
  expandIcon: {
    order: -1,
    width: "10px",
    height: "10px",
    // border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    margin: "0px",
    backgroundColor: "white !important",
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {
    // background: "whitesmoke",
  },
})(MuiAccordionSummary);

function Medialibrary(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const { Data } = props.datajson;

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.head}
        onChange={() => props.handleClickA("Medialibrary")}
        expanded={props.openA === "Medialibrary"}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={
            <ArrowRightIcon fontSize="small" style={{ marginTop: -4 }} />
          }
          className={classes.accro}
        >
          <Grid container justify="space-between" style={{ marginTop: "2px" }}>
            <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <WebIcon className={classes.webicons} />
                </div>

                <div className={classes.headtext}>Library</div>
              </div>
            </Grid>

            <Grid item>
              <div>
                <AddOutlinedIcon
                  className={classes.add}
                  onClick={props.onClickMedia}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.textwidth}>
          <Medialist />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default withAllContexts(Medialibrary);
