/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Chip, Grid, Typography, Divider, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { digitalObjectButton, digitalObjectList, digitalObjectListState } from '../../../../utils';
import { useStyles } from './style';

class DigitalObject extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            digitalObjectType: null,
            // digitalObjectmethod: null,
            ...(JSON.parse(JSON.stringify(digitalObjectListState))),
            maxfilesize: null
        }
    }
    componentDidMount() {
        let { editproperties } = this.props;
        this.setState({
            ...editproperties
        })
    }
    setValue(name, value) {
        this.setState({
            [name]: value
        })
        this.props.setValueJSON(name, value)
    }
    render() {
        let { classes } = this.props;
        let { digitalObjectType, maxfilesize } = this.state;
        return (
            <Grid container className={classes.root}>
                <Grid md={12} className={classes.spaceBottom15}>
                    <Typography className={classes.spaceBottom15 + ' ' + classes.spaceTop} >DIGITAL OBJECT</Typography>
                    {digitalObjectButton.map(val => <Chip className={classes.chipList} onClick={() => this.setValue("digitalObjectType", val.value)} color={digitalObjectType === val.value ? "secondary" : ""} size="small" label={val.label} />)}
                    {digitalObjectList.map(val => <div><FormControlLabel control={<Checkbox name="checkedB" checked={this.state[val.value]} onClick={() => this.setValue(val.value, !this.state[val.value])} color="primary" />} label={val.label} /></div>)}
                </Grid>
                <Divider className={classes.dividerLine} />
                <Grid md={12} className={classes.spaceBottom15 + ' ' + classes.spaceTop}>
                    <Typography variant="caption">Max file size</Typography>
                    <TextField
                        inputProps={{ style: { height: "14px" } }}
                        onChange={(e) => this.setValue("maxfilesize", e.target.value)}
                        id="min-length"
                        type="number"
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        value={maxfilesize}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(useStyles)(DigitalObject);