/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

 import React from 'react'
 import { Grid, Drawer, Typography, Paper } from '@material-ui/core'
 import { withStyles } from '@material-ui/core/styles'
 import { LeftCard, RightCard, MiddleCard } from './'
 import { withNavBars } from '../../HOCs'
 import { SecondTopNav } from '../../components/entity'
 import { Iframe, BottomCard, JSONDiff, EntityTemplate, AddRelationShip , AddGroupRelationShip } from '../../components'
 import { HideCard } from '../../components/entity/rightCard'
 import { RightSideNav } from '../../contexts'
 import Enterprise from '../enterprise_modeler/index'
 import ContextProvider from '../../contexts/enterprise';
 import axios from 'axios';
 import  environment  from '../../config';
 import { Alerts } from '../../components';
 const useStyles = theme => ({
   root: {
     flexGrow: 1
   },
   leftCardParent: {
     display: 'grid',
     gridTemplateAreas: `"left main right"`,
     paddingTop: '1px'
   },
   leftCard: {
     gridArea: 'left',
     overflow: 'auto'
   },
   middleCard: {
     gridArea: 'main',
     maxHeight: 'calc(100vh - 90px)',
     overflow: 'auto'
   },
   rightCard: {
     gridArea: 'right',
     overflow: 'auto'
   },
   paper: {
     borderRadius: 0
   },
   title: {
     padding: "5px 10px",
     color:"#0b78fa"
     // color: "#5d5df7"
   },
   username: {
     fontSize: 14,
     fontWeight: 600,
     margin: "auto"
   },
   rounded: {
     width: "22px",
     height: "22px",
     fontSize: "11px",
     margin: "auto",
     background: "black",
     color: "blue"
   },
   rightalign: {
     textAlign: "right",
     display: "grid",
     gridTemplateColumns: "36px 105px 26px",
     float: "right",
     padding: "8px"
   },
   textName:{
     background: "#0a78fa",
     width: "102px",
     textAlign: "center",
     /* top: 13px; */
     marginTop: "2px",
     color: "white",
     fontSize: "13px"
   },
   TextValue:{
     paddingLeft: "10px"
   }
 })
 
 const overlay = {
   position:"absolute",
   width: "100%",
   height: "100%",
   top: "0",
   left: "0",
   right: "0",
   bottom:" 0",
   backgroundColor: "rgb(217 188 188 / 50%)",
   zIndex: 100001 ,
 
 }
 
 class Entity extends React.Component {
   constructor(props) {
     super(props)
     this.state = {
       openleft: true,
       openRight: true,
       value: 1,
       enterpriseVSentity: 'definition_panel',
       stateChangeForNav:true,
       username:'',
       lockScreen:true,
       snackopen: false,
       snackmsg: 'Please select field first',
       snacktype: 'error',
       saveopen: false,
       autoHideDuration: 2000,
     }
     this.expandLeftCard = this.expandLeftCard.bind(this)
     this.handleRealatioShipClose = this.handleRealatioShipClose.bind(this)
     this.handleRealatioShipOpen = this.handleRealatioShipOpen.bind(this)
     this.setValue = this.setValue.bind(this)
 
   }
  async componentDidMount() {
     if (this.props.location.search && this.props.location.search.length > 0) {
       let params = new URLSearchParams(this.props.location.search)
       
       // sessionStorage.setItem('metadataname', params.get('metaDataName'))
       sessionStorage.setItem('metadataId', params.get('metadata_id'));
       sessionStorage.setItem('metaId', params.get('metadata_id')) ;
       sessionStorage.setItem('token',params.get('token'));
       var base64Url = params.get('token').split('.')[1];
       var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
       var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
       }).join(''));
       let getTokenDetails = JSON.parse(jsonPayload);
       this.setState((prev)=>({
         ...prev,
         username:getTokenDetails.given_name
       }));
       sessionStorage.setItem('is_db', params.get('isDB'));
       let payload = {
         db_name: `${environment.database}`,
         entity:"projectvstools",
         filter:`projectvstools.metadataid=='${params.get('metadata_id')}'`,
         return_fields: `{projectvstools}`
 
       }
       let project_details = {};
       await axios
       .post(`${environment.api_url}/api/read_documents`,payload )
       .then(async(response) => {
         if(response?.data?.Code===201){
           project_details = response?.data?.result[0]?.projectvstools;
         } else {
 
         }
       })
       .catch((error)=>{
 
       });
       let project_info = {};
       if(project_details?.projectid){
         let project_payload =  {
           db_name: `${environment.database}`,
           entity:"projects",
           filter:`projects._id=='${project_details?.projectid}'`,
           return_fields: `{projects}`
         }
         await axios
         .post(`${environment.api_url}/api/read_documents`,project_payload )
         .then(async(response) => {
           if(response?.data?.Code===201){
             project_info = response?.data?.result[0]?.projects;
             sessionStorage.setItem('metadataname', response?.data?.result[0]?.projects?.projectname)
             sessionStorage.setItem('clientDB', project_details.dbname);
             sessionStorage.setItem('projectId',response?.data?.result[0]?.projects?.projectid)
             // project_details = response?.data?.result[0]?.projectname;
           } else {
   
           }
         })
         .catch((error)=>{
   
         });
 
 
       if(project_info?.projectid){
         let client_payload =  {
           db_name: `${environment.database}`,
           entity:"clients",
           filter:`clients._id=='${project_details?.clientid}'`,
           return_fields: `{clients}`
         }
         await axios
         .post(`${environment.api_url}/api/read_documents`,client_payload )
         .then(async(response) => {
           if(response?.data?.Code===201){
             sessionStorage.setItem('clientName', response?.data?.result[0]?.clients?.clientname)
             sessionStorage.setItem('clientId',response?.data?.result[0]?.clients?.clientid)
 
             this.setState((prev)=>({
               ...prev,
               stateChangeForNav:false
             }))
             // project_details = response?.data?.result[0]?.projectname;
           } else {
   
           }
         })
         .catch((error)=>{
   
         });
       }
       if (sessionStorage.is_db === 'true') {
         this.setState({
           ...this.state,
           enterpriseVSentity: 'definition_panel'
         })
       } else {
         this.setState({
           ...this.state,
           enterpriseVSentity: 'enterprise_modeler'
         })
       }
       
     }
     let screenPayload = {
         db_name: `${environment.database}`,
         entity: "ScreenLock",
         metadata_dbname: `ATP_Platform`,
         filter: `ScreenLock.metadataid=='${sessionStorage.getItem('metadataId')}'`,
         return_fields: `{ScreenLock}`
     }
     let ScreenLock_details = {};
     await axios
         .post(`${environment.api_url}/api/read_documents`, screenPayload)
         .then(async (response) => {
             if (response?.data?.Code === 201) {
                 ScreenLock_details = response?.data?.result;
             } else {
             }
         })
         if(ScreenLock_details.length > 0)
         {
     const Result = ScreenLock_details[ScreenLock_details.length - 1];
 
     if (Result.ScreenLock.IsLocked) {  
       if(Result.ScreenLock.userId === getTokenDetails.preferred_username)
       {
       this.setState({lockScreen : false})     
         sessionStorage.setItem("Is_Lock", false);
 
       }
 
       else{
        
         this.setState({lockScreen : true})     
         sessionStorage.setItem("Is_Lock", true)
   
       //   this.setState({
       //     saveopen: true,
       //     snackmsg: `Screen Locked By ${Result.ScreenLock.userId}`,
       //     snacktype: 'error',
       //     autoHideDuration: 5000,
       //     isSave: true
 
       // })
       }
     }
     else {    
       this.setState({lockScreen : false})      
         sessionStorage.setItem("Is_Lock", false)
     }
   }
 
   else {    
     this.setState({lockScreen : false})      
       sessionStorage.setItem("Is_Lock", false)
   }
     }
   }
   shouldComponentUpdate(prev,next){
     if(!sessionStorage.clientId){
       return false;
     }
     return true;
   }
   expandLeftCard() {
     let { openleft } = this.state
     this.setState({ openleft: !openleft })
   }
   handleTab = val => {
      
     const state = this.state;
     if (val === 'definition_panel') {
       sessionStorage.setItem('is_db', true);
 
     } else {
       sessionStorage.setItem('is_db', false);
     }
     this.setState({ ...state, enterpriseVSentity: val })
   }
   closeSnackBar = () => {
     this.setState({ snackopen: false, saveopen: false })
 }
   handleRealatioShipClose = () => {
      
     this.setState({ relationshipAnchorEl: null })
   }
   handleRealatioShipOpen = (e, data, relation) => {
     this.setState({ relationshipAnchorEl: e, relationdata: relation, enitityData: data })
   }
 
   handleGroupRealatioShipOpen = (e, data, relation) => {
      
     this.setState({ grouprelationshipAnchorEl: e, relationdata: relation, enitityData: data })
   }
   handleGroupRealatioShipClose = () => {
     this.setState({ grouprelationshipAnchorEl: null })
   }
   setValue = (value) => {
     this.setState({
       value: value
     })
   }
 
   
   render() {
     let { openleft, enterpriseVSentity, saveopen,autoHideDuration, relationshipAnchorEl, relationdata, enitityData, value , grouprelationshipAnchorEl ,snackopen,snackmsg,snacktype  } = this.state
     let { classes } = this.props
     let context = this.context
     console.log('when reader state changes')
    
 return(
   <>
 
       { this.state.lockScreen ?  
          
         <div className={classes.root} > 
         {saveopen && (
                                 <Alerts
                                     severity={snacktype}
                                     open={saveopen}
                                     vertical={'top'}
                                     horizontal={'right'}
                                     msg={snackmsg}
                                     autoHideDuration={autoHideDuration}
                                     onclose={() => this.closeSnackBar()}
                                 />
                             )}
       {/* Your nav bars here */}
     
       {/* <Paper className={classes.paper}> */}
       <Paper className={classes.paper} style={{width:"100%" , height:"100%" , top:0 , bottom:0, left:0,right:0,backgroundColor:"rgba(217, 188, 188, 0.5)" , position:"absolute" , zIndex:100001}}>
         <Grid container className={classes.root1} >
           <Grid item md={2}>
             <Typography variant="h6" className={classes.title} >QDM</Typography>
           </Grid>
           <Grid item md={8} style={{display:'flex'}}>
             <Grid item md={4}>
                 <Typography  className={classes.textName}>
                   Client Name
                 </Typography>
                 <Typography className={classes.TextValue} >
                   {sessionStorage.getItem('clientName')
                     ? sessionStorage.getItem('clientName')
                     : 'Foodzo v0.1'}
                 </Typography>
             </Grid>
             <Grid item md={4}>
                 <Typography  className={classes.textName}>
                   Project Name
                 </Typography>
                 <Typography className={classes.TextValue} >
                   {sessionStorage.getItem('metadataname')
                     ? sessionStorage.getItem('metadataname')
                     : 'Foodzo v0.1'}
                 </Typography>
             </Grid>
             <Grid item md={4}>
                 <Typography  className={classes.textName}>
                   Database Name
                 </Typography>
                 <Typography  className={classes.TextValue}>
                   {sessionStorage.getItem('clientDB')
                     ? sessionStorage.getItem('clientDB')
                     : 'Foodzo v0.1'}
                 </Typography>
             </Grid>
           </Grid>
           
         </Grid>
       </Paper>
       
       {/* Content */}
 
       <div className={classes.content} >
       <Grid container className={classes.root}>
         <Grid item md={12} >
           <SecondTopNav
             enterpriseVSentity={enterpriseVSentity}
             handleTab={this.handleTab}
             value={value}
             username = {this.state.username}
             data = {this.props.location.search}
           />
         </Grid>
 
         {/* this is a enterprise modeler Component */}
 
         {enterpriseVSentity === 'enterprise_modeler' && <Enterprise />}
         {/* end */}
 
         {enterpriseVSentity === 'definition_panel' && (
           <Grid
             item
             md={12}
             className={classes.leftCardParent}
             
             style={{
               gridTemplateColumns: `${openleft ? `279px` : '100px'} ${context.isopen ? `1fr  300px` : ''
                 }`
             }}
           >
             <Grid className={classes.leftCard}>
               <LeftCard  expandLeftCard={this.expandLeftCard} left={openleft} />
             </Grid>
 
             <Grid className={classes.middleCard}>
               {/* <Iframe> */}
               <MiddleCard handleRealatioShipOpen={this.handleRealatioShipOpen}  handleGroupRealatioShipOpen = {this.handleGroupRealatioShipOpen}  />
               {/* </Iframe>  */}
               <HideCard  data = {this.props.location.search} />
               <BottomCard />
             </Grid>
 
             <Grid className={classes.rightCard}>
               <RightCard />
             </Grid>
           </Grid>
         )}
         {enterpriseVSentity === 'definition_panel' && <JSONDiff />}
         {enterpriseVSentity === 'entity_templates' && <EntityTemplate setValue={this.setValue} isRight ={false} />}
         <Drawer anchor={"right"} open={Boolean(relationshipAnchorEl)} onClose={this.handleRealatioShipClose} >
           <React.Fragment>
             {Boolean(relationshipAnchorEl) && <AddRelationShip current={enitityData} currentField={relationdata} onclose={this.handleRealatioShipClose} />}
           </React.Fragment>
         </Drawer>
 
         <Drawer anchor={"right"} open={Boolean(grouprelationshipAnchorEl)} onClose={this.handleGroupRealatioShipClose} >
           <React.Fragment>
             {Boolean(grouprelationshipAnchorEl) && <AddGroupRelationShip current={enitityData} currentField={relationdata} onclose={this.handleGroupRealatioShipClose} />}
           </React.Fragment>
         </Drawer>
       </Grid>
       </div>
       
     </div>
         
   :
 
   <div className={classes.root}> 
       {/* Your nav bars here */}
       <Paper className={classes.paper}>
         <Grid container className={classes.root1} >
           <Grid item md={2}>
             <Typography variant="h6" className={classes.title} >QDM</Typography>
           </Grid>
           <Grid item md={8} style={{display:'flex'}}>
             <Grid item md={4}>
                 <Typography  className={classes.textName}>
                   Client Name
                 </Typography>
                 <Typography className={classes.TextValue} >
                   {sessionStorage.getItem('clientName')
                     ? sessionStorage.getItem('clientName')
                     : 'Foodzo v0.1'}
                 </Typography>
             </Grid>
             <Grid item md={4}>
                 <Typography  className={classes.textName}>
                   Project Name
                 </Typography>
                 <Typography className={classes.TextValue} >
                   {sessionStorage.getItem('metadataname')
                     ? sessionStorage.getItem('metadataname')
                     : 'Foodzo v0.1'}
                 </Typography>
             </Grid>
             <Grid item md={4}>
                 <Typography  className={classes.textName}>
                   Database Name
                 </Typography>
                 <Typography  className={classes.TextValue}>
                   {sessionStorage.getItem('clientDB')
                     ? sessionStorage.getItem('clientDB')
                     : 'Foodzo v0.1'}
                 </Typography>
             </Grid>
           </Grid>
           {/* <Grid item md={6}>
             <div className={classes.rightalign}>
               <Avatar variant="rounded" className={classes.rounded}>DP</Avatar>
               <Typography variant="h6" className={classes.username} >Dmirch Preach</Typography>
               <ExpandMoreIcon />
             </div>
           </Grid> */}
         </Grid>
       </Paper>
       {/* Content */}
       <div className={classes.content}>
       <Grid container className={classes.root}>
         <Grid item md={12}>
           <SecondTopNav
             enterpriseVSentity={enterpriseVSentity}
             handleTab={this.handleTab}
             value={value}
             username = {this.state.username}
             data = {this.props.location.search}
           />
         </Grid>
 
         {/* this is a enterprise modeler Component */}
 
         {enterpriseVSentity === 'enterprise_modeler' && <Enterprise />}
         {/* end */}
 
         {enterpriseVSentity === 'definition_panel' && (
           <Grid
             item
             md={12}
             className={classes.leftCardParent}
             style={{
               gridTemplateColumns: `${openleft ? `279px` : '100px'} ${context.isopen ? `1fr  300px` : ''
                 }`
             }}
           >
             <Grid className={classes.leftCard}>
               <LeftCard  expandLeftCard={this.expandLeftCard} left={openleft} />
             </Grid>
 
             <Grid className={classes.middleCard}>
               {/* <Iframe> */}
               <MiddleCard handleRealatioShipOpen={this.handleRealatioShipOpen}  handleGroupRealatioShipOpen = {this.handleGroupRealatioShipOpen}  />
               {/* </Iframe>  */}
               <HideCard   data = {this.props.location.search} />
               <BottomCard />
             </Grid>
 
             <Grid className={classes.rightCard}>
               <RightCard />
             </Grid>
           </Grid>
         )}
         {enterpriseVSentity === 'definition_panel' && <JSONDiff />}
         {enterpriseVSentity === 'entity_templates' && <EntityTemplate setValue={this.setValue} isRight ={false} />}
         <Drawer anchor={"right"} open={Boolean(relationshipAnchorEl)} onClose={this.handleRealatioShipClose} >
           <React.Fragment>
             {Boolean(relationshipAnchorEl) && <AddRelationShip current={enitityData} currentField={relationdata} onclose={this.handleRealatioShipClose} />}
           </React.Fragment>
         </Drawer>
 
         <Drawer anchor={"right"} open={Boolean(grouprelationshipAnchorEl)} onClose={this.handleGroupRealatioShipClose} >
           <React.Fragment>
             {Boolean(grouprelationshipAnchorEl) && <AddGroupRelationShip current={enitityData} currentField={relationdata} onclose={this.handleGroupRealatioShipClose} />}
           </React.Fragment>
         </Drawer>
       </Grid>
       </div>
     </div>
 }
       
 </>  
     )
   }
 }
 Entity.contextType = RightSideNav
 export default withStyles(useStyles)(Entity)