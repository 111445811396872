export const style = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: "calc(113vh - 176px)",
        overflow: "auto",
        borderRight: "1px solid #d2cbcb",
        borderTop: "1px solid #8080801f"
    },
    buttonactive: {
        height: '30px',
        width: '31%',
        marginRight: "2%",
        borderRadius: '6px',
        color: '#fff !important',
        background: '#0b78fa !important',
        boxShadow: '0 15px 23px 0 #110f4703',
        border: '1px solid #eee',
        fontSize: 12,
        "&:hover": {
            boxShadow: '0 15px 23px 0 #110f4703',
            border: '1px solid #eee',
        }
    },
    nonebuttonactive: {
        color: '#000 !important',
        height: '30px',
        width: '31%',
        marginRight: "2%",
        background: '#fff !important',
        borderRadius: '6px',
        boxShadow: '0 15px 23px 0 #110f4703',
        border: '1px solid #eee',
        fontSize: 12,
        "&:hover": {
            boxShadow: '0 15px 23px 0 #110f4703',
            border: '1px solid #eee',
        }
    },
    addTemplate: {
        position: "absolute",
        bottom: "12px",
        left: "365px",
        width: "40px",
        height: "40px"
    },
    addText: {
        width: "105px",
        padding: "8px 3px",
        textAlign: "center",
        cursor: "pointer"
    },
    uploadTemplate: {
        width: 400,
        padding: 20
    },
    bottomSpace: {
        marginBottom: 15
    },
    templateAction: {
        textAlign: "right",
        paddingTop: 25
    },
    templateupload: {
        marginLeft: 10
    },
    topSpace: {
        marginTop: 15
    }
})