/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import { EntityLeftContext } from '../../../contexts';
const useStyles = ((theme) => ({
    paper: {
        padding: "15px",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "9px"
    },
    title: {
        fontWeight: 600
    }
}));

function MiddleMiniCard(props) {
    let { classes, data } = props;
    let entityContext = React.useContext(EntityLeftContext);
    const expandModule = () => {
        let { setEntity } = entityContext;
        setEntity({
            ...entityContext,
            selected : data.id
        })
    }
    return (
        <React.Fragment>
            <Grid  onClick={() => expandModule()} item  style={{ width :"145px" }}>
                <Paper className={classes.paper}>
                    <Typography className={classes.title} >{data.title}</Typography>
                    <Typography>{data.fields ? data.fields.length + ' fields' : '0 fields'}</Typography>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default withStyles(useStyles)(MiddleMiniCard);