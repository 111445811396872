/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Grid, Typography, Divider, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { securityData, securityState, typeData, typeDataState, cardinalityOptions,indexTypes,encriptionTypeData } from '../../../../utils';
import { useStyles } from './style';
import { SelectBox } from '../../';

class Security extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...(JSON.parse(JSON.stringify(securityState))),
            ...(JSON.parse(JSON.stringify(typeDataState))),
            cardinality: null,
            securityType:[],
            securityDataClone:[]
        }
    }
    componentDidMount() {
       this.updatedFields();

    }

    shouldComponentUpdate(nextprops){
        if(nextprops !== this.props){
            this.updatedFields();
        }
        return nextprops !== this.props
        
    }
    updatedFields = () =>{
         
        let { editproperties } = this.props;
         
        let properties = editproperties?.Security ?? {}
         
        let state = this.state;
         
        state.unique = editproperties?.properties?.unique ? editproperties?.properties?.unique : false;
         
        state.autoIncrement = editproperties?.properties?.autoIncrement ? editproperties?.properties?.autoIncrement : false;
         
        let data = {
            ...state,
            ...properties
        }
        let securityType = [];
         
        if(editproperties?.properties?.datatype==='string'){
                 securityType =  typeData.filter(_=> _.value !=='autoIncrement');
              
        }
        // else if(editproperties?.properties?.datatype==='number' || editproperties?.properties?.datatype==='integer' || editproperties?.properties?.datatype==='unsignedint' || editproperties?.properties?.datatype==='positiveint' || editproperties?.properties?.datatype==='positiveint'){
        //     securityType =  typeData.filter(_=> _.value !=='autoGenerate');
         
        // } 

        else if(editproperties?.properties?.datatype==='barcode')
        {
            securityType = [];
        }
        else {
             
            securityType =  typeData.filter(_=> _.value !=='autoGenerate');

        }
        let securityDataFilter  = []
        if(editproperties?.properties?.datatype ==='barcode'){
            securityDataFilter = [];
        } 
        else if(editproperties?.properties?.datatype !=='string')
        {
            securityDataFilter = securityData.filter(_=> _.value !=='IntergrityCheck');
        }
        
        else {
            securityDataFilter = securityData;
        }
        this.setState({
           ...data,
           securityType,
           securityDataClone: securityDataFilter
        })
    }
    setValue(name, value) {
        this.setState({
            [name]: value
        })
        this.props.setValueJSON("Security", {
            ...this.state,
            [name]: value
        })
    }
    handleIndexValue = ()=>{
       return this.props?.cloneContext?.list[this.props.datalist.editTable?.attributes?.type?.value][this.props?.datalist?.tableIndex]?.indexes?.filter(_=>_.fieldId===this.props?.datalist?.editFieldData?.attributes?.id)[0] ?? {}
    }
    render() {
        let { classes,editproperties,fieldProperties } = this.props;
        let { securityType,securityDataClone } = this.state;
        let indexValue = this.handleIndexValue();
        return (
            <Grid container className={classes.root}>
                <Grid md={12}>{securityDataClone.length > 0 ?  <Typography >SECURITY</Typography> :  <Typography ></Typography>}
                   
                      {(fieldProperties?.properties?.Security?.Encryption) && <Grid item md={12} className={classes.spaceTop} style={{marginTop:0}}>

                    {/* <Typography >Encryption</Typography> */}
                    <Grid container md={12} >
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                        <SelectBox
                                list={[
                                    ...encriptionTypeData
                                ]}
                                classname={classes.selectBoxOverideStyle}
                                onchange={(e, value) => { this.props.setDataTypeProperties("Security",{Encryption:{Script:value?.label ?? ''}}, 'property') }}
                                value={{label:editproperties?.properties?.Security?.Encryption?.Script,value:editproperties?.properties?.Security?.Encryption?.Script}}
                                top_title='Encrypt Type'
                                title='Encrypt Type'

                            />
                        </Grid>
                     </Grid>
                    
                    </Grid>} 
                    {securityDataClone.map(val => <FormControlLabel control={<Checkbox checked={this.props?.editproperties?.properties?.[val.value]?? false} onChange={(e, value) => this.props.setDataTypeProperties(val.value, value ,'property')} name="checkedB" color="primary" />} label={val.label} />)}
                </Grid>
                {
                    fieldProperties?.datatype?.toLowerCase() !== 'object' && fieldProperties?.datatype?.toLowerCase() !== 'array' && fieldProperties?.datatype?.toLowerCase() !== 'boolean'  ? 
                    <>
                     <Divider className={classes.dividerLine} />
                <Grid md={12} className={classes.spaceBottom15}>
                    {securityType.map(val => <div>
                        <FormControlLabel control={<Checkbox checked={this.props?.editproperties?.properties?.[val.value]?? false} onChange={(e, value) => this.props.setDataTypeProperties(val.value, value ,'property')} name={val.value} color="primary" />} label={val.label} />
                        {
                           (val.value==='isindex' && this.props?.editproperties?.properties?.[val.value]) ? <>
                            <Grid container spacing="1">
                            <Grid item md={6}>
                                    <SelectBox
                                        list={
                                           indexTypes
                                        }
                                        classname={classes.spaceBottom15}
                                        onchange={(e, value) => { this.props.updateIndexProperties("indexType",value?.value  ?? '', 'index_property')  }}
                                        value={indexTypes.filter(_=>_.value ===indexValue?.indexType)[0]}
                                        top_title='Index Type'
                                        title='Index Type'

                                    />
                                </Grid>
                                {
                                    indexValue?.indexType.length>0 ?   <Grid item md={6}>
                                    <Typography variant="caption">Index Name</Typography>
                                            <TextField
                                                inputProps={{ style: { height: "14px" } }}
                                                id="indexName"
                                                value={indexValue?.indexName}
                                                onChange={(e) => this.props.updateIndexProperties("indexName",e.target.value, 'index_property') }
                                                variant="outlined"
                                                size="small"
                                            />
                                    </Grid> :''
                                }
                                {
                                    indexValue?.indexType === 'fulltext' ? 
                                            <Grid item md={6}>
                                                <Typography variant="caption">Min Length</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={indexValue?.minLength}
                                                    onChange={(e) => this.props.updateIndexProperties("minLength",  e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e)=>{
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}

                                                />
                                        </Grid>
                                    : ''
                                }
                                {
                                   (indexValue?.indexType === 'fulltext'  || indexValue?.indexType === 'persistent' ||  indexValue?.indexType === 'ttl' || indexValue?.indexType === 'geo')  ? 
                                        <Grid item md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={indexValue?.isCreateInBackground??false}
                                                    onChange={(e,value) => this.props.updateIndexProperties("isCreateInBackground", value)}
                                                    name="isIndex"
                                                    color="primary"
                                                />
                                            }
                                            label="Create In Background"
                                        />
                                    </Grid>
                                    : ''
                                    
                                }
                                 {
                                    ( indexValue?.indexType === 'persistent') ? 
                                    <Grid item md={6}>
                                    <FormControlLabel
                                            control={
                                                <Checkbox
                                                   checked={indexValue?.isSparse??false}
                                                    onChange={(e,value) => this.props.updateIndexProperties("isSparse", value)}
                                                    name="isSparse"
                                                    color="primary"
                                                />
                                            }
                                            label="Sparse"
                                        />
                                    </Grid>
                                    : ''
                                    
                                }
                                  {
                                   ( indexValue?.indexType === 'persistent')  ? 
                                    <Grid item md={6}>
                                    <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={indexValue?.isUnique??false}
                                                    onChange={(e,value) => this.props.updateIndexProperties("isUnique", value)}
                                                    name="isUnique"
                                                    color="primary"
                                                />
                                            }
                                            label="Unique"
                                        />                                                              
                                </Grid>
                                    : ''
                                    
                                }

                                 {
                                    indexValue?.indexType === 'geo' ? 
                                    <Grid item md={6}>
                                    <FormControlLabel
                                            control={
                                                <Checkbox
                                                   checked={indexValue?.isGeoJson??false}
                                                    onChange={(e,value) => this.props.updateIndexProperties("isGeoJson", value)}
                                                    name="isGeoJson"
                                                    color="primary"
                                                />
                                            }
                                            label="Geo Json"
                                        />
                                </Grid> : ''

                                 }
                             {/* {
                                    ( indexValue?.indexType === 'persistent') ? 
                                    <Grid item md={6}>
                                    <FormControlLabel
                                            control={
                                                <Checkbox
                                                   checked={indexValue?.deduplicateArray??false}
                                                    onChange={(e,value) => this.props.updateIndexProperties("deduplicateArray", value)}
                                                    name="DeduplicateArray"
                                                    color="primary"
                                                />
                                            }
                                            label="Deduplicate Array"
                                        />
                                    </Grid>
                                    : ''
                                    
                                } */}
                                 {
                                   (indexValue?.indexType === 'ttl') ? 
                                    <Grid item md={6}>
                                        <Typography variant="caption">Expiration Time(ms)</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="expirationTime"
                                                    value={indexValue?.expirationTime}
                                                    onChange={(e) => this.props.updateIndexProperties("expirationTime",  e.target.value )}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e)=>{
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}

                                                />
                                </Grid> : ''

                                 } 
                                
                            </Grid>
                            </>:''
                        }
                        </div>)}
                </Grid>
                    </>
                    :''
                }
               
                {
                    fieldProperties?.properties?.Cardinality ? <>
                     <Divider className={classes.dividerLine} />
                <Grid container className={classes.spaceBottom15}>
                    <SelectBox
                        list={cardinalityOptions}
                        classname={classes.spaceBottom15}
                        onchange={(e, value) => {
                            this.props.setDataTypeProperties("Cardinality",value.label, 'property') 
                        }}
                        value={this.props.editproperties?.properties?.Cardinality ?
                            cardinalityOptions.filter(val => val.label === this.props.editproperties?.properties?.Cardinality)[0]
                            : null}
                        top_title='Cardinality'
                    />
                    {/* <Grid md={6}>
                        <Typography>Integrity check</Typography>
                    </Grid>
                    <Grid md={6}>
                        <Checkbox
                            className={classes.checkBox}
                            checked={state.integrityCheck}
                            onChange={(event) => this.setValue("integrityCheck", event.target.checked)}
                            name="Integrity"
                        />
                    </Grid> */}
                    {/* <FormControlLabel control={<Checkbox checked={state.integrityCheck} onClick={() => this.setValue("integrityCheck", !state["integrityCheck"])} name="integrityCheck" color="primary" />} label={"Integrity check"} /> */}
                </Grid>
                    </> : ''
                }
               
            </Grid>
        )
    }
}

export default withStyles(useStyles)(Security);