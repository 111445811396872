import { uuid } from 'uuidv4';


export const removeData = (dataList) => {
    dataList.map(val => {
        val.entity = val.entity.replace("[", "_").replace("]", "")
        val.fields = FindField(val.fields)
    })
    return dataList
}

export const defaultFieldAdd = (dataList) =>  {
    let datalist =   dataList.map(val=>{
        if(!val?.fields.some(_field=>_field.name==='_id')){
            val.fields.unshift(
                {
                    "name": "_id",
                    "properties": {
                      "datatype": "string",
                      "mandatory": false,
                      "length": {
                        "min": "",
                        "max": ""
                      },
                      "Sequence": {
                        "suffix": "",
                        "prefix": "",
                        "StartValue": 0,
                        "SequenceId": "",
                        "isSequence": false,
                        "IncrementBy": 0,
                        "MaxValue": 0,
                        "IsReset": false
                      },
                      "autoGenerate": false,
                      "defaultValue": "",
                      "IsLOV": false,
                      "LOVType": "",
                      "LOV": [
                        {
                          "key": "",
                          "value": ""
                        }
                      ],
                      "LOV_value_fields": "value",
                      "LOV_key_field": "key",
                      "LOV_ref_collection": "",
                      "isCaseSensitive": false,
                      "IsPseducode": false,
                      "Pseducode": "Now()",
                      "CaseSensitive": "uppercase",
                      "regexPattern": "",
                      "Unique": true,
                      "Security": {
                        "Encryption": {
                          "Script": "AES"
                        }
                      }
                    },
                    "fieldType": "f",
                    "attributes": {
                      "id": uuid(),
                      "table": val.entity,
                      "tableID": val.attributes.id,
                      "selected_color": "red"
                    }
                  }
            )
            
        }
        return val;
    });
    return  datalist;
}
export const FindField = (field) => {
    field.map(val => {
        // let isentityList = val?.properties?.dataTypeProperties?.QDMProperties?.properties?.entityLists ?? false;
        // if (isentityList) {
        //     delete val.properties.dataTypeProperties.QDMProperties.properties.entityLists
        // }
        val.name = val?.name?.replace("[", "_").replace("]", "")
        // if(val.fieldType === "d"){
        //     val.properties["fieldCollection"] = false
        //     val.properties["isdimensionedge"] = true
        // }
        if (val.properties.fields) {
            val.properties.fields = FindField(val.properties.fields)
        }
    })
    return field;
}