/**
 * @author porkalanchiyam
 * @email kalanchiyam@crayond.co
 * @create 16/03/2021
 * @desc Exporting medialist  components from /src/components
 */

import React from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// import DataJson from "../../../../data.json";
import { withAllContexts } from "../../../../HOCs";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  headtext: {
    fontSize: 13,
    color: "black",
    fontWeight: 700,
    display: "inline-flex",
    paddingLeft: "10px",
  },
  avttar: {
    margin: "10px",
    borderRadius: "5px",
  },
  width: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

function Personalist(props) {
  const classes = useStyles();
  const { Data } = props.datajson;

  return (
    <div>
      <div className={classes.width}>
        {Data?.Medialibrary?.map((media, i) => {
          return (
            <Avatar
              key={i}
              alt="Remy Sharp"
              src={media.imgUrl}
              className={classes.avttar}
            />
          );
        })}
      </div>
    </div>
  );
}

export default withAllContexts(Personalist);
