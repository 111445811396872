import React, { useState } from "react";
import {
    Grid, makeStyles, Typography, Popover,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Drawer
} from "@material-ui/core";
import { style } from "./style";
import { withAllContexts } from "../../../HOCs";
import { getCollectionData } from "../../../functions";
import { AddField, EntityFieldList,Alerts } from "../../../components";
const useStyles = makeStyles(style);
function TemplateMaincard(props) {
    debugger;
    const classes = useStyles();
    const [evt, setEvt] = React.useState(null);
    const [state, setState] = React.useState({ editData: null, isAdd: null, loading: false });
    const [list, setList] = React.useState(null);
    const [ref, setRef] = React.useState({ name: null, list: [], open: false });
    const { customTemplate } = props.customTemplate;
    const { resources } = props.resouceTemplates;
    const { isDefault, templateId, data, type } = props.selectedTemplate;
    const [ alertOp, setAlertOp] = useState(false);
    // const { masters } = props.masterDatas;

    const OpenEvt = (event) => {
        setEvt(event.currentTarget);
        setState({ ...state, editData: null, isAdd: null })
    };
    const handleClose = () => {
        setAlertOp(true);
        setEvt(null)
    };
    const handlepopOverClose = () => {
        // setAlertOp(true);
        setEvt(null)
    };
    const closeAlert = () =>{
        setAlertOp(false);
        
    }
    const openRef = (name) => {
        setRef({
            ...ref,
            name: name,
            open: true
        })
        getCollectionDatas(name)
    }
    const closeRef = () => {
        setRef({
            ...ref,
            name: null,
            list: [], open: false
        })
    }
    const getCollectionDatas = async (isreff) => {
        setState({ ...state, loading: true })
        let lists = await getCollectionData(isreff ? isreff : templateId.name)
        if (isreff) {
            setRef({
                ...ref,
                open: true,
                name: isreff,
                list: lists?.[0] ? lists[0] : []
            })
        } else {
            debugger;
            setList(lists[0])
            setState({ ...state, loading: false })
        }
    }

    React.useEffect(() => {
        debugger;
        if (templateId) {
            if (isDefault) {
                if (templateId.name) {
                    debugger;
                    getCollectionDatas()
                } else {
                    setList(templateId.data)
                }
            } else {
                if (type === 2) {
                    resources.map(val => {
                        if (templateId === val.attributes.id) {
                            // list = val
                            debugger;
                            setList(val)
                        }
                    })
                } else {
                    customTemplate.map(val => {
                        if (templateId === val.attributes.id) {
                            // list = val
                            debugger;
                            setList(val)
                        }
                    })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId])

    const openEdit = (event, val, isAdd) => {
        setEvt(event.currentTarget);
        setState({ ...state, editData: val, isAdd })
    }
    return <React.Fragment>
        {
            alertOp &&  <Alerts
            open = {alertOp}
            vertical = {'top'}
            horizontal ={'right'}
            autoHideDuration ={2000}
            severity = {'success'}
            msg ={'Field added successfully'}
            onclose = {closeAlert} 
        />
        }
          
        {list && <Grid container className={classes.root}>
            {(!isDefault) && <Grid md={12}>
                
                <Grid md={6} container justify="center">
                    <Grid md={12} container justify="center">
                        <Grid md={10} className={classes.entityHeader}>
                            <Grid md={12} className={classes.entityRoot}
                            // style={{ color: list.attributes.selected_color ? 'white' : "", backgroundColor: list.attributes.selected_color ? list.attributes.selected_color : "" }}
                            >
                                <Typography>{list.entity}</Typography>
                                <Typography
                                    color="primary"
                                    // style={{ color: 'white', cursor: "pointer" }}
                                    onClick={OpenEvt} className={classes.AddentityField}>ADD</Typography>
                            </Grid>
                            <Grid md={12} className={classes.fieldRoot}
                            >
                                <Grid md={12} className={classes.fieldListRoot}>
                                    <Grid>
                                        {list.fields && <EntityFieldList noEditAction={true} openRef={openRef} isTemplate tableIndex={0} data={list} openEdit={openEdit} list={list.fields} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid md={6}>
                    {/* <TextField
                        multiline
                        variant="outlined"
                        fullWidth={true}
                        className={classes.jsonInput}
                        defaultValue=""
                    /> */}
                </Grid>
            </Grid>}
            {(isDefault) &&
                <Grid md={12} container justify="center">
                    <Grid md={12}>
                        <Typography variant="h6">{templateId.entity || templateId.name}</Typography>
                        <TableContainer className={classes.templateTable} component={Paper}>
                            <Table className={classes.entityHeader} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Display</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Language</TableCell>
                                        {/* <TableCell>Definition</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(list && Array.isArray(list)) && list.map((row) => <TableRow key={row.value}>
                                        <TableCell >{row.Display}</TableCell>
                                        <TableCell>{row.Code}</TableCell>
                                        <TableCell>{row.language}</TableCell>
                                        {/* <TableCell>{row.Definition}</TableCell> */}
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }
        </Grid>}
        <Drawer anchor={"right"} open={Boolean(ref.open)} id={'refData'} onClose={closeRef}>
            <Grid className={classes.large}>
                <Grid md={12} container justify="center">
                    <Grid md={12}>
                        <Typography variant="h6">{ref.name}</Typography>
                        <TableContainer className={classes.templateTable} component={Paper}>
                            <Table className={classes.entityHeader} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Display</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Language</TableCell>
                                        {/* <TableCell>Definition</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ref.list.map((row) => <TableRow key={row.value}>
                                        <TableCell >{row.Display}</TableCell>
                                        <TableCell>{row.Code}</TableCell>
                                        <TableCell>{row.language}</TableCell>
                                        {/* <TableCell>{row.Definition}</TableCell> */}
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
        <Popover
            id={'add_field'}
            open={Boolean(evt)}
            anchorEl={evt}
            onClose={handlepopOverClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        >
            <AddField templatetype={type} isAdd={state.isAdd} editData={state.editData} data={data} isTemplate={true} onclose={handleClose} />
        </Popover>
    </React.Fragment>
}



export default withAllContexts(TemplateMaincard);