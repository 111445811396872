import { Button, Grid, withStyles, Paper } from "@material-ui/core";
import React from "react";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = theme => ({
    root: {
        paddingTop: 25
    },
    Uploadbutton: {
        width: "100%"
    },
    listcard: {
        padding: "10px 10px",
        background: "#eaeaea"
    },
    listRoot: {
        marginTop: "15px"
    },
    closeIcon: {
        float: "right",
        fill: "grey",
        cursor: "pointer"
    }
})
class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
        this.handleFileUpload = this.handleFileUpload.bind(this)
    }
    handleFileUpload = async (event) => {
        if(parseInt(event.target.files[0].size)/(1024*1024)>20){
            alert('Please upload file is smaller than 20MB.')
            return;
        }
        let { files } = this.state

        files.push(event.target.files[0])
        this.setState({ files }, () => { this.callFun() })
    };
    deleteFile = (index) => {
        let { files } = this.state
        files.splice(index, 1);
        this.setState({ files }, () => { this.callFun() })
    }
    callFun = () => {
        let { handleChange } = this.props
        if (handleChange) {
            handleChange(this.state.files)
        }
    }
    render() {
        let { files } = this.state;
        let { classes } = this.props;
        return <Grid className={classes.root}>
            <input
                ref="fileInput"
                onChange={this.handleFileUpload}
                type="file"
                accept=".xls,.xlsx"
                style={{ display: "none" }}
            />
            <Button disabled={(files && files.length > 0) ? true : false} className={classes.Uploadbutton} variant="contained" color="secondary" onClick={() => this.refs.fileInput.click()}>Upload File</Button>
            {
                files && files.map((val, i) => {
                    return <Grid className={classes.listRoot}>
                        <Paper className={classes.listcard}>
                            {val.name}
                            <CloseIcon onClick={() => this.deleteFile(i)} className={classes.closeIcon} />
                        </Paper>
                    </Grid>
                })
            }
        </Grid>
    }
}

export default withStyles(useStyles)(FileUpload);