import React, { useEffect } from "react";
import { withAllContexts } from "../../../HOCs";
import { loopFiles } from "../../../functions";
import { style } from "./style";
import { Popover, makeStyles, Grid, Typography, Divider, TextField, Drawer, Button } from "@material-ui/core";
import { AddEntity, FileUpload } from "../../";
import { Alerts } from "../../alert";

const useStyles = makeStyles(style);
function AddTemplate(props) {
    const classes = useStyles();
    const { closeFun, open, saveTemplate, ismaster, masterDatas, indexValue } = props;
    const [evt, setEvt] = React.useState(null);
    const [isopen, setIsOpen] = React.useState(null);
    const [ snackbar,setSnackbar ] = React.useState({
            saveopen:false,
            snackmsg: 'Template created successfully',
            autoHideDuration:2000,
            snacktype:'success',
          
      
    });
    useEffect(() => {
        if (ismaster) {
            setIsOpen(ismaster)
        }
    }, [ismaster])
    const [state, setState] = React.useState({ files: [], title: null });
    let { customTemplate, setCustomEnity } = props.customTemplate;
    let { setResouceTemplate, resources } = props.resouceTemplates;
    const OpenEvt = (event) => {
        setEvt(event.currentTarget);
    };
    const OpenTemplate = (event) => {
        closeFun();
        setIsOpen(event.currentTarget);
    };
    const handleClose = () => {
        setEvt(null)
    };
    const CloseTemplate = () => {
        setIsOpen(null)
        closeFun()

    };
    const handleChange = (files) => {
        setState({
            ...state,
            files: files
        });
    }

    const uploadFun = async () => {
        const data = state;
        if (Boolean(ismaster)) {
            let masters = await loopFiles(data.files, customTemplate, Boolean(ismaster))
            const { setMasters } = masterDatas;
            setMasters({
                ...masterDatas,
                masters: { ...masterDatas.masters, ...masters.data },
                masterEntity: [...masterDatas.masterEntity, ...masters.masterEntity]
            })
        } else {
            let entity = await loopFiles(data.files, customTemplate, Boolean(ismaster))
            entity = entity ? entity : [];
            if (indexValue === 2) {
                resources = [...resources, ...entity]
                setResouceTemplate({
                    ...props.resouceTemplates,
                    resources
                })
            } else {
                customTemplate = [...customTemplate, ...entity]
                setCustomEnity({
                    ...props.customTemplate,
                    customTemplate
                })
            }
        }
        CloseTemplate();
        handleClose();
    }
   const handleSnackBar = ()=>{
       setSnackbar({
           ...snackbar,
           saveopen:!snackbar.saveopen
       })
   }
    return <React.Fragment>
          {snackbar.saveopen && (
              <Alerts
                severity={snackbar.snacktype}
                open={snackbar.saveopen}
                vertical={'top'}
                horizontal={'right'}
                msg={snackbar.snackmsg}
                autoHideDuration={snackbar.autoHideDuration}
                onclose={() => handleSnackBar()}
              />
            )}
        <Popover
            id={'add_template'}
            open={Boolean(open)}
            anchorEl={open}
            onClose={closeFun}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Grid>
                <Typography onClick={OpenTemplate} className={classes.addText} >Upload</Typography>
                <Divider />
                <Typography onClick={OpenEvt} className={classes.addText} >Create</Typography>
            </Grid>
        </Popover>
        <Popover
            id={'add_entity'}
            open={Boolean(evt)}
            anchorEl={evt}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        >
            <AddEntity  isTemplate={true} onclose={handleClose} onSaveFun={(state) => {handleSnackBar();saveTemplate(state)}} />
        </Popover>
        <Drawer
            id={'UploadTemplate'}
            open={Boolean(isopen)}
            anchor={'right'}
            onClose={CloseTemplate}
        >
            <Grid className={classes.uploadTemplate}>
                <Grid>
                    <Typography className={classes.title + ' ' + classes.bottomSpace} variant="h6">Upload Files</Typography>
                </Grid>
                <Grid className={classes.topSpace}>
                    <FileUpload handleChange={handleChange} />
                </Grid>
                <Grid className={classes.topSpace + " " + classes.templateAction}>
                    <Button onClick={()=>CloseTemplate()}>Cancel</Button>
                    <Button disabled={state.files?.length === 0} variant="contained" onClick={() => uploadFun()} className={classes.templateupload} color="primary">Upload</Button>
                </Grid>
            </Grid>
        </Drawer>
    </React.Fragment>
}

export default withAllContexts(AddTemplate);