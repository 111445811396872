/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Grid, TextField, Divider, Button, Typography, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SelectBox } from '../';
import { EntityLeftContext } from '../../../contexts';
import { Types,ErrorValidationOfField } from '../../../utils';
import { uuid } from 'uuidv4';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withAllContexts } from "../../../HOCs";
import { getTemplatesList } from "../../../functions";
import environment from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "327px"
    },
    input: {
        width: "100%"
    },
    toparea: {
        padding: "20px"
    },
    bottomarea: {
        padding: "4px 20px",
        textAlign: "right"
    },
    bottomSpace: {
        marginBottom: 20
    },
    fieldType: {
        boxShadow: "unset",
        marginRight: "14px"
    },
    fontFormat:{
        textTransform: "capitalize"
    }
}));



const colors = ["red", "#bdbd4d", "#5bbabe", "rgb(24 108 140)", "blue"]

class AddField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Options: Types,
            title: null,
            type: null,
            selected_color: "red",
            fieldType: "f",
            templateList: [],
            template: null,
            error:{
                type:false,
                title:false,
                template:false
            },
            errorMsg:{
                type:'',
                title:'Please  enter your field',
                template:''
            },
            is_disable:false
        }
        this.appendField = this.appendField.bind(this)
        this.addFieldTo = this.addFieldTo.bind(this);
    }
    componentDidMount() {
        let { editData, isAdd, isTemplate, template } = this.props;
        let templates = getTemplatesList(this.props, isTemplate);
        if (editData && !isAdd) {
            let dataType = editData?.properties?.datatype
            let list = this.props?.properties?.databaseProperties?.filter(val => val.label === dataType?.toLowerCase())[0];
            this.setState({
                title: editData.name,
                templateList: templates,
                type: list,
                fieldType: editData?.attributes?.fieldType || editData?.fieldType,
                selected_color: editData?.attributes?.selected_color,
                template: template
            })
        } else {
            this.setState({ templateList: templates })
        }
    }
    setValue = (name, value) => {
        let errorField = this.state.error;
        let errorMessage = this.state.errorMsg;
        errorField[name]=ErrorValidationOfField(value);
        errorMessage[name]="Please  enter your field";
        if(name==='title'){
            value  = value.trim();
        }
        this.setState({
            [name]: value,
            error:errorField,
            errorMsg:errorMessage
        })
    }
    backendProperty = (type) => {

        return new Promise(async (resolve, reject) => {
            let ArangoProperty = this.props?.properties?.databaseProperties;
            let filterData = ArangoProperty.filter((data) => data.label === type)[0];
            filterData.QDMProperties.properties.fields = [];
            //  return  filterData;

            resolve(filterData);
            // await axios
            //     .post(`${environment.api_url}/api/read_documents`, {
            //         "db_name": `${environment.database}`,
            //         "entity": `${environment.qdm_property_entity}`,
            //         "filter": `${environment.qdm_property_entity}.datatype=='${type.toLowerCase()}' `,
            //         "return_fields": `{${environment.qdm_property_entity}}`
            //     }
            //     ).then(response => {
            //         // let data = converData(response.data);
            //         resolve(response.data.result[0]?.QDMProperties?.properties);
            //         // 
            //     })
            //     .catch(err => {
            //         resolve({})
            //     });

        })
    }
    appendField = async (fields) => {
        let { title, type, selected_color, fieldType } = this.state;
        let { editData, isAdd } = this.props;
        return new Promise(async (resolve, reject) => {
            if (isAdd) {
                 ;
                fields.map(async (val, j) => {
                    if (editData?.attributes?.id === val?.attributes?.id) {
                        fields[j].properties.fields = fields[j].properties.fields ? fields[j].properties.fields : []
                        let dim = {};
                        if (fieldType === 'd') {
                            dim = {
                                fields: [],
                                // fieldCollection: true,
                            }
                        }
                        let dataTypeProperties = await this.backendProperty(type?.label);
                        let  dimensionProperties = dataTypeProperties?.QDMProperties?.properties ?? {}
                        // if(fieldType==='d'){
                        //     dimensionProperties = dataTypeProperties?.QDMProperties?.properties ?? {}
                        // }
                        fields[j].properties.fields.push({
                            name: title,
                            properties: {
                                datatype: fieldType === 'd' ? "dimension" : type.label,
                                // datatype: type.label,

                                ...dim,
                                ...dimensionProperties
                            },
                            fieldType,
                            attributes: {
                                fieldType,
                                id: uuid(),
                                dimentionTitle: title,
                                dimentionID: editData?.attributes?.id,
                                parentID: editData?.attributes?.parentID ?? editData?.attributes?.id,
                                selected_color: selected_color
                            }
                        })
                    } else {
                        if (fields[j].fields) {
                            fields[j].fields = await this.appendField(fields[j].fields)
                        } else if (fields[j].properties.fields) {
                            fields[j].properties.fields = await this.appendField(fields[j].properties.fields)
                        }
                    }

                    return false
                })
            } else {
                await Promise.all(fields.map(async (val, j) => {
                    if (editData?.attributes?.id === val?.attributes?.id) {
                        fields[j] = {
                            ...fields[j],
                            name: title,
                            // properties: {
                            //     datatype: fieldType === 'd' ? null : type.label,
                            // },
                            fieldType,
                            attributes: {
                                ...fields[j].attributes,
                                selected_color: selected_color
                            }
                        }
                        if (type?.label && fields[j].properties.datatype !== type.label) {
                            let propertiy = await this.backendProperty(type.label);
                            let  clonePropertes =  fields[j].properties ;
                            fields[j].properties = {
                                           ...clonePropertes,
                                           ...propertiy?.QDMProperties?.properties,
                                           

                            };
                        }
                        fields[j].properties.fields = fields[j].properties.fields ? fields[j].properties.fields : [];
                        fields[j].properties.datatype = fieldType === 'd' ? null : type?.label;

                    }
                    // if (fields[j]?.properties?.fields?.length > 0) {
                    //     fields[j].properties.fields.map(async(hubchild, k) => {
                    //         if (editData?.attributes?.id === hubchild?.attributes?.id) {
                    //             fields[j].properties.fields[k] = {
                    //                 ...fields[j].properties.fields[k],
                    //                 name: title,
                    //                 properties: {
                    //                     datatype: fieldType === 'd' ? null : type.label,
                    //                 },
                    //                 fieldType,
                    //                 attributes: {
                    //                     ...fields[j].properties.fields[k].attributes,
                    //                     selected_color: selected_color
                    //                 }
                    //             }
                    //             if(type?.label  && fields[j].properties.fields[k].properties?.datatype !== type.label){
                    //                 fields[j].properties.fields[k].properties.dataTypeProperties=  await this.backendProperty(type.label)
                    //             }
                    //         }
                    //         return false;
                    //     })
                    // }
                    else {
                        if (fields[j].fields) {
                            let field;
                            field = await this.appendField(fields[j].fields);
                            fields[j].fields = field;
                        } else if (fields[j].properties.fields) {
                            let field;
                            field = await this.appendField(fields[j].properties.fields)
                            fields[j].properties.fields = field;
                        }
                    }
                    return false
                }))

            }
            resolve(fields);
        })

    }

    addFieldTo = async () => {
        //component state properties
        let { title, type, selected_color, fieldType, template } = this.state;
        //entityleft context properties
        let { list, setEntity } = this.context;
        //props  from parent component
        let { onclose, data, templatetype, editData, isTemplate, customTemplate, resouceTemplates } = this.props;
        const { setCustomEnity } = customTemplate;
        const { setResouceTemplate, resources } = resouceTemplates;
        let selected = data.attributes.id;
        let dataList = [];
        //check  template
        if (isTemplate) {
            dataList = templatetype === 2 ? resources : customTemplate.customTemplate
        } else {
            dataList = list[data?.attributes?.type?.value] ? list[data?.attributes?.type?.value] : [];
        }
        await Promise.all(dataList.map(async (val, i) => {
            if (val.attributes.id === selected) {
                if (editData) {
                    let choosefield = await this.appendField(dataList[i].fields)
                    dataList[i].fields = choosefield;
                } else {
                    //this call first time  add field dimention
                    let dataTypeProperties = {};
                    if (type?.label) {
                         ;
                        await axios
                            .post(`${environment.api_url}/api/read_documents`, {
                                "db_name": `${environment.database}`,
                                "entity": `${environment.qdm_property_entity}`,
                                "filter": `${environment.qdm_property_entity}.datatype=='${type?.label.toLowerCase()}' `,
                                "return_fields": `{${environment.qdm_property_entity}}`
                            }
                            ).then(response => {
                                // let data = converData(response.data);
                                dataTypeProperties = response?.data?.result[0]?.QDMProperties?.properties;
                                //  
                                // let isentityList = dataTypeProperties?.QDMProperties?.properties?.entityLists ?? false;
                                // if (isentityList) {
                                //     delete dataTypeProperties.QDMProperties.properties.entityLists
                                // }
                                // 
                            })
                            .catch(err => {
                            });
                       
                    }

                    let field = dataList[i]?.fields ? dataList[i].fields : []
                    let dim = {}
                    if (fieldType === 'd') {
                        dim = {
                            fields: [],
                            // fieldCollection: true,
                        }
                    }
                    let  dimensionProperties = {}
                    if(fieldType==='d'){
                        dimensionProperties = dataTypeProperties ?? {}
                    }
                    let fieldJson = {
                        name: title,
                        properties: {
                            datatype: fieldType === 'd' ? "dimension" : type.label,
                            ...dim,
                            ... dataTypeProperties ,
                            // ...dimensionProperties
                        },
                        fieldType,
                        attributes: {
                            id: uuid(),
                            table: dataList[i].entity,
                            tableID: dataList[i]?.attributes?.id,
                            selected_color: selected_color
                        }
                    }

                    console.log("fieldJson" , fieldJson)
                     
                    if (template && fieldType === 'd') {
                        fieldJson["properties"].fields = template?.fields ?? [] ;
                    }
                    field.push(fieldJson);
                     
                    dataList[i].fields = field
                }
            }
            return false;
        }));
        if (isTemplate) {
            if (templatetype === 2) {
                setResouceTemplate({
                    ...resouceTemplates,
                    resources: dataList
                })
            } else {
                setCustomEnity({
                    ...customTemplate,
                    customTemplate: dataList
                })
            }
        } else {
            list[data?.attributes?.type.value] = dataList
            setEntity({
                ...this.context,
                list
            })
        }
        if (onclose) {
            onclose()
        }
        this.setState({
            is_disable: false
        });
    }
    //array of recursion call
    checkwithArray = (field,title,status=false)=>{
        let { editData,data } = this.props;
        //edit Data  came from edit field and dimention creation new field
       if(editData){
        for(let i  = 0;i<field?.length;i++){
            if(editData?.attributes?.id===field[i]?.attributes?.id){
                let dumbData  = field[i]?.fieldType==='d' ? field[i]?.properties?.fields : field;
                for(let j  = 0;j<dumbData?.length;j++){
                    if(dumbData[j]?.name===title && editData?.attributes?.id !==dumbData[j]?.attributes?.id){
                        // alert(dumbData[j]?.name)
                        status = true
                        return  status;
                    };
                }
                
            } else {
                if(field[i]?.properties?.fields){
                  status   =  this.checkwithArray(field[i]?.properties?.fields,title,status)

                } else if(field[i]?.properties?.fields){
                  status  = this.checkwithArray(field[i]?.fields,title,status)
                    
                }
            }
            if(status){
                break;
            }
        }
       } else {
           let __checkData = data?.properties?.fields ?? data?.fields;
            for(let i=0;i<__checkData?.length;i++){
                if(__checkData[i].name===title){
                    // alert(__checkData[i].name)
                    status = true;
                    return  status;
                }
            }
       }
        
        return  status;
    }
    //logic  for find field duplicate
    duplicateField = (title)=>{
        let { list } = this.context;
        let { data } = this.props;
        // let entityList = [];
        // for(let _list in list){
        //     entityList = [...list[_list],...entityList];
        // }
        return this.checkwithArray(data?.properties?.fields ?? data?.fields ,title)
    }
    addField = async () => {
        let { title, type,fieldType,template,error,errorMsg } = this.state;
        
        let cloneError  =  error;
        let errorMessage = errorMsg;
        errorMessage.title = 'Please  enter your field';
        let checkError  =  false;
        //arango database defalut value of entity
        let defaultField = ['createdby','createddate','updatedby','updatedate','activestatus','rule_params'];
        //check wether defalut value or not
        if(defaultField.indexOf(title)>=0){
            cloneError.title=true;
            errorMessage.title = 'Default field not allowed';

            this.setState((prevstate)=>({
                ...prevstate,
                error:cloneError,
                errorMsg:errorMessage
            }));
            return;
        };
        //validate duplicate fields
        let _checkduplicate = this.duplicateField(title);
        if(_checkduplicate){
            cloneError.title=true;
            errorMessage.title = 'Duplicate field not allow';


            this.setState((prevstate)=>({
                ...prevstate,
                error:cloneError,
                errorMsg:errorMessage
            }));
            return;
        }
        //check the dimention field
        if(fieldType==='d'){
            if(!title  || title?.trim()?.length<=0 ||  !/([A-Za-z]{1}[\w\-]+)$/y.test(title)){
                checkError=true;
                cloneError.title = true;
            }  else  if(title?.trim()?.length>0){
                cloneError.title  = false
            }
            if(!template  || Object.keys(template)<=0){
                checkError=true;
                cloneError.template = true;
            }  else  if(Object.keys(template)>0){
                cloneError.template  = false
            }
        }else{
            if(!title  || title?.trim()?.length<=0 || !/([A-Za-z]{1}[\w\-]+)$/y.test(title)){
                checkError=true;
                cloneError.title = true;
            }  else  if(title?.trim()?.length>0){
                cloneError.title  = false
            }
            if(!type  || Object.keys(type)<=0){
                checkError=true;
                cloneError.type = true;
            }  else  if(Object.keys(type)>0){
                cloneError.type  = false
            }
        }
        if(checkError){
            this.setState((prevstate)=>({
                ...prevstate,
                error:cloneError
            }));
            return;
        }
        let editType = this.props?.editData?.properties?.datatype;
        let  datalist = this.props?.data;
        let context = this.context;
        if(editType && editType!== type?.label){
            let getRuleId = this.props?.editData?.properties?.Sequence ? Object.values(this.props?.editData?.properties?.Sequence) : [];
            getRuleId = getRuleId.filter(_rule=>_rule.toString().indexOf('Rules/')!==-1);
            let constructRequest = [];
            if(getRuleId.length>0){

            }
            if(getRuleId.length>0){
                let callConfirm =    await   Swal.fire({
                    title: 'if you change to the datatype Sequence Rules will be deleted',
                    // showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Submit'
                  });
                  if(!callConfirm.isConfirmed){
                    return;
                  }
            }
        
            //   .then((result) => {
            //     /* Read more about isConfirmed, isDenied below */
            //     if (result.isConfirmed) {
            //       Swal.fire('Saved!', '', 'success')
            //     } else if (result.isDenied) {
            //       Swal.fire('Changes are not saved', '', 'info')
            //     }
            //   })
            getRuleId.map(rule=>{
                rule.split(',').map(_ruleId=>{
                    let payload = {
                        "db_name":"RuleBuilder",
                        "entity": "Rules",
                        "filter": `Rules.activestatus==true && Rules._id == '${_ruleId}'`,
                        "return_fields": "Rules"
                    }
                    let apiCall = axios.post(`${environment.api_url}/api/read_documents`,payload)
                    constructRequest.push(apiCall)
                })
                
            });
            let  sequenceGeneratorParams = context.list[datalist.entity_group_name].filter(_collectionF=>_collectionF.entity===datalist.entity)[0].fields[0];
            if(sequenceGeneratorParams.name==='rule_params'){
                if(constructRequest.length>0){
                    let responseRules = await Promise.all(constructRequest);
                    responseRules= responseRules.reduce((prev,_fact)=>{return[..._fact.data.result[0].facts.facts,...prev]},[])
                    sequenceGeneratorParams.properties.fields =  JSON.parse(JSON.stringify(sequenceGeneratorParams.properties.fields)).map(_construct=>{
                        responseRules.map(_sequence=>{
                            if(_construct.name===Object.keys(_sequence)[0]){
                       
                                if(_construct.properties.fields.some(_in=>_in.name===_sequence[Object.keys(_sequence)[0]].attr) ){
                                    if(_construct?.properties?.fields){
                                        _construct.properties.fields =  _construct.properties.fields.reduce((prevValue,_in)=>{
                                            if(_in.name===_sequence[Object.keys(_sequence)[0]].attr){
                                                if(_in.count>1){
                                                    _in.count = _in.count-1;
                                                    prevValue.push(_in)
                                                }  
                                                // _in.count = parseInt(_in.count)>0 ? _in.count+1 : 2;
                                            }else{
                                                prevValue.push(_in)
                                            }
                                            return prevValue;
                                        },[]);
                                    }
                                }
                            }
                        })
                        
                       return _construct;
                    });    
                }
            }
            let takeKeys= Object.keys(context.list);
            for(let item of takeKeys){
                context.list[item]=context.list[item].map(_item=>{
                    if(_item.fields[0].name ==='rule_params'){
                        let filterCollectionIndex ;
                        let filterRuleCollection = _item.fields[0]?.properties.fields.filter((_f,ind_)=>{
                            if(_f.name===datalist.entity){
                                filterCollectionIndex = ind_;
                                return true;
                            }
                            return false;
                        });
                        if(filterRuleCollection?.length>0){
                            _item.fields[0].properties.fields[filterCollectionIndex].properties.fields=   _item.fields[0]?.properties?.fields[filterCollectionIndex]?.properties?.fields?.map(__item=>{
                                if(__item?.name==title){
                                    __item.properties.datatype = type.label;
                                }
                                return __item;
                            })                               
                        }
                    }
                    return _item;
                });
            };
            context.list[datalist.entity_group_name] = context.list[datalist.entity_group_name].map(_modifyValue=>{
                if(_modifyValue.entity===datalist.entity){
                    _modifyValue.fields[0]=sequenceGeneratorParams;
                }
                return _modifyValue;
            });
          context.setEntity(context);

        }
        if (type?.QDMProperties?.datatype === 'dimension') {
            this.setState({
                fieldType: 'd',
                is_disable: true

            }, () => {
                this.addFieldTo();
            })
        } else {
            this.setState({
                is_disable: true
            }, () => {
                this.addFieldTo();
            })
        }
    }

    render() {
        let { classes, onclose,editData,isAdd } = this.props;
        let { Options, type, title, selected_color, fieldType, template, templateList,error,is_disable,errorMsg } = this.state;
        
        return (
   
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    <div className={classes.toparea}>
                        <Typography className={classes.bottomSpace} >{isAdd ? 'Add' : editData ? 'Edit':'Add'} Field</Typography>
                        <Grid className={classes.bottomSpace}>
                            <Button className={classes.fieldType} size="small" variant="contained" onClick={(e) => this.setValue('fieldType', "f")} color={fieldType === "f" ? "primary" : ""}>Field</Button>
                            <Button className={classes.fieldType} size="small" variant="contained" onClick={(e) => this.setValue('fieldType', "d")} color={fieldType === "d" ? "primary" : ""}>Dimension</Button>
                        </Grid>
                        <TextField value={title} onChange={(e) => this.setValue('title', e.target.value)} size="small" className={classes.input + ' ' + classes.bottomSpace} label="Field Name" variant="outlined" error={error.title?true:false} helperText={error.title ? errorMsg.title:''} />
                        {fieldType !== "d" && <SelectBox
                            list={this.props?.properties?.databaseProperties ?? []}
                            classname={classes.bottomSpace}
                            onchange={(e, value) => { this.setValue('type', value) }}
                            value={type}
                            title='Type'
                            error={error.type}
                        />}
                        {fieldType === "d" && <SelectBox
                            list={templateList}
                            classname={classes.bottomSpace}
                            onchange={(e, value) => { this.setValue('template', value) }}
                            value={template}
                            title='Template'
                            error={error.template}
                        />}
                        {/* <Grid>
                            <Typography variant="caption">Color:</Typography>
                            {colors.map(val => <Checkbox checked={selected_color === val} onChange={() => this.setValue('selected_color', val)} style={{ color: val }} icon={<FiberManualRecordIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />)}
                        </Grid> */}
                    </div>
                    <Divider />
                    <Grid className={classes.bottomarea}>
                        <Button onClick={() => onclose()} className={classes.fontFormat}>Cancel</Button>
                        <Button onClick={() => this.addField()} size="small" variant="contained" color="secondary" className={classes.fontFormat} disabled={is_disable ? true : false}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

AddField.contextType = EntityLeftContext;

export default withStyles(useStyles)(withAllContexts(AddField));
