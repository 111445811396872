/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Grid, TextField, Divider, Button, Typography, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { EntityLeftContext } from '../../../contexts';
import { withAllContexts } from '../../../HOCs';
import { getTemplatesList } from '../../../functions';
import { SelectBox, TagSelects } from '../common';
import { ErrorValidationOfField } from '../../../utils';
import { uuid } from 'uuidv4';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { converEntityData } from '../../../contexts/entity'
import { Alerts } from '../../alert';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        width: "100%"
    },
    toparea: {
        padding: "20px 20px 0px",
        width: 300
    },
    toparea1: {
        padding: "20px 20px 0px",
        width: 300,
        maxHeight: "78vh",
        minHeight: "78vh",
        overflow: "auto"
    },
    bottomarea: {
        padding: "4px 20px",
        textAlign: "right"
    },
    bottomSpace: {
        marginBottom: 20
    },
    checkBox: {
        padding: 0,
        float: "right"
    },
    fontFormat:{
        textTransform: "capitalize"
    },
    templateEntity:{
        height: "40vh",
        marginBottom: "24px"
    }
}));

const colors = ["red", "#bdbd4d", "#5bbabe", "rgb(24 108 140)", "blue"]
const Types = [
    { label: "Personas", value: "personas" },
    { label: "Things", value: "things" },
    { label: "Ecosystem", value: "ecosystem" },
    { label: "Products and Services", value: "productServices" },
    { label: "Others", value: "others" }
]
const owner = [
    { label: "Manikandan", value: "Manikandan" },
    { label: "Sasikumar", value: "Sasikumar" },
    { label: "Vijay", value: "Vijay" },
    { label: "Gokul", value: "Gokul" },
    { label: "Manoj", value: "Manoj" }
]

class AddEntity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: null,
            title: null,
            description: null,
            type: null,
            p_owner: null,
            s_owner: null,
            selected_color: "red",
            tags: null,
            isActivity: false,
            isAcceptUserInput:false,
            resources: null,
            error:{
                type:false,
                title:false
            },
            errorMsg:{
                type:'Please enter the field',
                title:'Please enter the field'
            },
            activity: {
                create: false,
                update: false,
                // read: false,
                delete: false
            },
            saveopen:true,
            snackmsg:'find',
            autoHideDuration:2000,
            snacktype:'success',
            isSaveEntity:false,
        }
    }
    componentDidMount() {
        const { editData, resouceTemplates } = this.props;
        let res = getTemplatesList(this.props, true, true, true);
        if (editData) {
            this.setState({
                title: editData.entity,
                description: editData?.attributes?.description,
                type: editData?.attributes?.type,
                p_owner: editData?.attributes?.permission?.primary_owner,
                s_owner: editData?.attributes?.permission?.secondary_owner,
                selected_color: editData?.attributes?.style?.color,
                isActivity: editData.isActivity,
                isAcceptUserInput: editData.isAcceptUserInput,
                activity: editData.activity,
            })
        } else {
            this.setState({
                resourcesList: res
            })
        }
    }
    setValue = (name, value) => {
        let errorField = this.state.error;
        let  errorMsg = this.state.errorMsg;
        errorMsg.title ='Please enter the field';
        errorField[name]=ErrorValidationOfField(value);
        if(name==='title'){
            value  = value.trim();
        }
        this.setState({
            [name]: value,
            error:errorField,
            errorMsg
        })
    }
    AddEntity() {
        let { activity, isActivity, tags, isAcceptUserInput ,title, description, type, selected_color, p_owner, s_owner,errorMsg } = this.state;
        let { list, setEntity } = this.context;
        let { onclose, editData, resouceTemplates,renameContext } = this.props;
        let { rename,setRenameObj } = renameContext;
        let cloneState=this.state;
        cloneState.errorMsg.title  = 'Please enter the field';
        let  is_error=false;
       
        if(!title || title.length<=0 ||  !/([A-Za-z]{1}[\w\-]+)$/y.test(title)){
            cloneState.error.title = true;
            is_error=true;
        } else if(title.length>0) {
            cloneState.error.title = false;
        }
        //set all entity to  single array
        let listOfEntity = [];
        for(let _li in list){
            listOfEntity  = [...list[_li],...listOfEntity];
        }
        //duplicate entity check from entitycontext
        for(let i=0;i<listOfEntity?.length;i++){
            if(listOfEntity[i]?.entity === title && editData?.attributes?.id !==listOfEntity[i]?.attributes?.id){
                cloneState.error.title = true;
                cloneState.errorMsg.title = "Duplicate entity not allow";
                is_error=true;

            }
        }
        //push rename object to renameContext
        if(editData){
            let checkwithrename = rename.some(_ck=>_ck.id === editData.attributes.id);
            if(checkwithrename){
                let checksameEntity = false;
                rename = rename.map(_re=>{
                    if(_re.id === editData.attributes.id){
                        _re.renameEntity = title;
                        if(_re.renameEntity === _re.entity){
                            checksameEntity = true;
                        }
                    }
                    return _re;
                });
                if(checksameEntity){
                    rename =  rename.filter(_fl=> _fl.id !==editData.attributes.id);
                }
            } else {
                rename.push({
                    "db_name": sessionStorage.getItem('metadataname'),
                    "entity": editData?.entity,
                    "renameEntity": title,
                    "id":editData.attributes.id
                });
            }
     
            setRenameObj({
                rename
            })
        }
       
        if(!type || Object.keys(type).length<=0){
            cloneState.error.type = true;
            is_error=true;
        } else if(Object.keys(type).length>0) {
            cloneState.error.type = false;
        }
        if(is_error){
            this.setState({
                ...cloneState,
               
            })
            return;
        }
        this.disableSaveBtn();
        const { resources } = resouceTemplates;
        if (editData) {
           
                list[editData.attributes.type.value] && list[editData.attributes.type.value]?.length>0 &&list[editData.attributes.type.value].map((val, i) => {
                    if (val.id === editData.id && val.attributes.id === editData.attributes.id) {
                        list[editData.attributes.type.value][i] = {
                            ...list[editData.attributes.type.value][i],
                            entity: title,
                            status: "draft",
                            entity_group_name: type.value,
                            type,
                            db_objectname: title,
                            isActivity: isActivity,
                            isAcceptUserInput:isAcceptUserInput,
                            // indexes:[],
                            activity: activity,
                            attributes: {
                                ...list[editData.attributes.type.value][i].attributes,
                                type,
                                description,
                                permission: {
                                    secondary_owner: s_owner,
                                    primary_owner: p_owner,
                                },
                                style: {
                                    color: selected_color
                                }
                            },
                        }
                    }
                    return false
                })
                let values = Object.values(list)
                let datalist = []
                values.map(val => {
                  datalist = [...datalist, ...val]
                  return false
                });
               list = converEntityData(datalist);
        } else {
            let data = list[type.value] ? JSON.parse(JSON.stringify(list[type.value])) : [];
            let newTableId = uuid();
            data.push({
                entity: title,
                status: "draft",
                db_objectname: title,
                fields: this?.state?.resources?.fields ?? [
                    {
                        "name": "_id",
                        "properties": {
                          "datatype": "string",
                          "mandatory": false,
                          "length": {
                            "min": "",
                            "max": ""
                          },
                          "Sequence": {
                            "suffix": "",
                            "prefix": "",
                            "StartValue": 0,
                            "SequenceId": "",
                            "isSequence": false,
                            "IncrementBy": 0,
                            "MaxValue": 0,
                            "IsReset": false
                          },
                          "autoGenerate": false,
                          "defaultValue": "",
                          "IsLOV": false,
                          "LOVType": "",
                          "LOV": [
                            {
                              "key": "",
                              "value": ""
                            }
                          ],
                          "LOV_value_fields": "value",
                          "LOV_key_field": "key",
                          "LOV_ref_collection": "",
                          "isCaseSensitive": false,
                          "IsPseducode": false,
                          "Pseducode": "Now()",
                          "CaseSensitive": "uppercase",
                          "regexPattern": "",
                          "Unique": true,
                          "Security": {
                            "Encryption": {
                              "Script": "AES"
                            }
                          }
                        },
                        "fieldType": "f",
                        "attributes": {
                          "id": uuid(),
                          "table": title,
                          "tableID": newTableId,
                          "selected_color": "red"
                        }
                      }
                ],
                indexes:[],
                entity_group_name: type.value,
                isActivity: isActivity,
                isAcceptUserInput:isAcceptUserInput,
                activity: activity,
                attributes: {
                    description,
                    tags: tags,
                    id: newTableId,
                    type,
                    left: 10,
                    top: 10,
                    permission: {
                        secondary_owner: s_owner,
                        primary_owner: p_owner,
                    },
                    style: {
                        color: selected_color
                    }
                }
            });
            list[type.value] = data;
            // let message = this.props.isTemplate ? 'Template created successfully' : 'Entity created successfully';
            // this.setState(prevState=>({
            //     ...prevState,
            //     saveopen:true,
            //     snackmsg: message,
            //     autoHideDuration:2000,
            //     snacktype:'success'
            // }))
        }
        setEntity({
            ...this.context,
            list
        })
        if (onclose) {
            onclose()
        }
    }
    closeSnackBar  =  ()=>{
        this.setState(prevState=>({
            ...prevState,
            saveopen:false
        }));
    }
    disableSaveBtn = () =>{
        const { customTemplate } = this.props.customTemplate;
        this.setState(prevState=>({
            ...prevState,
            isSaveEntity:true
        }));
        const cloneState = this.state;
        if(this.props.onSaveFun){
            cloneState.errorMsg.title  = 'Please enter the field';
            let  is_error=false;
           
            if(!cloneState?.title || cloneState?.title?.length<=0 ||  !/([A-Za-z]{1}[\w\-]+)$/y.test(cloneState?.title)){
                cloneState.error.title = true;
                is_error=true;
            } else if(cloneState?.title.length>0) {
                cloneState.error.title = false;
            }
            //set all entity to  single array
            let listOfEntity = customTemplate;
            // for(let _li in list){
            //     listOfEntity  = [...list[_li],...listOfEntity];
            // }
            //duplicate entity check from entitycontext
            for(let i=0;i<listOfEntity?.length;i++){
                if(listOfEntity[i]?.entity === cloneState.title){
                    cloneState.error.title = true;
                    cloneState.errorMsg.title = "Duplicate entity not allow";
                    is_error=true;
                    break;
                }
            }
             
            if(!is_error){
                this.props.onSaveFun(this.state)
                this.props.onclose()
            }
        }
        this.setState(prevState=>({
            ...prevState,
            ...cloneState,
            isSaveEntity:false
            
        }));
    }
    render() {
        let { classes, onclose, isHeight, editData } = this.props;
        let { activity, tags, resources, resourcesList, isActivity, isAcceptUserInput, title, description, type, p_owner, s_owner, selected_color,error,isSaveEntity,errorMsg } = this.state;
        return (
            <Grid container className={classes.root}>
         
                <Grid item md={12} className={(this.props.isTemplate && isActivity)?  classes.templateEntity : ''}>
                    <div className={isHeight ? classes.toparea1 : classes.toparea}>
                        <Typography className={classes.bottomSpace} >{editData ? 'Edit'  : 'Add' } {this.props.isTemplate ? 'Template' : 'Entity'}</Typography>
                        <TextField value={title} onChange={(e) => this.setValue('title', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label={(this.props.isTemplate ? 'Template' : 'Entity') + " Name"} variant="outlined" error={error.title ? true:false}  helperText={error.title ? errorMsg.title:''} />
                        {!this.props.isTemplate && <React.Fragment>
                            <SelectBox
                                list={owner}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('p_owner', value) }}
                                value={p_owner}
                                title='Primary Data Owner'
                            />
                            <SelectBox
                                list={owner}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('s_owner', value) }}
                                value={s_owner}
                                title='Secondary Data Owner'
                            />
                            <SelectBox
                                list={Types}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('type', value) }}
                                value={type}
                                title='Select Types'
                                error={error.type}
                            />
                            {!editData && <React.Fragment>
                                <SelectBox
                                    list={resourcesList}
                                    classname={classes.bottomSpace}
                                    onchange={(e, value) => { this.setValue('resources', value) }}
                                    value={resources}
                                    title='Select Resource'
                                />
                                <TagSelects
                                    // list={tagsList}
                                    classname={classes.bottomSpace}
                                    onchange={(e, value) => { this.setValue('tags', value) }}
                                    value={tags}
                                    placeholder={"Select Tags"}
                                    title='Select Tags'
                                />
                            </React.Fragment>
                            }
                            <TextField value={description} onChange={(e) => this.setValue('description', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label="Description" multiline rows={4} variant="outlined" />
                        </React.Fragment>}
                        <Grid container className={classes.bottomSpace}>
                            <Grid md={6}>
                                <Typography>Activity Log</Typography> 
                            </Grid>
                            <Grid md={6}>
                                <Checkbox className={classes.checkBox} checked={isActivity} onChange={(event) => this.setState({ isActivity: event.target.checked })} name="isActivity" />
                            </Grid>
                        </Grid>
                        {isActivity &&
                            ActivityData.map(val => <Grid container className={classes.bottomSpace}>
                                <Grid md={6}>
                                    <Typography>{val.label}</Typography>
                                </Grid>
                                <Grid md={6}>
                                    <Checkbox className={classes.checkBox} checked={activity[val.state]} onChange={(event) => this.setState({ activity: { ...activity, [val.state]: event.target.checked } })} name="isActivity" />
                                </Grid>
                            </Grid>)
                        }
                       <Grid container className={classes.bottomSpace}>
                            <Grid md={6}>
                                <Typography>Accept User Input</Typography> 
                            </Grid>
                            <Grid md={6}>
                                <Checkbox className={classes.checkBox} checked={isAcceptUserInput} onChange={(event) => this.setState({ isAcceptUserInput: event.target.checked })} name="isAcceptUserInput" />
                            </Grid>
                        </Grid>

                        <Grid>
                            <Typography variant="caption">Color:</Typography>
                            {colors.map(val => <Checkbox checked={selected_color === val} onChange={() => this.setValue('selected_color', val)} style={{ color: val }} icon={<FiberManualRecordIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />)}
                        </Grid>

                    </div>
                    <Divider />
                    <Grid className={classes.bottomarea}>
                        <Button onClick={() => onclose()} className={classes.fontFormat}>Cancel</Button>
                        {
                            isSaveEntity ? 
                            <Button  size="small" variant="contained" color="secondary" className={classes.fontFormat} >Save</Button>
                            :
                            <Button onClick={this.props.onSaveFun ? () => {
                                this.disableSaveBtn();
                              
                            } : () => this.AddEntity()} size="small" variant="contained" color="secondary" className={classes.fontFormat} >Save</Button>
                        }
                       
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

AddEntity.contextType = EntityLeftContext;

export default withStyles(useStyles)(withAllContexts(AddEntity));

const ActivityData = [
    { label: "Create", state: "create" },
    { label: "Update", state: "update" },
    // { label: "Read", state: "read" },
    { label: "Delete", state: "delete" },
]
