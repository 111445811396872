import environment from '../config';
import axios from 'axios';

export const deleteSaveAction = async(entityId) =>{
    return new Promise(async(resolve,reject)=>{
      try{
        debugger;
        let _ = await ReadDocumentForEnterprise();
        let _Qpas  = await ReadDocumentForQPAS();
        debugger;
        if(Object.keys(_?.data?.result[_.data.result.length - 1]?.EnterpriseModuler?.attributes ?? {}).length>0){
            let  list = _?.data?.result[_.data.result.length - 1]?.EnterpriseModuler?.attributes;
            let is_filter = false;
            // //delete the matching entity designer table on enterprisemoduler
            // Object.keys(list ?? {}).map(_key=>{
            //     list[_key] = list[_key].filter(_li=>_li.id!==entityId);
            // })
            //create object from enterprisemoduler read document
            Object.keys(list).map(_key=>{
              list[_key] = list[_key].filter(_li=>{
               if(_li.id!==entityId) {
                 return true;
               }
               else{
                 is_filter = true;
                 return false;
               }
              });
            })
            // let readDocumentEnterPrise  = [];
            // Object.values(_?.data?.result[_.data.result.length - 1]?.EnterpriseModuler?.attributes).map(_read=>{
            //   readDocumentEnterPrise = [...readDocumentEnterPrise,..._read];
            // });
            // //create object  from filter array of enterprisemoduler
            // let  filerEnterpriseData  = [];
            // Object.values(list).map(_value=>{
            //   filerEnterpriseData = [...filerEnterpriseData,..._value]
            // })
            if(is_filter ){
              await SaveEnterPriseModuler(list)
              .catch((error)=>{
                resolve(true);
              })

            }

        }
        debugger;
        //Qpas Delete process of logic
        if(Object.keys(_Qpas?.data?.result[_Qpas?.data?.result?.length - 1]?.QPAS?.attributes ?? {}).length>0){
          let  list = _Qpas?.data?.result[_Qpas?.data?.result?.length - 1]?.QPAS?.attributes;
          let  is_filter = false;
          //match delete field are filter of qpas
          Object.keys(list).map(_key=>{
            if(Array.isArray(list[_key]) ){
              list[_key] = list[_key]?.filter(_li=>{
                if(_li?.id){
                  if(_li?.id!==entityId) {
                    return true;
                  }
                  else{
                    is_filter = true;
                    return false;
                  }
                } else{
                  return true;
                }
          
               });
            }
         
          })
          //check if the qpas data match to  the entity designer table
          if(is_filter){
            await SaveQPAS(list)
            .catch(()=>{
              console.log('QPAS Save  Error');
              resolve(true);
            });

          };
        

        }
        resolve(true)

      }
      catch(error){
              resolve(true)

      }
    });
    
}

//compare QPAS read document data and enterprisemoduler Data
export const compareQPASandEnterprise = ()=>{
  return new Promise(async(resolve,reject)=>{
    try{
      debugger;
      let enterpriseData = await ReadDocumentForEnterprise();
      let QPASData = await ReadDocumentForQPAS();
      debugger
      if (
        Object.keys(
          enterpriseData?.data?.result[enterpriseData.data.result.length - 1]?.EnterpriseModuler?.attributes ?? {}
        ).length > 0
      ) {
        enterpriseData  =  enterpriseData?.data?.result[enterpriseData.data.result.length - 1]?.EnterpriseModuler?.attributes;

      } else{
        enterpriseData = defaultJson;
      }
      if (
        Object.keys(
          QPASData?.data?.result[QPASData.data.result.length - 1]?.QPAS?.attributes ??  {}
        ).length > 0
      ) {
        QPASData = QPASData.data.result[QPASData.data.result.length - 1].QPAS.attributes;
      } else {
        QPASData = {};

      }
      // enterpriseData = Object.keys(enterpriseData).length > 0 ? enterpriseData : defaultJson;
      // QPASData = Object.keys(QPASData).length > 0 ? QPASData : {};

      let compareJson = compareArray(enterpriseData, QPASData);
      resolve(compareJson)
    }
    catch(error){
      resolve(defaultJson) 
    }
     

  })
}
export const ReadDocumentForEnterprise = () =>{
   
    return new Promise(async(resolve,reject)=>{
        let valueE = sessionStorage.projectId ?? 'ad8c5c3e-161f-4635-8b46-4b857fa6071e';
        
        let enterPriseparams = {
              db_name: `${environment.database}`,
              entity: `${environment.qdm_enterprisemodeler_entity}`,
              filter: `${environment.qdm_enterprisemodeler_entity}.EnterpriceModelerid == '${valueE}' sort ${environment.qdm_enterprisemodeler_entity}._key DESC limit 1`,
              return_fields: `{${environment.qdm_enterprisemodeler_entity}}`
        }
          await axios.post(`${environment.api_url}/api/read_documents`,enterPriseparams)
          .then(async(_)=>{
              resolve(_)
          })
          .catch((error)=>{
            resolve({})
         });
    })

   
}

export const ReadDocumentForQPAS = () =>{
   
  return new Promise(async(resolve,reject)=>{
      let valueE = sessionStorage.projectId ?? 'ad8c5c3e-161f-4635-8b46-4b857fa6071e';
      
      let qpasparams =  {
        db_name: `${environment.database}`,
        entity: `${environment.qdm_qpas_entity}`,
        filter: `${environment.qdm_qpas_entity}.qpasid == '${valueE}' sort ${environment.qdm_qpas_entity}._key DESC limit 1`,
        return_fields: `{${environment.qdm_qpas_entity}}`,
      }
        await axios.post(`${environment.api_url}/api/read_documents`,qpasparams)
        .then(async(_)=>{
            resolve(_)
        })
        .catch((error)=>{
          resolve({})
       });
  })

 
}

export const SaveQPAS = (Data) =>{
  return new Promise(async(resolve,reject)=>{
      let dataList = {
          db_name: `${environment.database}`,
          entity: `${environment.qdm_qpas_entity}`,
          is_metadata: false,
          projectname: sessionStorage.getItem('metadataname')
            ? sessionStorage.getItem('metadataname')
            : 'CRAYOND3',
          doc: {
            metadataId: sessionStorage.getItem('metadataId')
              ? sessionStorage.getItem('metadataId')
              : '0f9dc6ce-1d9b-4abd-a83b-462e319f7aa4',
              qpasid: sessionStorage.getItem('projectId')
              ? sessionStorage.getItem('projectId')
              : 'ad8c5c3e-161f-4635-8b46-4b857fa6071e',
            projectname: sessionStorage.getItem('metadataname')
              ? sessionStorage.getItem('metadataname')
              : 'CRAYOND3',
            attributes: Data 
          }
        }
        let data = JSON.stringify([dataList])
        var config = {
          method: 'post',
          url: `${environment.api_url}/api/upsert_document`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        }
   
        await axios(config)
          .then(async response => {
            resolve(true);
          })
          .catch(err => {
            resolve(false);
          })
  })
 
}

export const SaveEnterPriseModuler = (Data) =>{
    return new Promise(async(resolve,reject)=>{
        let dataList = {
            db_name: `${environment.database}`,
            entity: `${environment.qdm_enterprisemodeler_entity}`,
            is_metadata: false,
            projectname: sessionStorage.getItem('metadataname')
              ? sessionStorage.getItem('metadataname')
              : 'CRAYOND3',
            doc: {
              metadataId: sessionStorage.getItem('metadataId')
                ? sessionStorage.getItem('metadataId')
                : '0f9dc6ce-1d9b-4abd-a83b-462e319f7aa4',
                EnterpriceModelerid: sessionStorage.getItem('projectId')
                ? sessionStorage.getItem('projectId')
                : 'ad8c5c3e-161f-4635-8b46-4b857fa6071e',
              projectname: sessionStorage.getItem('metadataname')
                ? sessionStorage.getItem('metadataname')
                : 'CRAYOND3',
              attributes: Data 
            }
          }
          let data = JSON.stringify([dataList])
          var config = {
            method: 'post',
            url: `${environment.api_url}/api/upsert_document`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          }
     
          await axios(config)
            .then(async response => {
              resolve(true);
            })
            .catch(err => {
              resolve(false);
            })
    })
   
}

const defaultJson = {
  // borderColor: ['#007aff','#59b191', '#fcc223'],
  arrow: [],
  enterPriseList: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
    // {
    //   id: "2",
    //   no: "2",
    //   data: { value: "", borderColor: "#59b191" },
    // },
  ],
  things: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],
  eco: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],

  product: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],
  others: [
    // {
    //   id: "1",
    //   no: "1",
    //   data: { value: "", borderColor: "#007aff" },
    // },
  ],
  Personas: [
    {
      id: "1",

      imgUrl: "https://picsum.photos/200/300?random=7",
      name: "Patient",
    },
    {
      id: "2",
      imgUrl: "https://picsum.photos/200/300?random=8",
      name: "Receptionist",
    },
    {
      id: "3",
      imgUrl: "https://picsum.photos/200/300?random=9",
      name: "Front Desk",
    },
    {
      id: "4",
      imgUrl: "https://picsum.photos/200/300?random=4",
      name: "Doctor",
    },
    {
      id: "5",
      imgUrl: "https://res.cloudinary.com/demo/image/upload/smiling_man.jpg",
      name: "Nurse",
    },
  ],
  Medialibrary: [
    {
      id: "1",
      imgUrl: "https://picsum.photos/200/300?random=1",
    },
    {
      id: "2",
      imgUrl: "https://picsum.photos/200/300?random=2",
    },
    {
      id: "3",
      imgUrl: "https://picsum.photos/200/300?random=3",
    },
    {
      id: "4",
      imgUrl: "https://picsum.photos/200/300?random=4",
    },
    {
      id: "5",
      imgUrl: "https://picsum.photos/200/300?random=6",
    },
  ],
};


const compareArray = (enterprice, qpass) => {
  //enterprise  product array data
  let product = enterprice?.product ?? [];
  //create product id from enterprisemoduler product list
  let productIds = product.map(val => val.id);
  //qpas add story array  list =  new product of enterprise moduler
  let story = qpass?.addstory ?? [];
  //qpas persona array list = new enterpriselist
  let qpersonas = qpass?.library?.Personas ?? [];
  //enterprise persona array data
  let epersonas = enterprice?.enterPriseList;
  //create persona id from enterprisemoduler
  let epersonasIds = epersonas.map(val => val.id);
  //add new persona
  //qpas personas compare to enterprisemoduler  id,if new of qpas  persona, add to enterprise => enterpriseList
  qpersonas.map(val => {
    if (epersonasIds.indexOf(val.id) === -1) {
      let setpersonasData = createPersonas(val.name, val.id, epersonas.length + 1)
      enterprice.enterPriseList.push(setpersonasData);
    }
  })
  //add new product
  //qpas story compare to enterprise moduler product id, if new of qpas story add to  enerprise => product
  story.map(val => {

    if (productIds.indexOf(val.id) === -1) {
      let setstoryData = createStory(val.title, val.id, product.length + 1)
      enterprice.product.push(setstoryData);
    }
  })
  //edit persona
  debugger;
  qpersonas.map(val => {
    if (epersonasIds.indexOf(val.id) >=0) {

      // let setpersonasData = createPersonas(val.name, val.id, epersonas.length + 1)
      enterprice.enterPriseList = enterprice.enterPriseList.map(_edit=>{
        if(_edit.id===val.id && _edit?.data?.value !== val?.name){
          _edit.data.value = val.name;
        }
        return _edit;
      })
    }
  });
  //edit  product
  story.map(val => {
    if (productIds.indexOf(val.id) >=0) {

      // let setpersonasData = createPersonas(val.name, val.id, epersonas.length + 1)
      enterprice.product = enterprice.product.map(_edit=>{
        if(_edit.id===val.id && _edit?.data?.value !== val?.title){
          _edit.data.value = val?.title ;
        }
        return _edit;
      })
    }
  });
  //delete product compare to qpas story 
  //create ids from qpas story
  // let pickStoryIds = story.map(_=>_.id);
  // enterprice.product.map(val => {
  //   if (pickStoryIds.indexOf(val.id) ===-1) {

  //     // let setpersonasData = createPersonas(val.name, val.id, epersonas.length + 1)
  //     enterprice.product = enterprice.product.filter(__=>__.id !==val.id);
  //   }
  // });
  //delete persona compare to qpas persona
  //create ids from qpas persona
  // let pickPersonaIds = qpersonas.map(_=>_.id);
  // enterprice.enterPriseList.map(val => {
  //   if (pickPersonaIds.indexOf(val.id) ===-1) {

  //     // let setpersonasData = createPersonas(val.name, val.id, epersonas.length + 1)
  //     enterprice.enterPriseList = enterprice.enterPriseList.filter(__=>__.id !==val.id);
  //   }
  // });


  return enterprice;
}


const createStory = (text, id, count) => {
  return {
    name: "step",
    no: count,
    id: id,
    data: {
      value: text,
      chips: [],
      borderColor: "hsla(" + Math.random() * 360 + ", 100%, 50%, 1)",
    },
  };
}
const createPersonas = (text, id, count) => {
  return {
    name: "step",
    no: count,
    id: id,
    data: {
      value: text,
      chips: [],
      borderColor: "hsla(" + Math.random() * 360 + ", 100%, 50%, 1)",
    },
  };
}