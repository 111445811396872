/**
 * @author prakash p
 * @email prakash@qdmplatforms.com
 * @create 16/03/2021
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { Grid, Drawer } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import EnterpriseMainsection from "../../components/enterprise_modeler/enterpriseMainsection";
import LeftSection from "../../components/enterprise_modeler/leftSection/library";
// the width of drawer
const drawerWidth = 220;

// styles of component
const useStyles = (theme) => ({
  root: {
    overflowY: "scroll",
    flexGrow: 1,
    height: "85vh",
  },
  rootofdrawer: {
    display: "flex",
    height: "auto !important",
    width: "100% !important",
    "& .MuiDrawer-paper": {
      position: "absolute",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      position: "absolute",
    },
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflow: "hidden",
    // marginTop: '1px',
    position: "relative",
    width: drawerWidth,
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // }),
    border: "none",
    boxShadow: "0px 0px 6px 0 rgb(17 15 71 / 6%)",
  },
  drawerClose: {
    overflow: "hidden",
    marginTop: "1px",
    position: "relative",
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen
    // }),
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: "0px !important",
    },
    border: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
    marginTop: "20px",
    padding: "0px 2px",
    backgroundColor: "white",
    borderRadius: "50%",
    width: "30px",
    height: "30px !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    display: "flex",
  },
});
// end

class Enterprise extends React.Component {
  // state of the component
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  //   end

  componentDidMount() {}

  //   handle Drawer open close funcy
  handleDrawer = () => {
    const state = this.state;
    this.setState({
      ...state,
      open: !state.open,
    });
  };
  //   end

  render() {
    //   props variables of the components
    let { classes } = this.props;

    //   state variables of the components
    const { open } = this.state;

    return (
      <Grid container className={classes.root}>
        {/* the drawer component */}
        <div className={classes.rootofdrawer}>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {/* The left section component here */}
            <LeftSection />
            {/* end */}
          </Drawer>

          <main className={classes.content}>
            {/* The center section component here */}
            <div
              style={{
                marginLeft: open ? "-10px" : "20px",
                zIndex: 100000,
                border: "1px solid #d3d3d34d",
              }}
              onClick={() => this.handleDrawer()}
              className={classes.toolbar}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </div>
            <EnterpriseMainsection />
            {/* end */}
          </main>
        </div>
        {/* end */}
      </Grid>
    );
  }
}
export default withStyles(useStyles)(Enterprise);
// export of component
