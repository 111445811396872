import React,{useEffect,useState} from 'react';
import {
    Button,  Divider, Grid,  TextField, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "395px"
    },
    headerRoot: {
        padding: "9px 7px"
    },
    headertitleRoot: {
        display: "flex",
        margin: "auto"
    },
    headertitle: {
        fontWeight: 600
    },
    headerButtonRoot: {
        textAlign: "right"
    },
    headerButton: {
        height: "26px",
        marginLeft: "10px"
    },
    contentRoot: {
        paddingTop: 0,
        borderTop: "1px solid #f3f3f3"
    },
    currentTableName: {
        fontWeight: 600,
        fontSize: "14px",
        marginRight: "8px",
        marginLeft: "20px"
    },
    relationshipCaption: {
        width: "100%",
        marginRight: "10px"
    },
    selectTableField: {
        marginLeft: "20px"
    },
    selectTable: {
        width: "166px",
        display: "inline-block",
        '& .MuiFormControl-marginNormal': {
            marginTop: 0
        }
    },
    boxPadding: {
        padding: "10px"
    },
    deleteFont:{
        color: "white",
        background: "#f50057",
        padding: "5px",
        textTransform: "capitalize",
        fontSize: "12px",
        '&:hover': {
            background: "#f50057",
        }
    },
    fontFormat:{
        textTransform: "capitalize"
    },
    deleteBtn:{
        cursor: "pointer",
        background: "#f24a58",
        borderRadius: "7px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "26px",
        height: "31px",
        color: "white"
    },
    deleteBtnParent:{
        display: "flex",
        justifyContent: "flex-end"
    }
}));

function Keypopup(props) {
    const { classes } = props;
    const  [  keyData,setKeyData ] = useState([]);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    useEffect(()=>{
        if(props.lovKeyData){
            let constructLovKey = props?.lovKeyData?.map((_lovkey=>{
                return {
                    keyname:_lovkey,
                    error:false
                };
            }));
            setKeyData(constructLovKey);
        }
    },[]);
    // useEffect(()=>{
    //     setKeyData(keyData);
       
    // },[props.lovKeyData]);
    const handleClose = () => {
        props.handleCloseKey();
        
    };
    const onSuccess = async() => {
        if(keyData?.length<=0){
            alert("please add key");
            return;
        }
        let _mapkey =  keyData.map((_d,_index)=>{
            if(_d?.keyname && _d.keyname?.length>0){
                _d.error = false;
            } else {
                _d.error = true;
            }

            return _d
        });
        let iserror  = _mapkey.some(_c=>_c.error);
        if(iserror){
          setKeyData(_mapkey);
            return ;
        } 
        let splitKey = _mapkey.map(_=>{
            return  _.keyname;
        });
        props.onKeySave(splitKey);
    };
   const addKey = ()=>{
       let _keyData = keyData;
       let params = {
           keyname:"",
           error:false
       }
       _keyData.push(params)
        setKeyData(keyData);
        forceUpdate();

    };
   const deleteKey = (index)=>{
        let _filterkey =  keyData.filter((_d,_index)=>_index !== index);
        setKeyData(_filterkey);
    };
   const editKey = (value,index) =>{
        let _mapkey =  keyData.map((_d,_index)=>{
            if(_index === index && value && value?.length>0){
                _d.keyname = value;
                _d.error = false;
            } else if(_index === index){
                _d.keyname = value;
                _d.error = true;
            }

            return _d
        });
        setKeyData(_mapkey);
    }

    return (
        <>
            {/* {snackopen && <Alerts severity={snacktype} open={snackopen} vertical={'top'} horizontal={'right'} msg={snackmsg} onclose={() => this.closeSnackBar()} />} */}
         
                 <Grid className={classes.root} >
                        <Grid md={12} container className={classes.headerRoot}>
                            <Grid item md={6} className={classes.headertitleRoot} style={{ textAlign: "left" }}>
                                <Typography variant="h6" style={{fontSize: "1.06rem"}}>LOV Keys</Typography>
                            </Grid>
                            <Grid item md={6} className={classes.headerButtonRoot} >
                                <Button className={classes.headerButton + ' ' +  classes.fontFormat}  onClick={() => handleClose()}>Cancel</Button>
                                <Button className={classes.headerButton  + ' ' +  classes.fontFormat} onClick={() => onSuccess()} variant="contained" color="secondary">Save</Button>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item md={12} className={classes.contentRoot}>
                                <Grid item md={12} className={classes.boxPadding}>
         <Button className={classes.headerButton  + ' ' +  classes.fontFormat} color='primary' onClick={() => addKey()} variant="contained" color="secondary">Add</Button>

                                </Grid>
                                {
                                    keyData?.map((_keyItem,_index)=>(
                                        <Grid item md={12} className={classes.boxPadding}>
                                            <Grid container style={{marginBottom:5}}>
                                                <Grid item md={6}>
                                                    <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: "bold"}}>Key {_index+1}</Typography>

                                                </Grid>
                                                <Grid item md={6} className={classes.deleteBtnParent}>
                                                    <div className={classes.deleteBtn}>
                                                        <DeleteOutlinedIcon onClick={() => deleteKey(_index)}/> 
                                                    </div>
                                                {/* <Button className={classes.headerButton  + ' ' +  classes.fontFormat} color='primary' onClick={() => deleteKey(_index)} variant="contained" color="secondary">Delete</Button> */}

                                                </Grid>
                                            </Grid>
                                            {/* <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: 400}}>Key {_index+1}</Typography>
                                            <Button className={classes.headerButton  + ' ' +  classes.fontFormat} color='primary' onClick={() => deleteKey(_index)} variant="contained" color="secondary">Delete</Button>
                                         */}
                                            <TextField
                                                value={_keyItem.keyname}
                                                fullWidth={true}
                                                onChange={e => editKey( e.target.value, _index)}
                                                // id="relationship-caption" 
                                                error={_keyItem.error ? true : false}
                                                helperText={_keyItem.error ? "Please enter the key value" : ''}
                                                size="small"
                                                className={classes.relationshipCaption}
                                                placeholder={"key  "+(_index+1)} variant="outlined"
                                            />
                                        </Grid>
                                    ))
                                }
                               
                        </Grid>
                    </Grid>
                
            
            
            </>
    );
}
export default withStyles(useStyles)(Keypopup);