/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Grid, ListItem, List, ListItemText, Collapse, Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        padding: 0
    },
    listItem: {
        padding: "10px 6px"
    },
    listItemText: {
        margin: "0px"
    },
    iconLeft: {
        marginLeft: "5px"
    },
    listicon: {
        height: "24px",
        marginLeft: "6px",
        marginRight: "4px",
        '& svg': {
            width: "20px",
            color: "#5f5d5d"
        }
    },
    paddingChanges:{
        paddingLeft:"4px"
    },
    notificationIcon:{
        marginLeft: "5px",
        background: "blue",
        color: "white",
        borderRadius: "50%",
        width: "18px",
    height: "18px !important"
       
    },
    notificationFont:{
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center"
    }
}));

class CollapseListCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: null
        }
    }
    componentDidMount() {
        let { defultOpen } = this.props;
        if (defultOpen) {
            this.setState({ open: defultOpen })
        }
    }
    handleClick() {
        let { open } = this.state;
        let { onClickCollapse } = this.props;
        open = open ? null : true
        this.setState({
            open
        })
        if (onClickCollapse) {
            onClickCollapse()
        }
    }
    render() {
        let { classes, title, collapseChildren, icon, iconRight, textStyle = {}, isOpenCollapse,NotificationCount } = this.props;
        let { open } = this.state;
        let openCollapse = isOpenCollapse !== undefined ? isOpenCollapse : open
        return (
            <Grid container className={classes.root + ' ' +  classes.paddingChanges}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}
                >
                    <ListItem button className={classes.listItem} onClick={() => { this.handleClick() }}>
                        {!iconRight && <React.Fragment>{openCollapse ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</React.Fragment>}
                        {icon && <Typography varient="body1" className={classes.listicon} >{icon}</Typography>}
                        <ListItemText
                            className={`${classes.listItemText} ${classes.iconLeft}`}
                            secondary={title}
                            secondaryTypographyProps={{
                                style: textStyle
                            }}
                        />
                        {
                            NotificationCount ? <React.Fragment>
                                {/* <Grid style={{display:"flex"}}> */}
                            <Grid className={classes.notificationIcon}>
                               <Typography className={classes.notificationFont}>{NotificationCount}</Typography>
                            </Grid>
                        {/* </Grid> */}
                            </React.Fragment>:''
                        }
                        {(iconRight) && <React.Fragment>{openCollapse ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</React.Fragment>}
                    </ListItem>
                    {openCollapse && <Divider />}
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        {collapseChildren}
                    </Collapse>
                    <Divider />
                </List>
            </Grid >
        )
    }
}

export default withStyles(useStyles)(CollapseListCard);