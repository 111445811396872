/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Button, Divider, Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { EntityLeftContext, EntityRelationShipContext } from '../../../contexts';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withAllContexts } from '../../../HOCs';
import { loopRelationsShip } from '../../../functions';
import { uuid } from 'uuidv4';
import { SelectBox } from '../..';
import { ErrorValidationOfField } from '../../../utils';
import { Alerts } from '../../../components';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "395px"
    },
    headerRoot: {
        padding: "9px 7px"
    },
    headertitleRoot: {
        display: "flex",
        margin: "auto"
    },
    headertitle: {
        fontWeight: 600
    },
    headerButtonRoot: {
        textAlign: "right"
    },
    headerButton: {
        height: "26px",
        marginLeft: "10px"
    },
    contentRoot: {
        paddingTop: 0,
        borderTop: "1px solid #f3f3f3"
    },
    currentTableName: {
        fontWeight: 600,
        fontSize: "14px",
        marginRight: "8px",
        marginLeft: "20px"
    },
    relationshipCaption: {
        width: "100%",
        marginRight: "10px"
    },
    selectTableField: {
        marginLeft: "20px"
    },
    selectTable: {
        width: "166px",
        display: "inline-block",
        '& .MuiFormControl-marginNormal': {
            marginTop: 0
        }
    },
    boxPadding: {
        padding: "10px"
    },
    deleteFont:{
        color: "white",
        background: "#f50057",
        padding: "5px",
        textTransform: "capitalize",
        fontSize: "12px",
        '&:hover': {
            background: "#f50057",
        }
    },
    fontFormat:{
        textTransform: "capitalize"
    }
}));


class AddRelationShip extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            relations: [
                {
                    id:uuid(),
                    caption: null,
                    targetTable: null,
                    targetTableColumn: null,
                    error:{
                        targetTable:false,
                        targetTableColumn:false
                    },
                    message:{
                        targetTableColumn:"Please select same datatype",
                        targetTable:"Please select Table"
                    }
                }
            ],
            isedit: false,
            snackopen:false, 
            snackmsg:'',
            snacktype:'success'
        }
        this.addMoreField = this.addMoreField.bind(this);
    }
    componentDidMount() {
          
        let { relationshipcontext, currentField = {} } = this.props;
         
        let entityLists = this.getAlltable(Object.values(this.context.list));
         
        let data = relationshipcontext.list.filter(val => currentField?.attributes?.id === val?.attributes?.currentField?.attributes?.id);
         
        if (data.length > 0) {
             
            let list = data.map(relation => {
                 
                return {
                    id:relation?.id ?? uuid(),
                    caption: relation.db_objectname,
                    targetTable: entityLists.filter(_=>_.entity === relation.attributes.targetTable.entity)[0],
                    attributes: relation.attributes,
                    targetTableColumn: relation.attributes.targetTableColumn,
                    message:{
                        targetTableColumn:"Please select same datatype",
                        targetTable:"Please select table"
                    },
                    error:{
                        targetTable:false,
                        targetTableColumn:false
                    },
                    
                }
                 
            })
            this.setState({
                relations: list,
                isedit: true
            })
        }
    }
    setValue = (name, value, index) => {
        let { relations } = this.state;
        relations[index][name] = value 
        relations[index].error[name]=ErrorValidationOfField(value);

        this.setState({ relations })
    }
    getAlltable(data) {
        let arr = [];
        data.map(val => {
            arr = [...arr, ...val];
            return false
        })
        return arr;
    }
    AddRelationShip(relationShipContext) {
        let { current = {}, currentField = {}, onclose, drawLine, leftContext } = this.props;
        let { relations, isedit } = this.state;
        let relationcontext = relationShipContext;
        let { list, setRelation } = relationcontext;
        let allrelations = [];
        let isDontSend = false
        let  is_error = false;
        relations =  relations.map(_r=>{
            if(_r.targetTableColumn?.properties?.datatype===currentField?.properties?.datatype){
                _r.error.targetTableColumn = false;
                _r.error.targetTable = false;

                
            } else if(currentField?.properties?.datatype==='array' && _r.targetTableColumn?.properties?.datatype){
                _r.error.targetTableColumn = false;
                _r.error.targetTable = false;

            } else {
                if(!_r?.targetTable?.db_objectname){
                    _r.error.targetTable = true
                    _r.message.targetTable="Please select table";

                } else  {
                    _r.error.targetTableColumn = true;
                    _r.message.targetTableColumn="Please select same datatype";

                }
                is_error= true;
            }
            return _r;
        })
        if(is_error){
            this.setState(prevState=>({
                ...prevState,
                relations
            }));
            return
        }
        // if (relations.length > 0) {
        //     let { list, setEntity } = leftContext;
        //     isDontSend = true;
            // const relates = loopRelationsShip(relations, current, currentField)
        //     // { relations, newEntity }
        //     // allrelations = relates.relations;
        //     allrelations = relations;

        //     list["others"] = list["others"] ? list["others"] : [];
        //     // list["others"].push(relates.newEntity)
        //     setEntity({
        //         ...leftContext,
        //         list
        //     })

        // }
        relations.map(value => {
            let entity = current.entity;
            let targetEntity = value.targetTable.entity;
            let targetfield = value.targetTableColumn.name;
            let dbObjectName  = `${entity}_${targetEntity}_${list.filter(_data=>_data?.db_objectname?.toLowerCase() === `${entity}_${targetEntity}_e`).length>0 ? `${targetfield}_E`:'E'}`;
            let json = {
                attributes: {
                    currententity: current,
                    targetTable: value.targetTable,
                    targetTableColumn: value.targetTableColumn,
                    currentField,
                    isDontSend: isDontSend,
                    id: (value.attributes && value.attributes.id) ? value.attributes.id : uuid()
                },
                status: "draft",
                entity: entity,
                version: "1.0",
                target_entity: targetEntity,
                relationship: `${entity}.${currentField.name} ${value.targetTableColumn.properties.datatype==='array' ? "_IN_" : '=='} ${targetEntity}.${targetfield}`,

                // relationship: `${entity}.${currentField.name} ${value.caption ? value.caption : ""} ${targetEntity}.${targetfield}`,
                "entity_objectname": entity,
                "target_entity_objectname":  targetEntity,
                db_objectname: value.caption ?? dbObjectName,
                "sourceColumn":currentField.name,
                "sourceDatatype":currentField?.properties?.datatype,
                "targetColumn":targetfield,
                "targetDatatype":value.targetTableColumn.properties.datatype,
                "operator": value.targetTableColumn.properties.datatype==='array' ? "_IN_" : '=='
            }
            if (isedit) {
                list.map((val, i) => {
                    if (json?.attributes?.id === val?.attributes?.id) {
                        list[i] = {...val,...json};
                    }
                    return false
                });
             let  t=   list.map(vi_i=>vi_i?.attributes?.id).indexOf(json?.attributes?.id) <= -1 ? list.push(json) : ''
            } else {
                list.push(json);
            }
        })
        
        console.log("relationcontext" , relationcontext)
        console.log("list" , list)
        list = [...list]
        setRelation({
            ...relationcontext,
            list
        })

        // call draw line
        let { setCount, count } = drawLine;
        setCount({
            ...drawLine,
            count: count + 1
        })
        if (onclose) {
            
            this.setState(prevState=>({
                ...prevState,
                snackmsg: "relationship added successfully", 
                snackopen: true, 
                snacktype: "success",
            }),()=>{
                onclose()
            })
        }
    }
    addMoreField() {
        let { relations } = this.state;
        relations.push({
            id:uuid(),
            caption: null,
            targetTable: null,
            targetTableColumn: null,
            message:{
                targetTableColumn:"Please select same datatype",
                targetTable:"Please select table"
            },
            error:{
                targetTable:false,
                targetTableColumn:false
            },
        })
        this.setState({ relations })
    }
    deleteRelationship  = (_val,relationShipContext) =>{
        let {  relations }  = this.state;
        let relationcontext = relationShipContext;
        let { list, setRelation } = relationcontext;
        relations = relations.filter(_rl=>_rl.id  !== _val.id);
        this.setState({ relations });
        // if(_val?.attributes?.id){
        //     list = list.filter(_li=>_li.attributes.id !== _val.attributes.id);
        //     setRelation({
        //         ...relationcontext,
        //         list
        //     })
        // }

    }
    fieldLists = (entityName)=>{

    }
    render() {
        let { classes, current, onclose,currentField } = this.props;
        let { relations,snackopen, snackmsg, snacktype } = this.state; 
        let context = this.context;
        let list = this.getAlltable(Object.values(context.list))
        return (
            <>
            {snackopen && <Alerts severity={snacktype} open={snackopen} vertical={'top'} horizontal={'right'} msg={snackmsg} onclose={() => this.closeSnackBar()} />}
            <EntityRelationShipContext.Consumer>
                {
                    relationShipContext => <Grid className={classes.root}>
                        <Grid md={12} container className={classes.headerRoot}>
                            <Grid item md={6} className={classes.headertitleRoot} style={{ textAlign: "left" }}>
                                <Typography variant="h6" style={{fontSize: "1.06rem"}}>Add Relationship</Typography>
                            </Grid>
                            <Grid item md={6} className={classes.headerButtonRoot} >
                                <Button className={classes.headerButton + ' ' +  classes.fontFormat}  onClick={() => onclose()}>Cancel</Button>
                                <Button className={classes.headerButton  + ' ' +  classes.fontFormat} onClick={() => this.AddRelationShip(relationShipContext)} variant="contained" color="secondary">Save</Button>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item md={12} className={classes.contentRoot}>
                                <Grid item md={12} className={classes.boxPadding}>
                                    <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px"}}>Source</Typography>
                                </Grid>
                               <Grid item md={12} className={classes.boxPadding}>
                                    <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: 400}}>Entity Name</Typography>
                                  
                                    <TextField
                                        value={current.entity}
                                        disabled
                                        fullWidth={true}
                                        // onChange={e => this.setValue('caption', e.target.value, rindex)}
                                        // id="relationship-caption" 
                                        size="small"
                                        // className={classes.relationshipCaption}
                                        placeholder="from table" variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={12} className={classes.boxPadding}>
                                    <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: 400}}>Entity Field</Typography>
                                  
                                    <TextField
                                        value={currentField.name}
                                        disabled
                                        fullWidth={true}
                                        // onChange={e => this.setValue('caption', e.target.value, rindex)}
                                        // id="relationship-caption" 
                                        size="small"
                                        // className={classes.relationshipCaption}
                                        placeholder="from table" variant="outlined"
                                    />
                                </Grid>
                            <Divider />
                            {
                                relations.length>0 &&  <Grid item md={12} className={classes.boxPadding}>
                                <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px"}}>Destination</Typography>
                            </Grid>
                            }
                            {relations.map((val, rindex) => <React.Fragment>
                                {
                                    rindex > 0 && <Divider />
                                }
                                
                                {
                                        val?.caption?.length>0 ? <Grid item md={12} className={classes.boxPadding}>
                                        <TextField disabled value={val.caption} onChange={e => this.setValue('caption', e.target.value, rindex)} id="relationship-caption" size="small" className={classes.relationshipCaption} placeholder="add caption" variant="outlined" />
                                    </Grid>  : ''
                                    }
                                <Grid item md={12} className={classes.boxPadding} style={{paddingBottom: "0px"}}>
                                    {/* <select value={val.targetTable?.entity} onChange={(e) => { let index = e.nativeEvent.target.selectedIndex; let label = e.nativeEvent.target[index].text; this.setValue('targetTable', list[index - 1], rindex) }} style={{ width: "100%", height: "35px", color: "#7d7c7c", fontSize: "15px", borderRadius: "4px", background: "white" }}>
                                        <option value={0}>{'select  table'}</option>
                                        {
                                            list?.map((selectList, index) => (
                                                <option value={selectList.entity}>{selectList.entity}</option>
                                            ))
                                        }
                                    </select> */}
                                    <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: 400}}>Entity Name</Typography>

                                    <SelectBox
                                        list={
                                           list.map(_li=>{
                                               _li.label = _li.entity;
                                               _li.value = _li.entity;
                                                return _li;
                                           })
                                        }
                                        classname={classes.spaceBottom15}
                                        onchange={(e, value) => { this.setValue('targetTable', value, rindex) }}
                                        value={{label:val.targetTable?.entity,value:val.targetTable?.entity}}
                                        // top_title=''
                                        title='select table'
                                        error={val.error.targetTable}
                                        message={val.message.targetTable}

                                    />
                                </Grid>
                                {
                                     // val.targetTable && <Grid item md={12} className={classes.boxPadding}>
                                    //     <select value={val.targetTableColumn?.name} onChange={(e) => { let index = e.nativeEvent.target.selectedIndex; let label = e.nativeEvent.target[index].text; this.setValue('targetTableColumn', val.targetTable?.fields[index - 1], rindex) }} style={{ width: "100%", height: "35px", color: "#7d7c7c", fontSize: "15px", borderRadius: "4px", background: "white", }}>
                                    //         <option value={0}>{'select column'}</option>
                                    //         {
                                    //             val.targetTable?.fields?.map((selectList, index) => (
                                    //                 <option value={selectList.name}>{selectList.name}</option>
                                    //             ))
                                    //         }
                                    //     </select>
                                    // </Grid>
                                    val.targetTable && <Grid item md={12} className={classes.boxPadding} style={{paddingBottom: "0px"}}>
                                    <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: 400}}>Entity Fields</Typography>

                                         <SelectBox
                                        list={
                                            val.targetTable?.fields?.map(_li=>{
                                               _li.label = _li.name;
                                               _li.value = _li.name;
                                                return _li;
                                           }).filter(_fl=> _fl.label !==currentField.name)
                                        // this.fieldLists(val.targetTable?.entity)
                                        }
                                        classname={classes.spaceBottom15}
                                        onchange={(e, value) => { this.setValue('targetTableColumn', value, rindex) }}
                                        value={{label:val.targetTableColumn?.name,value:val.targetTableColumn?.name}}
                                        // top_title=''
                                        title='select column'
                                        error={val.error.targetTableColumn}
                                        message={val.message.targetTableColumn}

                                    />
                                    </Grid>
                                }
                            <Grid item md={12} className={classes.boxPadding} style={{textAlign:"end",paddingBottom: "0px",top:"-17px",position:"relative"}}>
                                <Button onClick={()=>this.deleteRelationship(val,relationShipContext)} variant="contained" color="secondary"  className={classes.headerButton  + ' ' +  classes.fontFormat} >Delete</Button>
                            </Grid>
                            </React.Fragment>)}
                            <Grid item md={12} className={classes.boxPadding}>
                                <Button onClick={this.addMoreField} variant="contained" color="primary" className={classes.fontFormat}>More Field</Button>
                            </Grid>
                            <Divider />

                            {/* <Grid container md={12} className={classes.headerRoot}>
                                <Grid item md={6} className={classes.headertitleRoot}>
                                </Grid>
                                
                            </Grid> */}
                        </Grid>
                    </Grid>
                }
            </EntityRelationShipContext.Consumer>
            
            </>
            
        )
    }
}

AddRelationShip.contextType = EntityLeftContext;

export default withStyles(useStyles)(withAllContexts(AddRelationShip));