/**
 * @author prakash p
 * @email prakash@qdmplatforms.com
 * @create 16/03/2021
 * @desc Exporting Enterprise WorkflowSection components from /src/components
 */

import React, { useState, useRef,useEffect } from 'react'
import { makeStyles, Button, Divider } from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import Input from './inputCom'
// import { withAllContexts } from "../../../HOCs";
// import   LeftEntity   from '../../../contexts/entity/leftContext';
import AddIcon from '@material-ui/icons/Add'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Xarrow from 'react-xarrows'
import { DataJson } from '../../../contexts/enterprise/contextdata';
import { v4 as uuidv4 } from 'uuid';
import { ReadDocumentForEnterprise,compareQPASandEnterprise,ReadDocumentForQPAS,SaveQPAS,SaveEnterPriseModuler } from '../../../functions/actionEnterprise';
import ConfirmationBox from '../../../functions/confirmationBox'
const boxStyle = {
  border: 'none',
  position: 'relative'
}
const connectPointStyle = {
  position: 'absolute',
  width: 15,
  height: 15,
  borderRadius: '50%',
  background: 'white',
  top: '20px',
  left: '268px',
  zIndex: 10000
}

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    display: 'flex'
  },
  subheader: {
    padding: '12px 0px',
    display: 'flex'
  },
  persona: {
    color: '#808080',
    fontSize: 20
  },
  title: {
    fontSize: 13,
    fontWeight: 500,
    color: '#808080',
    margin: '2px 6px'
  },
  body: {
    marginTop: '6px',
    flexWrap: 'wrap',
    display: 'flex',
    overflowX: 'auto',
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'none'
      }
    },
    padding: '10px'
  },
  addnew: {
    minWidth: 114,
    marginBottom: 6,
    marginTop: 6
  },
  newBtn: {
    marginTop: 1,
    padding: '0px 14px',
    minHeight: '44px',
    fontSize: '12px',
    borderRadius: '6px',
    textTransform: 'none',
    fontWeight: 500,
    width: 'fit-content',
    border: 'dashed',
    '& .MuiButton-startIcon': {
      marginRight: '2px'
    }
  }
}))

function WorkflowSection (props) {
  const classes = useStyles()

  // const { Data, setData } = props.datajson;
  let { Data, setData } = React.useContext(DataJson);
  //confirmation section  state
  let [ confirm,setConfirm] = useState({
    open:false,
    title:'Confirmation',
    dialog:'Are you sure delete this field ?',
    deleteIndex:null,
    deleteCategory:'',
    fieldId:''
  });
  // let { state,setState } = useState(true);
  let borderColor = Data?.borderColor
  useEffect(()=>{ 
    handleFreshData();
  },[])

  const handleFreshData = async() =>{
    //it's only fetch the data of enterprise  read documents
    let _  = await compareQPASandEnterprise();
    setData({
      Data:_
    });
  }

  const onChangeVal = (e, index, id, datauppend) => {
    Data[datauppend][index].data.value = e.target.value
    setData({
      Data
    })
  }
  const ondelete = (e, index, id, datauppend) => {
    setConfirm({
      ...confirm,
      open:true,
      deleteIndex:index,
      deleteCategory:datauppend,
      fieldId:id
    });
  }
  //field delete  success action
  const onDeleteSuccess = async()=>{
    try{
      Data[confirm.deleteCategory].splice([confirm.deleteIndex], 1);
    let saveEnterprise =  await SaveEnterPriseModuler(Data);
      let _Qpas =  await ReadDocumentForQPAS();
        if(Object.keys(_Qpas?.data?.result[_Qpas?.data?.result?.length - 1]?.QPAS?.attributes ?? {}).length>0){
          let  list = _Qpas?.data?.result[_Qpas?.data?.result?.length - 1]?.QPAS?.attributes;
          let  is_filter = false;
          //match delete field are filter of qpas
          Object.keys(list).map(_key=>{
            if(Array.isArray(list[_key]) ){
              list[_key] = list[_key]?.filter(_li=>{
                if(_li?.id){
                  if(_li?.id!==confirm.fieldId) {
                    return true;
                  }
                  else{
                    is_filter = true;
                    return false;
                  }
                } else{
                  return true;
                }
          
               });
            } else if(typeof list[_key] ==='object'){
              if(list[_key]?.Personas){
                list[_key].Personas = list[_key]?.Personas?.filter(_li=>{
                  if(_li?.id){
                    if(_li?.id!==confirm.fieldId) {
                      return true;
                    }
                    else{
                      is_filter = true;
                      return false;
                    }
                  } else{
                    return true;
                  }
            
                 });
              }  
             
            }
         
          })
          //check if the qpas data match to  the entity designer table
          if(is_filter){
            await SaveQPAS(list)
          };
        

        }
      setData({
        Data
      });
      handleCloseConfirmation();
    } catch(error){

    }
   
  
  }
  // field delete cancel action
  const handleCloseConfirmation = () =>{
    setConfirm({
      ...confirm,
      open:false
    });
  }

  const colorChange = (e, Borderindex, edit, datauppend) => {
    if (e) {
      Data[datauppend][Borderindex].data.borderColor = e
    }
    Data[datauppend][Borderindex].data.value = edit
    setData({
      Data
    })
  }

  const newStep = datauppend => {
    var level = Data[datauppend].length + 1

    // Data.borderColor.push(`#00${level}`)
    var newStepJson = {
      name: 'step',
      no: level,
      id: uuidv4(),
      data: {
        value: '',
        chips: [],
        borderColor: 'hsla(' + Math.random() * 360 + ', 100%, 50%, 1)'
      }
    }
    Data[datauppend].push(newStepJson)
    setData({
      Data
    });
  }
  // drag and drop style
  const getListStyle = isDraggingOver => ({
    border: 'none',
    outline: 'none'
  })

  // drag and drop style step
  const getItemStyle2 = (isDragging, draggableStyle) => ({
    background: isDragging ? '#f5f5f561' : '',
    border: isDragging ? '2px dashed #d4d4d4' : '',
    padding: isDragging ? 10 : '',
    // marginRight: "20px",
    // marginBottom: "20px",
    borderRadius: 8,
    outline: 'none',
    ...draggableStyle
  })
  const dndfuncy = (e, datauppend) => {
    if (e.destination) {
      let dragedvalue = Data[datauppend].splice(e.source.index, 1)[0]
      Data[datauppend].splice(e.destination.index, 0, dragedvalue)
      setData({ Data })
    }
  }
  const [arrows, setArrows] = useState([])

  // adding arrow
  const addArrow = ({ start, end, datauppend }) => {
    setArrows([...arrows, { start, end }])
    Data.arrow = [...arrows, { start, end }]
    setData({ Data })
  }
  React.useEffect(() => {
    setArrows([...Data.arrow])
  }, [])
  const ref1 = useRef()

  return (
    <div>
      <ConfirmationBox onDeleteSuccess={onDeleteSuccess} handleCloseConfirmation={handleCloseConfirmation} open={confirm.open} title={confirm.title} dialog ={confirm.dialog} />
      {arrows.map((ar, i) => (
        <Xarrow
          start={ar.start}
          end={ar.end}
          // key={ar.start + '-.' + ar.start}
          path={'smooth'}
          strokeWidth={3}
          lineColor={'#007aff'}
          color={'#007aff'}
          headColor={'#007aff'}
          style={{ position: 'relative' }}
          // passProps={{ onClick: e => MorePop(e, ar, i) }}
        />
      ))}

      <div>
        <div className={classes.header}>
          <PersonOutlineIcon className={classes.persona} />
          <span className={classes.title}> PERSONA</span>
        </div>
        <DragDropContext onDragEnd={e => dndfuncy(e, 'enterPriseList')}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={classes.body}>
                  {Data?.enterPriseList?.map((item, index) => (
                    <div
                      style={{ paddingRight: '20px', paddingBottom: '20px' }}
                    >
                      <Draggable
                        draggableId={item.id}
                        index={index}
                        key={item.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={item.id}
                            style={boxStyle}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => {
                              if (e.dataTransfer.getData('arrow') === item.id) {
                                return
                              } else {
                                const refs = {
                                  start: e.dataTransfer.getData('arrow'),
                                  end: item.id,
                                  datauppend: 'enterPriseList'
                                }
                                addArrow(refs)
                              }
                            }}
                          >
                            {!snapshot.isDragging && (
                              <div
                                ref={ref1}
                                style={{
                                  ...connectPointStyle,
                                  border: item?.data?.borderColor
                                    ? `1px solid ${item?.data?.borderColor}`
                                    : `1px solid ${borderColor?.[item.no]}`
                                }}
                                draggable
                                onDragStart={e => {
                                  e.dataTransfer.setData('arrow', item.id)
                                }}
                              />
                            )}
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle2(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Input
                                {...provided}
                                key={index}
                                steps
                                key={index}
                                id={index}
                                data={item.data}
                                borderColor={
                                  item.data.borderColor
                                    ? item.data.borderColor
                                    : borderColor[item.no]
                                }
                                Borderindex={index}
                                placeholder='Untitled Persona'
                                datauppend={'enterPriseList'}
                                ondelete={e =>
                                  ondelete(e, index, item.id, 'enterPriseList')
                                }
                                onChange={e =>
                                  onChangeVal(
                                    e,
                                    index,
                                    item.id,
                                    'enterPriseList'
                                  )
                                }
                                colorChange={colorChange}
                                value={item.data.value ? item.data.value : null}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  <div className={classes.addnew}>
                    <Button
                      variant='text'
                      color='secondary'
                      className={classes.newBtn}
                      disableElevation
                      startIcon={<AddIcon style={{ fontSize: 11 }} />}
                      onClick={() => newStep('enterPriseList')}
                      style={{ borderWidth: '1px' }}
                    >
                      New Entity
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Divider />

      <div>
        <div className={classes.subheader}>
          <PersonOutlineIcon className={classes.persona} />
          <span className={classes.title}> THINGS</span>
        </div>
        <DragDropContext onDragEnd={e => dndfuncy(e, 'things')}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={classes.body}>
                  {Data?.things?.map((item, index) => (
                    <div
                      style={{ paddingRight: '20px', paddingBottom: '20px' }}
                    >
                      <Draggable
                        draggableId={JSON.stringify(index)}
                        index={index}
                        key={JSON.stringify(index)}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={item.id}
                            style={boxStyle}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => {
                              if (e.dataTransfer.getData('arrow') === item.id) {
                                return
                              } else {
                                const refs = {
                                  start: e.dataTransfer.getData('arrow'),
                                  end: item.id,
                                  datauppend: 'things'
                                }
                                addArrow(refs)
                              }
                            }}
                          >
                            {!snapshot.isDragging && (
                              <div
                                ref={ref1}
                                style={{
                                  ...connectPointStyle,
                                  border: item.data.borderColor
                                    ? `1px solid ${item.data.borderColor}`
                                    : `1px solid ${borderColor[item.no]}`
                                }}
                                draggable
                                onDragStart={e => {
                                  e.dataTransfer.setData('arrow', item.id)
                                }}
                              />
                            )}
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle2(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Input
                                steps
                                key={index}
                                id={index}
                                data={item.data}
                                borderColor={
                                  item.data.borderColor
                                    ? item.data.borderColor
                                    : borderColor[item.no]
                                }
                                Borderindex={index}
                                placeholder='Untitled Things'
                                datauppend={'things'}
                                ondelete={e =>
                                  ondelete(e, index, item.id, 'things')
                                }
                                onChange={e =>
                                  onChangeVal(e, index, item.id, 'things')
                                }
                                colorChange={colorChange}
                                value={item.data.value ? item.data.value : null}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  <div className={classes.addnew}>
                    <Button
                      variant='text'
                      color='secondary'
                      className={classes.newBtn}
                      disableElevation
                      startIcon={<AddIcon style={{ fontSize: 11 }} />}
                      onClick={() => newStep('things')}
                      style={{ borderWidth: '1px' }}
                    >
                      New Entity
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Divider />
      <div>
        <div className={classes.subheader}>
          <PersonOutlineIcon className={classes.persona} />
          <span className={classes.title}>ECO SYSTEM</span>
        </div>
        <DragDropContext onDragEnd={e => dndfuncy(e, 'eco')}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={classes.body}>
                  {Data?.eco?.map((item, index) => (
                    <div
                      style={{ paddingRight: '20px', paddingBottom: '20px' }}
                    >
                      <Draggable
                        draggableId={JSON.stringify(index)}
                        index={index}
                        key={JSON.stringify(index)}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={item.id}
                            style={boxStyle}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => {
                              if (e.dataTransfer.getData('arrow') === item.id) {
                                return
                              } else {
                                const refs = {
                                  start: e.dataTransfer.getData('arrow'),
                                  end: item.id,
                                  datauppend: 'eco'
                                }
                                addArrow(refs)
                              }
                            }}
                          >
                            {!snapshot.isDragging && (
                              <div
                                ref={ref1}
                                style={{
                                  ...connectPointStyle,
                                  border: item.data.borderColor
                                    ? `1px solid ${item.data.borderColor}`
                                    : `1px solid ${borderColor[item.no]}`
                                }}
                                draggable
                                onDragStart={e => {
                                  e.dataTransfer.setData('arrow', item.id)
                                }}
                              />
                            )}
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle2(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Input
                                steps
                                key={index}
                                id={index}
                                data={item.data}
                                borderColor={
                                  item.data.borderColor
                                    ? item.data.borderColor
                                    : borderColor[item.no]
                                }
                                Borderindex={index}
                                placeholder='Untitled Eco Stystem'
                                datauppend={'eco'}
                                ondelete={e =>
                                  ondelete(e, index, item.id, 'eco')
                                }
                                onChange={e =>
                                  onChangeVal(e, index, item.id, 'eco')
                                }
                                colorChange={colorChange}
                                value={item.data.value ? item.data.value : null}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  <div className={classes.addnew}>
                    <Button
                      variant='text'
                      color='secondary'
                      className={classes.newBtn}
                      disableElevation
                      startIcon={<AddIcon style={{ fontSize: 11 }} />}
                      onClick={() => newStep('eco')}
                      style={{ borderWidth: '1px' }}
                    >
                      New Entity
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Divider />
      <div>
        <div className={classes.subheader}>
          <PersonOutlineIcon className={classes.persona} />
          <span className={classes.title}>PRODUCT & SERVICE</span>
        </div>
        <DragDropContext onDragEnd={e => dndfuncy(e, 'product')}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={classes.body}>
                  {Data?.product?.map((item, index) => (
                    <div
                      style={{ paddingRight: '20px', paddingBottom: '20px' }}
                    >
                      <Draggable
                        draggableId={JSON.stringify(index)}
                        index={index}
                        key={JSON.stringify(index)}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={item.id}
                            style={boxStyle}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => {
                              if (e.dataTransfer.getData('arrow') === item.id) {
                                return
                              } else {
                                const refs = {
                                  start: e.dataTransfer.getData('arrow'),
                                  end: item.id,
                                  datauppend: 'product'
                                }
                                addArrow(refs)
                              }
                            }}
                          >
                            {!snapshot.isDragging && (
                              <div
                                ref={ref1}
                                style={{
                                  ...connectPointStyle,
                                  border: item.data.borderColor
                                    ? `1px solid ${item.data.borderColor}`
                                    : `1px solid ${borderColor[item.no]}`
                                }}
                                draggable
                                onDragStart={e => {
                                  e.dataTransfer.setData('arrow', item.id)
                                }}
                              />
                            )}
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle2(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Input
                                steps
                                key={index}
                                id={index}
                                data={item.data}
                                borderColor={
                                  item.data.borderColor
                                    ? item.data.borderColor
                                    : borderColor[item.no]
                                }
                                Borderindex={index}
                                placeholder='Untitled Product'
                                datauppend={'product'}
                                ondelete={e =>
                                  ondelete(e, index, item.id, 'product')
                                }
                                onChange={e =>
                                  onChangeVal(e, index, item.id, 'product')
                                }
                                colorChange={colorChange}
                                value={item.data.value ? item.data.value : null}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  <div className={classes.addnew}>
                    <Button
                      variant='text'
                      color='secondary'
                      className={classes.newBtn}
                      disableElevation
                      startIcon={<AddIcon style={{ fontSize: 11 }} />}
                      onClick={() => newStep('product')}
                      style={{ borderWidth: '1px' }}
                    >
                      New Entity
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Divider />
      <div>
        <div className={classes.subheader}>
          <PersonOutlineIcon className={classes.persona} />
          <span className={classes.title}>OTHERS</span>
        </div>
        <DragDropContext onDragEnd={e => dndfuncy(e, 'others')}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className={classes.body}>
                  {Data?.others?.map((item, index) => (
                    <div
                      style={{ paddingRight: '20px', paddingBottom: '20px' }}
                    >
                      <Draggable
                        draggableId={JSON.stringify(index)}
                        index={index}
                        key={JSON.stringify(index)}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={item.id}
                            style={boxStyle}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => {
                              if (e.dataTransfer.getData('arrow') === item.id) {
                                return
                              } else {
                                const refs = {
                                  start: e.dataTransfer.getData('arrow'),
                                  end: item.id,
                                  datauppend: 'others'
                                }
                                addArrow(refs)
                              }
                            }}
                          >
                            {!snapshot.isDragging && (
                              <div
                                ref={ref1}
                                style={{
                                  ...connectPointStyle,
                                  border: item.data.borderColor
                                    ? `1px solid ${item.data.borderColor}`
                                    : `1px solid ${borderColor[item.no]}`
                                }}
                                draggable
                                onDragStart={e => {
                                  e.dataTransfer.setData('arrow', item.id)
                                }}
                              />
                            )}
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle2(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Input
                                steps
                                key={index}
                                id={index}
                                data={item.data}
                                borderColor={
                                  item.data.borderColor
                                    ? item.data.borderColor
                                    : borderColor[item.no]
                                }
                                Borderindex={index}
                                placeholder='Untitled others'
                                datauppend={'others'}
                                ondelete={e =>
                                  ondelete(e, index, item.id, 'others')
                                }
                                onChange={e =>
                                  onChangeVal(e, index, item.id, 'others')
                                }
                                colorChange={colorChange}
                                value={item.data.value ? item.data.value : null}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                  <div className={classes.addnew}>
                    <Button
                      variant='text'
                      color='secondary'
                      className={classes.newBtn}
                      disableElevation
                      startIcon={<AddIcon style={{ fontSize: 11 }} />}
                      onClick={() => newStep('others')}
                      style={{ borderWidth: '1px' }}
                    >
                      New Entity
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}
export default WorkflowSection
