/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from 'react';
import { EntityFieldList } from './'
function InsideFolder(props) {
    return <React.Fragment>
        <EntityFieldList {...props} insideVlaue={true}/>
    </React.Fragment>
}

export default InsideFolder;