import React,{useState,useEffect} from 'react';
import {
    Button,  Divider, Grid,  TextField, Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "395px"
    },
    headerRoot: {
        padding: "9px 7px"
    },
    headertitleRoot: {
        display: "flex",
        margin: "auto"
    },
    headertitle: {
        fontWeight: 600
    },
    headerButtonRoot: {
        textAlign: "right"
    },
    headerButton: {
        height: "26px",
        marginLeft: "10px"
    },
    contentRoot: {
        paddingTop: 0,
        borderTop: "1px solid #f3f3f3"
    },
    currentTableName: {
        fontWeight: 600,
        fontSize: "14px",
        marginRight: "8px",
        marginLeft: "20px"
    },
    relationshipCaption: {
        width: "100%",
        marginRight: "10px"
    },
    selectTableField: {
        marginLeft: "20px"
    },
    selectTable: {
        width: "166px",
        display: "inline-block",
        '& .MuiFormControl-marginNormal': {
            marginTop: 0
        }
    },
    boxPadding: {
        padding: "10px"
    },
    deleteFont:{
        color: "white",
        background: "#f50057",
        padding: "5px",
        textTransform: "capitalize",
        fontSize: "12px",
        '&:hover': {
            background: "#f50057",
        }
    },
    fontFormat:{
        textTransform: "capitalize"
    },
    deleteBtn:{
        cursor: "pointer",
        background: "#f24a58",
        borderRadius: "7px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "26px",
        height: "31px",
        color: "white"
    },
    deleteBtnParent:{
        display: "flex",
        justifyContent: "flex-end"

    }
}));

function ValueDrawer(props) {
    const { classes } = props;
    const  [  valueData,setValueData ] = useState([]);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    useEffect(()=>{
        if(props.lovValueData){
            // let constructLovKey = props?.lovKeyData?.map((_lovkey=>{
            //     return {
            //         keyname:_lovkey,
            //         error:false
            //     };
            // }));
            setValueData(props.lovValueData);
        }
    },[]);
 
    const handleClose = () => {
        props.handleCloseValue();
        
    };
    const onSuccess = async() => {
        debugger;
        if(valueData?.length<=0){
            alert("please add value");
            return;
        }
        let _mapkey =  valueData.map((_d,_index)=>{
            for(let i=0;i<props?.lovKeyData?.length;i++){
                if(_d[props?.lovKeyData[i]] && _d[props?.lovKeyData[i]]?.length>0){
                    _d.error[props?.lovKeyData[i]] = false;
                } else {
                    _d.error[props?.lovKeyData[i]] = true;
                }
            }
            return _d
        });
        let  iserror  = false;
       for(let j=0;j<_mapkey.length;j++){
         iserror  = Object.values(_mapkey[j].error).some(_c=>_c===true);
         if(iserror){
             break;
         }

       }
        if(iserror){
          setValueData(_mapkey);
            return ;
        };
        // let splitKey = _mapkey.map(_=>{
        //     return  _.keyname;
        // });
        props.onValueSave(_mapkey);
    };
   const addKey = ()=>{
       let _keyData = valueData;
       let _push_struct = {};
       let error = {};
       let sample_data = props?.lovKeyData?.map(_=>{
           _push_struct[_]  =  "";
           error[_] = false;
           return _;
       });
       _push_struct.error = error;
       _keyData.push(_push_struct)
        setValueData(_keyData);
        forceUpdate();

    };
   const deleteKey = (index)=>{
        let _filterkey =  valueData.filter((_d,_index)=>_index !== index);
        setValueData(_filterkey);
    };
   const editKey = (value,index,name) =>{
        let _mapkey =  valueData.map((_d,_index)=>{
            if(_index === index && value && value?.length>0){
                _d[name] = value;
                _d.error[name] = false;
            } else if(_index === index){
                _d[name] = value;
                _d.error[name] = true;
            }

            return _d
        });
        setValueData(_mapkey);
    }

    return (
        <>
            {/* {snackopen && <Alerts severity={snacktype} open={snackopen} vertical={'top'} horizontal={'right'} msg={snackmsg} onclose={() => this.closeSnackBar()} />} */}
                
            <Grid className={classes.root} >
            <Grid md={12} container className={classes.headerRoot}>
                <Grid item md={6} className={classes.headertitleRoot} style={{ textAlign: "left" }}>
                    <Typography variant="h6" style={{fontSize: "1.06rem"}}>LOV Values</Typography>
                </Grid>
                <Grid item md={6} className={classes.headerButtonRoot} >
                    <Button className={classes.headerButton + ' ' +  classes.fontFormat}  onClick={() => handleClose()}>Cancel</Button>
                    <Button className={classes.headerButton  + ' ' +  classes.fontFormat} onClick={() => onSuccess()} variant="contained" color="secondary">Save</Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid item md={12} className={classes.contentRoot}>
                    <Grid item md={12} className={classes.boxPadding}>
                            <Button className={classes.headerButton  + ' ' +  classes.fontFormat} color='primary' onClick={() => addKey()} variant="contained" color="secondary">Add</Button>

                    </Grid>
                    {
                        valueData?.map((_keyItem,_index)=>(
                            <Grid item md={12} className={classes.boxPadding}>
                                <Grid container style={{marginBottom:5}}>
                                    <Grid item md={6}>
                                        <Typography variant="caption" className={classes.currentTableName} style={{margin: "0px",fontWeight: "bold"}}>LOV {_index+1}</Typography>

                                    </Grid>
                                    {
                                        _index !==0 ?               <Grid item md={6} className={classes.deleteBtnParent}>
                                        <div className={classes.deleteBtn}>
                                            <DeleteOutlinedIcon onClick={()=>deleteKey(_index)}/> 
                                        </div>
                                       {/* <Button className={classes.headerButton  + ' ' +  classes.fontFormat} color='primary' onClick={() => deleteKey(_index)} variant="contained" color="secondary">Delete</Button> */}

                                    </Grid> : ''
                                    }
                      
                                </Grid>
                                {
                                    props?.lovKeyData?.map((_keydata,__index)=>(
                                        <Grid container style={{marginBottom:5}}>
                                            <Grid item md={3}>
                                                <Typography>{_keydata}</Typography>
                                            </Grid>
                                            <Grid item md={1}>
                                               <Typography>:</Typography>
                                            </Grid>
                                            <Grid item md={8}>
                                                <TextField
                                                    value={_keyItem[_keydata]}
                                                    fullWidth={true}
                                                    onChange={e => editKey( e.target.value, _index,_keydata)}
                                                    // id="relationship-caption" 
                                                    error={_keyItem.error[_keydata] ? true : false}
                                                    helperText={_keyItem.error[_keydata]? "Please enter the key value" : ''}
                                                    size="small"
                                                    className={classes.relationshipCaption}
                                                    // placeholder={"value  "+(_index+1)} 
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                                
                            </Grid>
                        ))
                    }
                    
            </Grid>
        </Grid>



        </>
    );
}
export default withStyles(useStyles)(ValueDrawer);