/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Grid, Typography, Divider, TextField, Checkbox, FormControlLabel, Button , Fab} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './style';
import { SelectBox } from '../..';
import moment from 'moment';
import { parse } from 'flatted';
import AddIcon from '@material-ui/icons/Add';

var monthInfo = [
    {
        label:'January',
        value:'01'
    },
    {
        label:'Febrary',
        value:'02'
    },
    {
        label:'March',
        value:'03'
    },
    {
        label:'April',
        value:'04'
    },
    {
        label:'May',
        value:'05'
    },
    {
        label:'June',
        value:'06'
    },
    {
        label:'July',
        value:'07'
    },
    {
        label:'Augest',
        value:'08'
    },
    {
        label:'Septemper',
        value:'09'
    },
    {
        label:'October',
        value:'10'
    },
    {
        label:'November',
        value:'11'
    },
    {
        label:'Decemper',
        value:'12'
    },
]
class SequenceDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            issequence: false,
            prefix: null,
            suffix: null,
            minDate:new Date()
        }
    }
    componentDidMount() {
        let { editproperties } = this.props;
        // let Sequence = editproperties?.Sequence ?? {};
        let state = this.state;
        state.issequence = editproperties?.properties?.Sequence?.isSequence;
        state.prefix  = editproperties?.properties?.Sequence?.prefix;
        state.suffix  = editproperties?.properties?.Sequence?.suffix;

        this.setState({
            ...state
        })
        this.getMinDate()
    }

    getMinDate = () => {

        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
        
        var maxDate = year + '-' + month + '-' + day;

        this.setState({
            minDate:maxDate
        })


    }
    disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    // shouldComponentUpdate(nextprops){
    //     if(nextprops !== this.props){
    //         let { editproperties } = this.props;
    //         // let Sequence = editproperties?.Sequence ?? {};
    //         let state = this.state;
    //         state.issequence = editproperties?.properties?.Sequence?.isSequence;
    //         state.prefix  = editproperties?.properties?.Sequence?.prefix;
    //         state.suffix  = editproperties?.properties?.Sequence?.suffix;
    //         this.setState({
    //             ...state
    //         })
    //     }
    //     return nextprops !== this.props
        
    // }
    setValue(name, value) {
         

        this.setState({
            [name]: value
        })
         
        this.props.setValueJSON("Sequence", {
            "suffix": this.state.suffix,
            "prefix": this.state.prefix, 
            [name]: value
        })
         
    }

   
    render() {

        
         
        let { classes,editproperties,fieldProperties } = this.props;

        console.log("editproperties" , editproperties?.properties?.Sequence?.reset_config?.resetType)
        
        let { issequence, prefix, suffix } = this.state;
        return (
            <Grid container className={classes.root}>
                <Grid md={12}>
                    <Typography >SEQUENCE</Typography>
                    <FormControlLabel control={<Checkbox checked={editproperties?.properties?.Sequence?.isSequence ?? false} onChange={(e,value) => this.props.setDataTypeProperties("isSequence", value,'sequence' ,editproperties?.properties?.datatype)} name="checkedB" color="primary" />} label="Sequence" />
                   {
                       editproperties?.properties?.Sequence?.isSequence ? <>
                        <Grid container className={classes.spaceBottom15} md={12} >
                        {
                            fieldProperties?.datatype?.toLowerCase() !=='number'? 
                            <>
                             <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                            <Typography variant="caption">Prefix</Typography>
                            <Grid>
                            <TextField
                                inputProps={{ style: { height: "14px" } }}
                                id="min-length"
                                value={editproperties?.properties?.Sequence?.prefix}
                                onChange={(e) => this.props.setDataTypeProperties("prefix", e.target.value,'sequence' ,editproperties?.properties?.datatype)}
                                variant="outlined"
                                size="small"
                                disabled={editproperties?.properties?.Sequence?.prefix?.indexOf('Rules/')!==-1 ? true: false}
                            />
                              <img style={{margin: "6px"}} onClick={()=>this.props.handleSequenceOpen('prefix',editproperties?.properties?.Sequence?.prefix)} src={require('../../../../assets/icons8-table-properties.svg')} />
                            </Grid>
                           
                        </Grid>
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                            <Typography variant="caption">Suffix</Typography>
                            <Grid>
                            <TextField
                                inputProps={{ style: { height: "14px" } }}
                                id="min-length"
                                value={editproperties?.properties?.Sequence?.suffix}
                                onChange={(e) => this.props.setDataTypeProperties("suffix", e.target.value,'sequence' , editproperties?.properties?.datatype)}
                                variant="outlined"
                                size="small"
                                disabled={editproperties?.properties?.Sequence?.suffix?.indexOf('Rules/')!==-1 ? true: false}

                            />
                            <img style={{margin: "6px"}} onClick={()=>this.props.handleSequenceOpen('suffix',editproperties?.properties?.Sequence?.suffix)} src={require('../../../../assets/icons8-table-properties.svg')} />

                            </Grid>
                          
                        </Grid>
                            </> 
                            :''
                        }
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                            <Typography variant="caption">Start Value</Typography>
                            <Grid>
                            <TextField
                                inputProps={{ style: { height: "14px" } }}
                                id="start-value"
                                value={editproperties?.properties?.Sequence?.StartValue}
                                onChange={(e) => this.props.setDataTypeProperties("StartValue",e.target.value,'sequence' ,editproperties?.properties?.datatype)}
                                variant="outlined"
                                size="small"
                                disabled={(typeof editproperties?.properties?.Sequence?.StartValue ==='string' && editproperties?.properties?.Sequence?.StartValue?.indexOf('Rules/')!==-1) ? true: false}

                                // type="number"
                                // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                // onInput={
                                //     (e)=>{
                                //         e.target.value = Math.max(0, parseInt(e.target.value));
                                //     }
                                // }
                                // onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}

                            />
                            <img style={{margin: "6px"}} onClick={()=>this.props.handleSequenceOpen('StartValue',editproperties?.properties?.Sequence?.StartValue)} src={require('../../../../assets/icons8-table-properties.svg')} />

                            </Grid>
                            
                        </Grid>
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                            <Typography variant="caption">Sequence Id</Typography>
                            <Grid>
                            <TextField
                                inputProps={{ style: { height: "14px" } }}
                                id="sequence-id"
                                value={editproperties?.properties?.Sequence?.SequenceId}
                                onChange={(e) => this.props.setDataTypeProperties("SequenceId", e.target.value,'sequence' ,editproperties?.properties?.datatype)}
                                variant="outlined"
                                size="small"
                                disabled={editproperties?.properties?.Sequence?.SequenceId?.indexOf('Rules/')!==-1 ? true: false}

                            />
                             <img style={{margin: "6px"}} onClick={()=>this.props.handleSequenceOpen('SequenceId',editproperties?.properties?.Sequence?.SequenceId)} src={require('../../../../assets/icons8-table-properties.svg')} />

                            </Grid>
                          
                        </Grid>
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                            <Typography variant="caption">Increment By</Typography>
                            <Grid>
                            <TextField
                                inputProps={{ style: { height: "14px" } }}
                                id="increment-by"
                                value={editproperties?.properties?.Sequence?.IncrementBy}
                                onChange={(e) => this.props.setDataTypeProperties("IncrementBy", e.target.value,'sequence' , editproperties?.properties?.datatype)}
                                variant="outlined"
                                size="small"
                                disabled={typeof editproperties?.properties?.Sequence?.IncrementBy === 'string' && editproperties?.properties?.Sequence?.IncrementBy?.indexOf('Rules/')!==-1 ? true: false}

                                // type="number"
                                // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                // onInput={
                                //     (e)=>{
                                //         e.target.value = Math.max(0, parseInt(e.target.value));
                                //     }
                                // }
                                // onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}

                            />
                             <img style={{margin: "6px"}} onClick={()=>this.props.handleSequenceOpen('IncrementBy',editproperties?.properties?.Sequence?.IncrementBy)} src={require('../../../../assets/icons8-table-properties.svg')} />

                            </Grid>
                            
                        </Grid>
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                            <Typography variant="caption">Max Value</Typography>
                            <Grid>
                                {["daywise" , "monthwise" , "custom"].includes(editproperties?.properties?.Sequence?.reset_config?.resetType) ?
                                 <TextField
                                 inputProps={{ style: { height: "14px" } }}
                                 id="max-value"
                                 disabled={true}
                                 value={editproperties?.properties?.Sequence?.MaxValue}
                                 onChange={(e) => this.props.setDataTypeProperties("MaxValue", e.target.value,'sequence' , editproperties?.properties?.datatype)}
                                 variant="outlined"
                                 size="small"
                                
                                  
 
                                 // type="number"
                                 // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                 // onInput={
                                 //     (e)=>{
                                 //         e.target.value = Math.max(0, parseInt(e.target.value));
                                 //     }
                                 // }
                                 // onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}
 
                             />
                             :
                             <>
                            
                             <Grid>
                                 <TextField
                                 inputProps={{ style: { height: "14px" } }}
                                 id="max-value"
                                 disabled={ typeof editproperties?.properties?.Sequence?.MaxValue === 'string' &&  editproperties?.properties?.Sequence?.MaxValue?.indexOf('Rules/')!==-1 ? true: false}
                                 value={editproperties?.properties?.Sequence?.MaxValue}
                                 onChange={(e) => this.props.setDataTypeProperties("MaxValue", e.target.value,'sequence' , editproperties?.properties?.datatype)}
                                 variant="outlined"
                                 size="small"
                                 
 
                             />
                             </Grid>
                             </>

                                }
                             
                             
                            <img style={{margin: "6px"}} onClick={()=>this.props.handleSequenceOpen('MaxValue',editproperties?.properties?.Sequence?.MaxValue)} src={require('../../../../assets/icons8-table-properties.svg')} />

                            </Grid>
                            
                        </Grid>
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                        {/* <Typography >Is Reset</Typography> */}
                    <FormControlLabel control={<Checkbox checked={editproperties?.properties?.Sequence?.IsReset ?? false} onChange={(e,value) => this.props.setDataTypeProperties("IsReset", value,'sequence')} name="checkedIS" color="primary" />} label="Is Reset" />
                        </Grid>
                        {
                            editproperties?.properties?.Sequence?.IsReset &&
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                              <SelectBox
                                list={[
                                   {
                                       label:'daywise',
                                       value:'daywise'
                                   },
                                   {
                                    label:'monthwise',
                                    value:'monthwise'
                                   },
                                   {
                                    label:'custom',
                                    value:'custom'
                                   }
                                ]}
                                classname={classes.selectBoxOverideStyle}
                                onchange={(e, value) => { this.props.setDataTypeProperties("reset_config",{resetType:value?.value ?? '',resetvalue:''}, 'sequence') }}
                                value={{label:editproperties?.properties?.Sequence?.reset_config?.resetType,value:editproperties?.properties?.Sequence?.reset_config?.resetType}}
                                top_title='Reset Type'
                                title='Reset Type'
                            />
                            
                            {

                                editproperties?.properties?.Sequence?.reset_config?.resetType==='custom' &&
                                <>
                                <TextField
                                             inputProps={{ style: { height: "14px" } }}
                                             id="min-length34"
                                             value={editproperties?.properties?.Sequence?.reset_config?.resetvalue || ''}
                                             //onChange={(e) => { this.props.setDataTypeProperties("defaultValue",e.target.value, 'property') }}
                                             fullWidth={true}
                                             disabled
                                             variant="outlined"
                                             size="small"
                                         />
                                         <Grid style={{position:"relative"}} 
                                                 
                                                 >
                                         <Button   
                                         id="closeBtn"
                                         onClick={()=>{
                                            // alert('closeBtn');
                                            this.props.setDataTypeProperties("reset_config",{resetType:editproperties?.properties?.Sequence?.reset_config?.resetType ?? '',resetvalue:''},'sequence') }}
                                         style= {{
                                                     height: "20px",
                                                     width: `${ '47px'}`,
 
                                                    //  position: "absolute",
                                                     right: "11px",
                                                    //  top: "-27px"
                                                 }}
                                                 >clear</Button>
                                         </Grid>
                                {/* <span>{editproperties?.properties?.Sequence?.reset_config?.resetvalue}</span> */}
                                {console.log("new date" , `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`)}
                                 <TextField
                                             inputProps={{
                                                 style: {
                                                     height: "20px",
                                                     width: `${ '20px'}`,
 
                                                     position: "absolute",
                                                     right: "-266px",
                                                     top: "-48px"
                                                 },
                                                 min: `${this.state.minDate}`
                                                //  max: `${new Date().getFullYear()}-12-31`
                                             }}
                                             id="min-length"
                                             defaultValue={editproperties?.properties?.Sequence?.reset_config?.resetvalue ? editproperties?.properties?.Sequence?.reset_config?.resetvalue+'/'+new Date().getFullYear() : null}
                                             onChange={(e) => {  this.props.setDataTypeProperties("reset_config",{resetType:editproperties?.properties?.Sequence?.reset_config?.resetType ?? '',resetvalue:moment(e.target.value).format('DD/MM')},'sequence')  }}
                                             // onChange={(e) => { this.props.setDataTypeProperties("defaultValue",e.target.value, 'property') }}
 

 
                                             size="small"
                                             type={ 'date'}
                                             className={classes.textField}
                                             InputLabelProps={{
                                                 shrink: true,
                                             }}
                                             InputProps={(e) => {
 
                                             }}
                                            //  min={"2022-01-31"}
                                            //  max={"31/12/2022"}
                                             format={'DD/MM/YYYY'}
                                         />
                                </>
                               
                            }
                            {/* {
                                editproperties?.properties?.Sequence?.reset_config?.resetType==='monthwise' &&
                             <SelectBox
                                list={
                                    monthInfo
                                }
                                classname={classes.selectBoxOverideStyle}
                                onchange={(e) => {  this.props.setDataTypeProperties("reset_config",{resetType:editproperties?.properties?.Sequence?.reset_config?.resetType ?? '',resetvalue:`${new Date(new Date().getFullYear(),parseInt(e.target.value),0).getDate().toString().length==1 ? 0+new Date(new Date().getFullYear(),parseInt(e.target.value),0).getDate().toString() : new Date(new Date().getFullYear(),parseInt(e.target.value),0).getDate().toString()}/${e.target.value}/${new Date().getFullYear()}`},'sequence')  }}

                                value={editproperties?.properties?.Sequence?.reset_config?.resetvalue? monthInfo.filter(_m=>_m.value== editproperties?.properties?.Sequence?.reset_config?.resetvalue.split('/')[1])[0]:{}}
                                top_title='Reset Value'
                                title='Reset Value'
                            />
                            } */}
                        </Grid>
                        }
                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                        <Button
                      variant='text'
                      color='secondary'
                      className={classes.newBtn}
                      disableElevation
                      startIcon={<AddIcon style={{ fontSize: 11 }} />}
                      onClick={() => this.props.handleGRoupBYOpen('groupBy' , editproperties?.properties?.Sequence?.groupBy)}
                      style={{ borderWidth: '1px' }}
                    >
                      Add Group By
                    </Button>
                      {/* <Grid>
                         <TextField
                                            //  inputProps={{ style: { height: "14px" } }}
                                             id="groupBy"
                                             value={editproperties?.properties?.Sequence?.groupBy || ''}
                                             onChange={(e) => { this.props.setDataTypeProperties("groupBy",e.target.value, 'sequence') }}
                                       
                                             multiline
                                             rows={5}
                                             rowsMax={10}
                                             variant="outlined"
                                             
                                         />
                                         </Grid> */}
                                         </Grid>
                    </Grid>
                       </>:''
                   }
                   
                  
                </Grid>

                <Divider className={classes.dividerLine} />
            </Grid>
        )
    }
}

export default withStyles(useStyles)(SequenceDetails);