/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Button, Divider, Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { EntityLeftContext, EntityGroupRelationShipContext  } from '../../../contexts';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withAllContexts } from '../../../HOCs';
import { loopRelationsShip } from '../../../functions';
import { uuid } from 'uuidv4';
import { SelectBox } from '../..';
import { ErrorValidationOfField } from '../../../utils';
import { Alerts } from '../../../components';
import environment from '../../../config';
import axios from 'axios';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "395px"
    },
    headerRoot: {
        padding: "9px 7px"
    },
    headertitleRoot: {
        display: "flex",
        margin: "auto"
    },
    headertitle: {
        fontWeight: 600
    },
    headerButtonRoot: {
        textAlign: "right"
    },
    headerButton: {
        height: "26px",
        marginLeft: "10px"
    },
    contentRoot: {
        paddingTop: 0,
        borderTop: "1px solid #f3f3f3"
    },
    currentTableName: {
        fontWeight: 600,
        fontSize: "14px",
        marginRight: "8px",
        marginLeft: "20px"
    },
    relationshipCaption: {
        width: "100%",
        marginRight: "10px"
    },
    selectTableField: {
        marginLeft: "20px"
    },
    selectTable: {
        width: "166px",
        display: "inline-block",
        '& .MuiFormControl-marginNormal': {
            marginTop: 0
        }
    },
    boxPadding: {
        padding: "10px"
    },
    deleteFont: {
        color: "white",
        background: "#f50057",
        padding: "5px",
        textTransform: "capitalize",
        fontSize: "12px",
        '&:hover': {
            background: "#f50057",
        }
    },
    fontFormat: {
        textTransform: "capitalize"
    }
}));


class AddGroupRelationShip extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            relations: [
                {
                    id: uuid(),
                    caption: null,
                    groupId: null,
                    groupName: null,
                    FromCollection: null,
                    FromColumnName: null,
                    ToCollection: null,
                    ToColumnName: null,
                    SeqNo: null,
                    Align: "Right",
                    error: {
                        FromCollection: false,
                        FromColumnName: false,
                        ToCollection: false,
                        ToColumnName: false
                    },
                    message: {
                        FromCollection: "Please select same datatype",
                        FromColumnName: "Please select Table",
                        ToCollection: "Please select same datatype",
                        ToColumnName: "Please select Table"
                    }
                }
            ],
            groupIdsRemove:[],
            isedit: false,
            snackopen: false,
            snackmsg: '',
            snacktype: 'success'
        }
        this.addMoreField = this.addMoreField.bind(this);
    }

    componentDidMount() {

        let { grouprelationshipcontext, currentField = {} } = this.props;
        let entityLists = this.getAlltable(Object.values(this.context.list));
         
        let data = grouprelationshipcontext.list.filter(val => currentField?.entity === val?.entity && val?.entitycode === currentField?.entitycode && val?.active !== "N");
        if (data.length > 0) {
            let list = data.map(relation => {
                let FromENtityField =  entityLists.filter(val => val.entity === relation.FromCollection)[0];  
                let FromENtityFieldsss = FromENtityField.fields.filter(_id => _id.name === relation.FromColumnName)[0];
                let ToENtityField =  entityLists.filter(val => val.entity === relation.ToCollection)[0];  
                let ToENtityFieldsss = ToENtityField.fields.filter(_id => _id.name === relation.ToColumnName)[0];
                 
                return {
                    id: relation?.id ?? uuid(),
                    relation: relation.db_objectname,
                    groupId: relation.groupId,
                    groupName: relation.groupName,
                    FromCollection: FromENtityField,
                    FromColumnName: FromENtityFieldsss,
                    ToCollection: ToENtityField,
                    ToColumnName: ToENtityFieldsss,
                    SeqNo: relation.SeqNo,
                    Align: "Right",
                    error: {
                        FromCollection: false,
                        FromColumnName: false,
                        ToCollection: false,
                        ToColumnName: false
                    },
                    message: {
                        FromCollection: "Please select same datatype",
                        FromColumnName: "Please select Table",
                        ToCollection: "Please select same datatype",
                        ToColumnName: "Please select Table"
                    }
                }
                 
            })

            this.setState({
                relations: list,
                isedit: true
            })
        }
        else {
            let lastValue = grouprelationshipcontext.list.filter(val => currentField?.entity === val?.entity && val?.entitycode === currentField?.entitycode && val?.active !== "N");
            let SeqNumber = 1;
            if (lastValue.length > 0) {

                if (lastValue[lastValue.length - 1].SeqNo !== undefined) {
                    SeqNumber = parseInt(lastValue[lastValue.length - 1].SeqNo) + 1;
                }
                else {
                    SeqNumber = 1;
                }
            }
            let NewRelation = [
                {
                    id: uuid(),
                    caption: null,
                    groupId: null,
                    groupName: null,
                    FromCollection: null,
                    FromColumnName: null,
                    ToCollection: null,
                    ToColumnName: null,
                    SeqNo: SeqNumber,
                    Align: "Right",
                    error: {
                        FromCollection: false,
                        FromColumnName: false,
                        ToCollection: false,
                        ToColumnName: false
                    },
                    message: {
                        FromCollection: "Please select same datatype",
                        FromColumnName: "Please select Table",
                        ToCollection: "Please select same datatype",
                        ToColumnName: "Please select Table"
                    }
                }
            ]

            this.setState({
                relations: NewRelation,
            })
        }
    }
    setValue = (name, value, index) => {

        let { relations } = this.state;
        relations[index][name] = value
        relations[index].error[name] = ErrorValidationOfField(value);
        this.setState({ relations })
    }

    getAlltable(data) {
        
        let arr = [];
        data.map(val => {
            arr = [...arr, ...val];
            return false
        })
        return arr;
    }

    AddGroupRelationShip(grouprelationShipContext) {

        

        let { current = {}, currentField = {}, onclose, drawLine, leftContext  } = this.props;

        let { relations, isedit } = this.state;

        let relationcontext = grouprelationShipContext;

        let { list, setgroupRelation , deletelistsss} = relationcontext;
        let allrelations = [];

        let isDontSend = false

        let is_error = false;

        

        list = list.filter(li => li.entity !== current.entity);
        
        relations = relations.map((_r) => {

            // if (_r.From ColumnName?.properties?.datatype === _r.ToColumnName?.properties?.datatype) {
            //     _r.error.FromColumnName = false;
            //     _r.error.FromCollection = false;


            // } else if (currentField?.properties?.datatype === 'array' && _r.ToColumnName?.properties?.datatype) {
            //     _r.error.FromColumnName = false;
            //     _r.error.FromCollection = false;

            // } else {

            if (!_r?.FromCollection?.db_objectname || !_r?.FromCollection) {

                _r.error.FromCollection = true
                _r.message.FromCollection = "Please select From Collection";
                is_error = true;
            }
            else if (!_r?.FromColumnName) {

                _r.error.FromColumnName = true
                _r.message.FromColumnName = "Please select From Column";
                is_error = true;
            }

            else if (!_r?.ToCollection?.db_objectname || !_r?.ToCollection) {

                _r.error.ToCollection = true
                _r.message.ToCollection = "Please select To Collection";
                is_error = true;
            }

            else if (!_r?.ToColumnName) {

                _r.error.ToColumnName = true
                _r.message.ToColumnName = "Please select To Column Name";
                is_error = true;
            }
            // else {
            //     _r.error.FromColumnName = true;
            //     _r.message.FromColumnName = "Please select same datatype";
            // }
            //}
            return _r;
        })
        if (is_error) {
            this.setState(prevState => ({
                ...prevState,
                relations
            }));
            return
        }
       

        relations.map(value => {
            
            let entity = current.entity;
            let FromCollection = value.FromCollection.entity;
            let FromColumnName = value.FromColumnName.name;
            let ToCollection = value.ToCollection.entity;
            let ToColumnName = value.ToColumnName.name;
            let json = {
                
                status: "draft",
                entity: entity,
                entitycode:current.entitycode,
                version: "1.0",
                FromCollection: FromCollection,
                ToCollection: ToCollection,
                "FromColumnName": FromColumnName,
                "FromColumnDataType":value.FromColumnName.properties.datatype,
                "ToColumnDataType":value.ToColumnName.properties.datatype,
                "ToColumnName": ToColumnName,
                "groupId": value.groupId,
                "SeqNo": value.SeqNo,
                isDontSend: isDontSend,
            }

            if (isedit) {

          
               var t = [];
                 t = list.filter(val => val.groupId === json.groupId && val.groupId !== null) 
                 
                if(t.length > 0)
                {
                     
                    console.log("DO Nothing")
                }

                else if(json.groupId === null){

                   var newlistss = list.filter(val => val.groupId === null && val.SeqNo === json.SeqNo && val.entity === json.entity) 
                     if(newlistss.length > 0)
                     {
                        console.log("DO Nothing")
                     }

                     else{
                    list.push(json);
                }
                }

                else{
                     
                    list.push(json); 
                }
                
               
                
            } 
            else {
                list.push(json);  
                
            }
            

        })
         
        list = [...list]
        // deletelistsss = [...this.state.groupIdsRemove]
         
        setgroupRelation({
            ...relationcontext,
            list,
            deletelistsss
        })
        console.log("listtsssvalueeee" , list)
        if(this.state.groupIdsRemove.length > 0)
        {
             
            this.state.groupIdsRemove.map(async (deleteee) => {
                 
                if(deleteee.GroupId !== null)
                {
                     
                let datass = {
                  db_name: `${environment.database}`,
                  entity: 'entity_grouprelation_stg',
                  is_metadata: false,
                  filter: {
                    groupId: `${deleteee.GroupId}`
                  },
                  doc: {
                    active: "N"
                  }
                }
                let data = JSON.stringify([datass])
                var config = {
                  method: 'post',
                  url: `${environment.api_url}/api/upsert_document`,
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  data: data
                }
          
                await axios(config)
                  .then(async response => {
          
                  })
                let publish_relation = {
                  "db_name": `${environment.database}`,
                  "entity": "entity_grouprelation_published",
                  "filter": `entity_grouprelation_published.groupId=='${deleteee.GroupId}'`,
                  "metadata_dbname": `${environment.database}`
                };
          
                await axios.post(`${environment.api_url}/api/delete_document`, publish_relation)
                  .then(async res => {
          
          
                  })
                   
                  list = list.filter(val => val?.groupId !== deleteee.GroupId);
                   
                  setgroupRelation({
                     ...relationcontext,
                     list
                 });
                }
               
                // else if(deleteee.GroupId === null)
                // {
                     
                //    list = list.filter(val => val?.groupId !== null && val?.SeqNo !== deleteee?.SeqNo && val?.entity !== deleteee?.entity);
                    
                //    setgroupRelation({
                //     ...relationcontext,
                //     list
                // });
                // }
          
              })  
              console.log("listtsssvalueeeedelete" , list)

        }

        
        // call draw line
        let { setCount, count } = drawLine;
        setCount({
            ...drawLine,
            count: count + 1
        })
        
        if (onclose) {

            this.setState(prevState => ({
                ...prevState,
                snackmsg: "relationship added successfully",
                snackopen: true,
                snacktype: "success",
            }), () => {
                onclose()
            })
        }

       
       
      
    }
    addMoreField() {

        let { relations } = this.state;
        let { grouprelationshipcontext } = this.props;
        let lastValue = grouprelationshipcontext.list[grouprelationshipcontext.list.length - 1];
         
        let SeqNumber = 1;
            if (relations.length > 0) {
                SeqNumber = parseInt(relations.length) + 1;

                if(SeqNumber <= relations[relations.length - 1].SeqNo  )
                {
                    SeqNumber = relations[relations.length - 1].SeqNo + 1;
                }
            }
            else {
                SeqNumber = 1;
            }
        
         
        relations.push({
            id: uuid(),
            caption: null,
            groupId: null,
            groupName: null,
            SeqNo: SeqNumber,
            FromCollection: null,
            FromColumnName: null,
            ToCollection: null,
            ToColumnName: null,
            message: {
                FromColumnName: "Please select same datatype",
                FromCollection: "Please select table",
                ToColumnName: "Please select same datatype",
                ToCollection: "Please select table"
            },
            error: {
                FromCollection: false,
                FromColumnName: false,
                ToCollection: false,
                ToColumnName: false,
            },
        })

        this.setState({ relations })
    }
    deleteRelationship = (_val, grouprelationShipContext ,currentField) => {
        let { relations , groupIdsRemove } = this.state;  
        let relationcontext = grouprelationShipContext;
        let { list, setgroupRelation } = relationcontext;
        relations = relations.filter(_rl => _rl.id !== _val.id);
        this.setState({ relations });  
        groupIdsRemove.push({GroupId:_val.groupId, SeqNo:_val.SeqNo, entity:currentField.entity});
        this.setState({ groupIdsRemove });  

    }
    fieldLists = (entityName) => {

    }
    render() {
        let { classes, current, onclose, currentField } = this.props;
        let { relations, snackopen, snackmsg, snacktype, groupId, groupName, SeqNo } = this.state;
        let context = this.context;
        let list = this.getAlltable(Object.values(context.list))
        return (
            <>
                {snackopen && <Alerts severity={snacktype} open={snackopen} vertical={'top'} horizontal={'right'} msg={snackmsg} onclose={() => this.closeSnackBar()} />}
                <EntityGroupRelationShipContext.Consumer>
                    {
                        grouprelationShipContext => <Grid className={classes.root}>
                            <Grid md={12} container className={classes.headerRoot}>
                                <Grid item md={6} className={classes.headertitleRoot} style={{ textAlign: "left" }}>
                                    <Typography variant="h6" style={{ fontSize: "1.06rem" }}>Add Group Relationship</Typography>
                                </Grid>
                                <Grid item md={6} className={classes.headerButtonRoot} >
                                    <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => onclose()}>Cancel</Button>
                                    <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.AddGroupRelationShip(grouprelationShipContext)} variant="contained" color="secondary">Save</Button>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid item md={12} className={classes.contentRoot}>
                                {
                                    relations.length > 0 && <Grid item md={12} className={classes.boxPadding}>
                                        <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px" }}></Typography>
                                    </Grid>
                                }
                                {relations.map((val, rindex) => <React.Fragment>
                                    {
                                        rindex > 0 && <Divider />
                                    }
                                    <Grid item md={12} className={classes.boxPadding}>
                                        <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px" }}>Group Relationship</Typography>
                                    </Grid>
                                    {/* <Grid item md={12} className={classes.boxPadding}>
                                        <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>Group Id</Typography>

                                        <TextField
                                            value={val.groupId}
                                            disabled
                                            fullWidth={true}
                                            onchange={(e, value) => { this.setValue('groupId', value, rindex) }}
                                            size="small"
                                            placeholder="Group Id" variant="outlined"
                                        />
                                    </Grid> */}
                                    {/* <Grid item md={12} className={classes.boxPadding}>
                                    <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>Group Name</Typography>
                                    <TextField
                                       value={val.groupName}
                                        fullWidth={true}
                                        onChange={e => this.setValue('groupName', e.target.value, rindex)}
                                        // id="relationship-caption" 
                                        size="small"
                                        // className={classes.relationshipCaption}
                                        placeholder="Group Name" variant="outlined"
                                    />
                                </Grid> */}
                                    {/* <Grid item md={12} className={classes.boxPadding}>
                                        <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>Sequence Number</Typography>
                                        <TextField
                                           disabled
                                            value={val.SeqNo}
                                            fullWidth={true}
                                            onChange={e => this.setValue('SeqNo', e.target.value, rindex)}
                                            size="small"
                                            type='number'
                                            style={{ direction: "rtl" }}
                                            // className={classes.relationshipCaption}
                                            variant="outlined"
                                        />
                                    </Grid> */}
                                    <Divider />

                                    {
                                        val?.caption?.length > 0 ? <Grid item md={12} className={classes.boxPadding}>
                                            <TextField disabled value={val.caption} onChange={e => this.setValue('caption', e.target.value, rindex)} id="relationship-caption" size="small" className={classes.relationshipCaption} placeholder="add caption" variant="outlined" />
                                        </Grid> : ''
                                    }
                                    <Grid item md={12} className={classes.boxPadding} style={{ paddingBottom: "0px" }}>

                                        <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>From Collection</Typography>

                                        <SelectBox
                                            list={
                                                list.map(_li => {
                                                    _li.label = _li.entity;
                                                    _li.value = _li.entity;
                                                    return _li;
                                                })
                                            }
                                            classname={classes.spaceBottom15}
                                            onchange={(e, value) => { this.setValue('FromCollection', value, rindex) }}
                                            value={{ label: val.FromCollection?.entity, value: val.FromCollection?.entity }}
                                            // top_title=''
                                            title='select From Collection'
                                            error={val.error.FromCollection}
                                            message={val.message.FromCollection}

                                        />
                                    </Grid>

                                    {
                                        val.FromCollection && <Grid item md={12} className={classes.boxPadding} style={{ paddingBottom: "0px" }}>
                                            <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>From Column Name</Typography>

                                            <SelectBox
                                                list={
                                                    val.FromCollection?.fields?.map(_li => {
                                                        _li.label = _li.name;
                                                        _li.value = _li.name;
                                                        return _li;
                                                    }).filter(_fl => _fl.label !== currentField.name)
                                                    // this.fieldLists(val.FromCollection?.entity)
                                                }
                                                classname={classes.spaceBottom15}
                                                onchange={(e, value) => { this.setValue('FromColumnName', value, rindex) }}
                                                value={{ label: val.FromColumnName?.name, value: val.FromColumnName?.name }}
                                                // top_title=''
                                                title='select From Column'
                                                error={val.error.FromColumnName}
                                                message={val.message.FromColumnName}

                                            />
                                        </Grid>
                                    }

                                    <Grid item md={12} className={classes.boxPadding} style={{ paddingBottom: "0px" }}>

                                        <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>To Collection</Typography>

                                        <SelectBox
                                            list={
                                                list.map(_li => {
                                                    _li.label = _li.entity;
                                                    _li.value = _li.entity;
                                                    return _li;
                                                })
                                            }
                                            classname={classes.spaceBottom15}
                                            onchange={(e, value) => { this.setValue('ToCollection', value, rindex) }}
                                            value={{ label: val.ToCollection?.entity, value: val.ToCollection?.entity }}
                                            // top_title=''
                                            title='select To Collection'
                                            error={val.error.ToCollection}
                                            message={val.message.ToCollection}

                                        />
                                    </Grid>
                                    {
                                        val.ToCollection && <Grid item md={12} className={classes.boxPadding} style={{ paddingBottom: "0px" }}>
                                            <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>To Column Name</Typography>

                                            <SelectBox
                                                list={
                                                    val.ToCollection?.fields?.map(_li => {
                                                        _li.label = _li.name;
                                                        _li.value = _li.name;
                                                        return _li;
                                                    }).filter(_fl => _fl.label !== currentField.name && _fl.label !== "_id")
                                                    // this.fieldLists(val.FromCollection?.entity)
                                                }
                                                classname={classes.spaceBottom15}
                                                onchange={(e, value) => { this.setValue('ToColumnName', value, rindex) }}
                                                value={{ label: val.ToColumnName?.name, value: val.ToColumnName?.name }}
                                                // top_title=''
                                                title='select To Column'
                                                error={val.error.ToColumnName}
                                                message={val.message.ToColumnName}

                                            />
                                        </Grid>
                                    }
                                    <Grid item md={12} className={classes.boxPadding} style={{ textAlign: "end", paddingBottom: "0px", top: "-17px", position: "relative" }}>
                                        <Button onClick={() => this.deleteRelationship(val, grouprelationShipContext ,currentField)} variant="contained" color="secondary" className={classes.headerButton + ' ' + classes.fontFormat} >Delete</Button>
                                    </Grid>
                                </React.Fragment>)}
                                <Grid item md={12} className={classes.boxPadding}>
                                    <Button onClick={this.addMoreField} variant="contained" color="primary" className={classes.fontFormat}>More Field</Button>
                                </Grid>
                                <Divider />

                                {/* <Grid container md={12} className={classes.headerRoot}>
                                 <Grid item md={6} className={classes.headertitleRoot}>
                                 </Grid>
                                 
                             </Grid> */}
                            </Grid>
                        </Grid>
                    }
                </EntityGroupRelationShipContext.Consumer>

            </>

        )
    }
}

AddGroupRelationShip.contextType = EntityLeftContext;

export default withStyles(useStyles)(withAllContexts(AddGroupRelationShip));