export const useStyles = ((theme) => ({
    sequenceDrawerroot: {
        flexGrow: 1,
        width: "395px"
    },
    root: {
        flexGrow: 1,
        padding: "10px 10px",
    },
    tabRoot: {
        flexGrow: 1,
        borderRadius: "8px",
        padding: "0px 0px",
        "& .MuiTab-root": {
            minWidth: "99px",
            minHeight: "36px"
        },
        "& .MuiTabs-root": {
            minHeight: "35px",
            borderRadius: "8px"
        },
        "& .Mui-selected": {
            background: "#3f51b5 !important",
            color: "white !important"
        }
    },
    spaceTop: {
        marginTop: "15px"
    },
    spaceLeft5: {
        paddingLeft: 5
    },
    spaceRight5: {
        paddingRight: 5
    },
    spaceBottom15: {
        marginBottom: "15px"
    },
    addNewTable: {
        cursor: "pointer"
    },
    spaceBottom5: {
        marginBottom: "5px"
    },
    headTitle: {
        fontSize: "14px",
        display: "grid",
        gridTemplateColumns: "28px 1fr"
    },
    headIcon: {
        fontSize: "18px",
        color: "#a0a0a0",
        margin: "auto"
    },
    chipList: {
        padding: "13px 8px",
        marginRight: "7px",
        marginBottom: "10px"
    },
    dividerLine: {
        width: "100%",
        height: "2px",
        marginBottom: "9px",
        marginTop: "9px"
    },
    selectBoxOverideStyle:{
        'div > select ':{
            marginBottom: "5px !important"
        }
    },
    btnAction:{
        marginLeft:"5px"
    },
    headerRoot: {
        padding: "9px 7px"
    },
    headertitleRoot: {
        display: "flex",
        margin: "auto"
    },
    headertitle: {
        fontWeight: 600
    },
    headerButtonRoot: {
        textAlign: "right"
    },
    headerButton: {
        height: "26px",
        marginLeft: "10px"
    },
    deleteFont:{
        color: "white",
        background: "#f50057",
        padding: "5px",
        textTransform: "capitalize",
        fontSize: "12px",
        '&:hover': {
            background: "#f50057",
        }
    },
    fontFormat:{
        textTransform: "capitalize"
    }
}));