/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 5/1/2021
 * @modify 5/1/2021
 * @desc Exporting all the components from /src/functions
**/

const LeftSide = "left", rightSide = "right";

/* position on dragging element */
var Point = function (x, y) {
    this.x = x;
    this.y = y;
}

export const iscanvasOrNot = () => {
    var canvas = document?.getElementsByClassName('IframeData')[0]?.contentWindow.document.getElementById("canvas");
    if (!canvas || !canvas.getContext) {
        return false
    }
    return true
}


/* draw canvas lines base function */
export const drawingLine = (relationList) => {
    if (!document.getElementsByClassName('IframeData')[0]) {
        return false
    }
    var canvas = document.getElementsByClassName('IframeData')[0].contentWindow.document.getElementById("canvas");
    if (!canvas || !canvas.getContext) {
        return false
    }
    var ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    relationList.map(val => {
        if(val?.attributes?.isDontShow){
            return false
        }
        let start = getComponentPoints(val?.attributes?.currentField?.attributes?.id);
        let end = getComponentPoints(val?.attributes?.targetTableColumn?.attributes?.id);
        let dataPoint = comparePoints(start, end);
        start = dataPoint.startp;
        end = dataPoint.endp;
        drawLine(new Point(start[0], start[1]), new Point(end[0], end[1]), 'blue', ctx, dataPoint?.startSide, dataPoint?.endSide);
        canvas_arrow(ctx, end[0], end[1], 'blue', dataPoint?.endSide);
        return false;
    })
}
/* clear canvas lines */
export const clearCanvasDrawLines = () => {
    if (document.getElementsByClassName('IframeData').length > 0) {
        var canvas = document.getElementsByClassName('IframeData')[0].contentWindow.document.getElementById("canvas");
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
}
/* draw canvas lines */
function drawLine(stPoint, endPoint, color, ctx, startside, endtside) {
    ctx.beginPath();
    if (stPoint.x > endPoint.x) {
        let temp = stPoint;
        stPoint = endPoint;
        endPoint = temp;
    }
    if (startside === LeftSide && endtside === LeftSide && ((stPoint.x + 300) > endPoint.x) && ((stPoint.x - 100) < endPoint.x)) {
        ctx.moveTo(stPoint.x - 10, stPoint.y);
        ctx.bezierCurveTo(stPoint.x - 100, stPoint.y, (((endPoint.x) / 4) * 3), endPoint.y, endPoint.x - 10, endPoint.y);
    } else {
        ctx.moveTo(stPoint.x, stPoint.y);
        ctx.bezierCurveTo(stPoint.x + 60, stPoint.y, (((endPoint.x) / 4) * 3), endPoint.y, endPoint.x - 10, endPoint.y);
    }
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
}
/* get components from and to top and left position points */
export const getComponentPoints = (id) => {
    let component = document.getElementsByClassName('IframeData')[0].contentWindow.document.getElementsByClassName("relation-connection" + id)[0]
    if (component) {
        let client = component.getBoundingClientRect();
        let left = client.x - 10
        let top = client.y + 14;
        return [left, top, client.width];
    }
    return [0, 0, 300]
}
/* compare components  from and to top and left position points and change line left or roght to start */
export const comparePoints = (start, end) => {
    let endPoint = end[0] + end[2];
    let startPoint = start[0] + start[2];
    let endp = end;
    let startp = start;
    let startSide = LeftSide;
    let endSide = LeftSide;
    if (endPoint < start[0]) {
        endSide = rightSide;
        endp = [endPoint + 20, end[1], end[2]]
    }
    if (startPoint < end[0]) {
        startSide = rightSide;
        startp = [startPoint + 20, start[1], start[2]]
    }

    return { startp, endp, endSide, startSide }
}
/* draw arrow to position */
export const canvas_arrow = (ctx, fromx, fromy, color, side) => {
    fromx = fromx - 20;
    fromy = fromy - 10;
    ctx.beginPath();
    if (side === rightSide) {
        fromx = fromx + 30
        fromy = fromy + 3
        ctx.moveTo(fromx, fromy);
        ctx.lineTo(fromx, fromy + 15);
        ctx.lineTo(fromx - 15, fromy + 8);
    } else {
        fromy = fromy + 1
        ctx.moveTo(fromx, fromy);
        ctx.lineTo(fromx, fromy + 15);
        ctx.lineTo(fromx + 15, fromy + 8);
    }
    ctx.fillStyle = color;
    ctx.fill();
}

