import React from 'react'
import {
  makeStyles,
  OutlinedInput,
  InputAdornment,
  Avatar,
  Grid,
  TextField,
  IconButton
} from '@material-ui/core'
import SimpleBackdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import Divider from '@material-ui/core/Divider'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FlipToFrontIcon from '@material-ui/icons/FlipToFront'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
const useStyles = makeStyles(theme => ({
  parentDiv: {
    height: 'fit-content',
    width: 270
  },
  moreIcon: {
    fontSize: 20
  },
  inline: {
    fontSize: '.9rem',
    fontWeight: 600,
    color: '#9696A9',
    alignItems: 'center',
    display: 'flex'
  },
  moreIconbtn: {
    padding: 10
  },
  inputFieldChipsDiv: {
    marginLeft: '5%',
    marginBottom: '3%'
  },
  inputFieldChips: {
    fontWeight: 300,
    padding: '2px 10px',
    backgroundColor: 'white',
    border: '.8px solid gray',
    marginRight: '2%',
    borderRadius: 4,
    color: 'gray',
    fontSize: '.68rem',
    marginBottom: '2%',
    '&:focus': {
      border: '.8px solid gray',
      outline: 'none'
    },
    '&:active': {
      border: '.8px solid gray'
    },
    '&:hover': {
      border: '.8px solid gray'
    }
  },
  inputavt: {
    width: '25px',
    height: '25px',
    cursor: 'pointer'
  },
  stepChild: {
    height: 'fit-content',
    minHeight: '46px',
    minWidth: '276px',
    maxWidth: '244px',
    position: 'relative',
    borderRadius: 8,
    marginBottom: '2%',
    marginRight: '2%',
    '& .MuiOutlinedInput-multiline': {
      padding: '14px 0px 0px 7px',
      fontSize: '14px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:hover': {
      '& svg': {
        display: 'block'
      }
    }
  },
  drag: {
    position: 'absolute',
    right: 8,
    '& svg': {
      display: 'none'
    }
  },
  ListItemTitle: {
    paddingLeft: 6,
    borderBottom: '1px solid #d2d2d2'
  },
  checkboxList: {
    userSelect: 'none',
    justifyContent: 'space-between',
    marginLeft: 0,
    height: 36,
    '& .MuiIconButton-label': {
      padding: 6
    }
  },
  collapseDiv: {
    '& .MuiListItem-gutters': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  kpiPopup: {
    padding: '2px 4px'
  },
  flag: {
    transform: 'rotate(184deg)',
    width: 0,
    height: 0,
    borderLeft: '9px solid transparent',
    borderRight: '0px solid transparent',

    position: 'absolute',
    left: 0,
    top: 0
  },

  serhead: {
    backgroundColor: '#e3e6e48f',
    padding: '8px 15px'
  },
  colorhead: {
    padding: '10px 16px'
  },
  divi: {
    marginLeft: '15px',
    marginRight: '15px'
  },
  calgrid: {
    paddingRight: '25px',
    paddingLeft: '7px'
  },
  iconbtn: {
    border: '1px solid #d3d3d3',
    marginTop: '3px',
    marginBottom: '3px',
    width: '10px',
    height: '10px',
    backgroundColor: '#fff',
    marginLeft: '10px',
    '& svg': {
      fontSize: '16px'
    },
    boxShadow: '0 22px 24px 0 #110f4714',
    padding: 14,
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  radiobtn: {
    display: 'block',
    paddingLeft: '24px',

    '& .MuiFormControlLabel-root': {
      marginLeft: '0px'
    }
  },
  radiobtn1: {
    '& .MuiRadio-root': {
      color: 'red',
      padding: '0px'
    }
  },
  radiobtn2: {
    '& .MuiRadio-root': {
      color: 'green',
      padding: '0px'
    }
  },
  radiobtn3: {
    '& .MuiRadio-root': {
      color: 'yellow',
      padding: '0px'
    }
  },
  radiobtn4: {
    '& .MuiRadio-root': {
      color: 'darkblue',
      padding: '0px'
    }
  },
  radiobtn5: {
    '& .MuiRadio-root': {
      // backgroundColor: "black",
      color: 'black',
      padding: '0px'
    }
  },
  modalbox: {
    backgroundColor: 'transparent'
  },
  btns: {
    display: 'block'
  },
  descrip: {
    margin: '12px 0px 12px 0px',
    fontSize: 15,
    fontWeight: 'bold',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  textfild: {
    fontSize: '13px',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  textfild2: {
    width: '447px',
    backgroundColor: '#dadada8f',
    marginBottom: '80px',
    margin: '0px 15px',
    marginTop: '10px',
    border: 'none',
    '& .MuiInputBase-root.Mui-disabled': {
      height: '46px',
      fontSize: '14px',
      color: '#000000c4'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  save: {
    color: 'red',
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  mod: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  },
  papermodal: {
    height: '518px',
    boxShadow: '0 22px 24px 0 rgba(17, 15, 71, 0.08)',
    border: 'solid 1px rgba(17, 15, 71, 0.03)',
    borderRadius: '6px',
    right: '0%',
    bottom: '0%',
    position: 'absolute',
    width: 485,
    backgroundColor: theme.palette.background.paper,
    outline: 0,
    '& .SimpleBackdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  },
  modalhead: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '-10px',
    marginBottom: '-10px'
  },
  service: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  calender: {
    fontSize: 12,
    color: 'grey',
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingRight: '15px'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalRoot: {
    backgroundColor: 'rgba(255,255,255,0.2)'
  },
  savehead: {
    marginTop: '-10px',
    marginBottom: '-10px'
  },
  addrelation: {
    backgroundColor: '#f50057',
    marginRight: '25px',
    minWidth: '60px',
    maxWidth: '150px',
    marginTop: '4px',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      height: '24px',
      borderRadius: '8px',
      '& svg': {
        color: 'white',
        fontSize: '22px'
      }
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-14px',
      fontSize: '12px',
      fontWeight: '700',
      color: 'white',
      marginLeft: '-5px'
    }
  },
  menuitem: {
    color: 'white',
    backgroundColor: '#f50057',
    fontSize: '12px'
  },
  menulist: {
    color: 'white',
    fontSize: '12px'
  },
  selectlist: {
    color: 'white',
    backgroundColor: '#f50057',
    fontSize: '12px'
  }
}))

export default function Input (props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [color, setcolor] = React.useState('')
  const [edit, setedit] = React.useState('')

  const handleColor = v => {
    setcolor(v)
  }
  const handleSave = v => {
    setOpen(false)
    props.colorChange(color && color, props.id, edit, props.datauppend)
    setedit('')
  }
  const handleRadioChange = event => {
    setValue(event.target.value)
  }
  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div
      className={classes.stepChild}
      style={{ border: '.125rem solid ' + props.borderColor }}
    >
      <div className={classes.drag} style={{ display: 'flex' }}>
        <div
        style={{
          zIndex: 10
        }}
          onClick={() =>
            props.ondelete(props.data.id, props.parentid, props.datauppend)
          }
        >
          {' '}
          <DeleteOutlineIcon
            style={{
              fontSize: 20,
              color: '#909090',
              paddingTop: 4,
              cursor: 'pointer',
              margin:"0px 6px",
              zIndex: 10
            }}
          />
        </div>
        <FlipToFrontIcon
          style={{
            fontSize: 20,
            color: '#909090',
            paddingTop: 4
          }}
        />
      </div>
      <OutlinedInput
        // multiline
        id='outlined-adornment'
        type={'text'}
        placeholder={props.placeholder}
        value={props.data ? props.data?.value : ''}
        onDoubleClick={handleOpen}
        onChange={e =>
          props.onChange(e, props.data.id, props.parentid, props.datauppend)
        }
        startAdornment={
          <InputAdornment position='start'>
            <Avatar
              alt='Remy Sharp'
              src='https://picsum.photos/200/300?random=5'
              className={classes.inputavt}
            />
          </InputAdornment>
        }
      />
      <span
        className={classes.flag}
        style={{ borderBottom: '9px solid ' + props.borderColor }}
      />
      <Modal
        className={classes.mod}
        open={open}
        onClose={handleOpen}
        BackdropComponent={SimpleBackdrop}
      >
        <div className={classes.papermodal}>
          <Grid
            justify='space-between'
            alignItems='center'
            container
            className={classes.serhead}
          >
            <Grid className={classes.modalhead}>
              <p className={classes.service}>
                {props.data ? props.data?.value : ''}
              </p>
              <IconButton className={classes.iconbtn}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton className={classes.iconbtn}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid>
              <IconButton className={classes.iconbtn}>
                <CloseOutlinedIcon onClick={handleOpen} />
              </IconButton>
            </Grid>
          </Grid>
          <p className={classes.descrip}>Description</p>
          <TextField
            className={classes.textfild}
            multiline
            rows={4}
            fullWidth={true}
            value={edit ? edit : props?.data?.value}
            onChange={e => setedit(e.target.value)}
            variant='outlined'
          />
          <Grid
            justify='space-between'
            alignItems='center'
            container
            className={classes.colorhead}
          >
            <Grid className={classes.modalhead}>
              <p className={classes.service}>Color</p>

              <RadioGroup
                className={classes.radiobtn}
                value={value}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value='red'
                  control={<Radio />}
                  className={classes.radiobtn1}
                  onClick={e => handleColor(e.target.value)}
                />
                <FormControlLabel
                  value='green'
                  control={<Radio />}
                  className={classes.radiobtn2}
                  onClick={e => handleColor(e.target.value)}
                />
                <FormControlLabel
                  value='yellow'
                  control={<Radio />}
                  className={classes.radiobtn3}
                  onClick={e => handleColor(e.target.value)}
                />
                <FormControlLabel
                  value='blue'
                  control={<Radio />}
                  className={classes.radiobtn4}
                  onClick={e => handleColor(e.target.value)}
                />
                <FormControlLabel
                  value='black'
                  control={<Radio />}
                  className={classes.radiobtn5}
                  onClick={e => handleColor(e.target.value)}
                />
              </RadioGroup>
            </Grid>
            <Grid className={classes.savehead}>
              <span className={classes.save} onClick={() => handleSave()}>
                Save
              </span>
            </Grid>
          </Grid>
          <Divider className={classes.divi} />
          <Grid justify='space-between' alignItems='center' container>
            <Grid>
              <p className={classes.descrip}>Relationship</p>
            </Grid>
          </Grid>
          <Grid
            justify='space-between'
            alignItems='center'
            container
            className={classes.calgrid}
          >
            <Grid className={classes.modalhead}>
              <IconButton>
                <CalendarTodayOutlinedIcon style={{ fontSize: '16px' }} />
              </IconButton>
              <p className={classes.calender}>Calender</p>
            </Grid>
            <Grid>
              <IconButton className={classes.iconbtn}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton className={classes.iconbtn}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TextField
            className={classes.textfild2}
            disabled
            defaultValue='Service Professional update their availability'
            variant='outlined'
          />
        </div>
      </Modal>
    </div>
  )
}
