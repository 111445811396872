import React from 'react'
import {
  Grid,
  Paper,
  Button,
  Avatar,
  Divider,
  Typography,
  Badge
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { withAllContexts } from '../../../HOCs'
import axios from 'axios'
import environment from '../../../config'
import { Alerts } from '../../../components';
import  {  entityType } from  '../../../functions/createEntity';
import {
  SaveSchema,
  saveResourceDataTypes,
  getUploadedArray,
  ExecuteAql,
  createEntity
} from '../../../functions'
const useStyles = () => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  paper: {
    borderRadius: 0,
    padding: '10px 10px'
  },
  button: {
    height: '30px',
    width: '187px',
    color: 'unset !important',
    marginRight: 10,
    borderRadius: '6px'
  },
  buttonactive: {
    height: '30px',
    width: '187px',
    marginRight: 10,
    borderRadius: '6px',
    color: '#fff !important',
    background: '#0b78fa !important',
    boxShadow: '0 15px 23px 0 #110f4703',
    border: '1px solid #eee',
    fontSize: 12,
    '&:hover': {
      boxShadow: '0 15px 23px 0 #110f4703',
      border: '1px solid #eee'
    }
  },
  nonebuttonactive: {
    color: '#000 !important',
    height: '30px',
    width: '187px',
    marginRight: 10,
    background: '#fff !important',
    borderRadius: '6px',
    boxShadow: '0 15px 23px 0 #110f4703',
    border: '1px solid #eee',
    fontSize: 12,
    '&:hover': {
      boxShadow: '0 15px 23px 0 #110f4703',
      border: '1px solid #eee'
    }
  },
  middle: {
    textAlign: 'center'
  },
  avatar: {
    width: 27,
    height: 27,
    fontSize: 13
  },
  right: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: 14
  },
  divider: {
    margin: '0px 10px'
  },
  left: {
    display: 'flex'
  },
  dbName: {
    fontSize: 15
  },
  buttonFontStyle: {
    textTransform: 'none'
  },
  buttonBackground: {
    background: '#fafafb !important'
  },
  saveButtonPadding: {
    margin: '10px 0px 0px 10px',
    top: '48px',
    position: 'absolute',
    borderRadius: '7px',
    right: '150px'
  },
  small: {
    width: 32,
    height: 32
  },
  overlayRoot: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    zIndex: 15,
    background: '#33323240'
  },
  overlaygrid: {
    margin: 'auto'
  },
  overLayPaper: {
    borderRadius: '50%',
    padding: '104px 65px'
  },
  overlayText: {
    fontSize: '67px',
    minWidth: '179px',
    textAlign: 'center'
  }
})
const StyledBadge = withStyles(theme => ({
  badge: {
    right: -6,
    top: 13,
    color: '#9696a9'
  }
}))(Badge)
const headpersona = [
  {
    id: '1',
    imgUrl: 'https://picsum.photos/200/300?random=7'
  },
  {
    id: '2',
    imgUrl: 'https://picsum.photos/200/300?random=8'
  },
  {
    id: '3',
    imgUrl: 'https://picsum.photos/200/300?random=9'
  },
  {
    id: '4',
    imgUrl: 'https://picsum.photos/200/300?random=4'
  }
]
class SecondTopNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      saveLoad: false,
      publishLoad: false,
      totalCount: 0,
      isOpenOverlay: false,
      uploadedCount: 0,
      saveerr: false,
      savesucc: false,
      snackbar:{
        severity:'success',
        open:false,
        message:''
      },
      username:'',
      marginTop:"0px",
      IsLockScreen:false,
      saveopen:false

    }
    this.publishMasters = this.publishMasters.bind(this)
    this.saveResource = this.saveResource.bind(this)
  }
   async componentDidMount(){
    if (this.props.data && this.props.data.length > 0) {
    let params = new URLSearchParams(this.props.data)
    let screenPayload = {
      db_name: `${environment.database}`,
      entity: "ScreenLock",
      metadata_dbname: `ATP_Platform`,
      filter: `ScreenLock.metadataid=='${params.get('metadata_id')}'`,
      return_fields: `{ScreenLock}`
  }
  var base64Url = params.get('token').split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  let getTokenDetails = JSON.parse(jsonPayload);

  let ScreenLock_details = {};
  await axios
      .post(`${environment.api_url}/api/read_documents`, screenPayload)
      .then(async (response) => {
          if (response?.data?.Code === 201) {
              ScreenLock_details = response?.data?.result;
          } else {
          }
      })
      if(ScreenLock_details.length > 0)
      {
  const Result = ScreenLock_details[ScreenLock_details.length - 1];
 
  if (Result.ScreenLock.IsLocked) {  
     
    const Result = ScreenLock_details[ScreenLock_details.length - 1];
    if(Result.ScreenLock.userId === getTokenDetails.preferred_username)
    {
       
    this.setState({IsLockScreen : false})     
    }

    else{
       
      this.setState({IsLockScreen : true})     
      this.setState({
        saveopen: true,
        snackmsg: `Screen Locked By ${Result.ScreenLock.userId}`,
        snacktype: 'error',
        autoHideDuration: 5000,
        isSave: true
    })
    }
  }
  else {   
      
    this.setState({IsLockScreen : false})      
  }
  }
 
}

    
   }

  
  checkValidation = (lists)=>{
    let error = {
      status:false,
      message:''
    }
    //REGEX validation of enterprise moduler fields
    for(let i=0;i<entityType.length;i++){
      for(let j=0;j<lists[entityType[i].name].length;j++){
        if(lists[entityType[i]?.name][j]?.data.value){
          lists[entityType[i].name][j].data.value = lists[entityType[i]?.name][j]?.data?.value?.trim();
        }
        if(!lists[entityType[i]?.name][j]?.data.value || lists[entityType[i]?.name][j]?.data.value.length<=0 || !/([A-Za-z]{1}[\w\-]+)$/y.test(lists[entityType[i]?.name][j]?.data.value)){
          
          error.status  =  true;
          if(!lists[entityType[i]?.name][j]?.data.value || lists[entityType[i]?.name][j]?.data.value.length<=0){
          error.message = ` Not allow empty field to ${entityType[i].value}/${lists[entityType[i]?.name][j]?.data.value}`;

          }
          else{
            error.message = ` First character should not  be special character to ${entityType[i].value}/${lists[entityType[i]?.name][j]?.data.value}`;

          }
          break;
        }
      }
      if(error.status){
        break;
      }
    }
    //check the duplication fields
    let enterpriseNewData = [];
    if(!error.status){
      //create new array of enterprise moduler list data, entitytype use to take key value
      entityType.map(_key=>{
        enterpriseNewData = [...enterpriseNewData,...lists[_key.name]]
      });
      //check duplicate from newArray
      for(let _i=0;_i<enterpriseNewData.length;_i++){
        // let error_check  = false;
        for(let _j=_i+1;_j<enterpriseNewData.length;_j++){
          if(enterpriseNewData[_j]?.data?.value ===enterpriseNewData[_i]?.data?.value){
            error.status = true;
            error.message = `Not Allow Duplicate field name '${enterpriseNewData[_i]?.data?.value}' `;
            break;
          }
        }
        //break condition check
        if(error.status){
          break;
        }
      }
    }
    if(!error.status){
      if(enterpriseNewData.length<=0){
        error.status = true;
        error.message = 'Please add enterprise moduler fields';
      }
    }
    return error;
    
  }
  saveEnterprise = async () => {
     
    let {
      datajson,
      enterpriseVSentity,
      customTemplate,
      masterDatas
    } = this.props
    let { Data } = datajson;
    if (enterpriseVSentity !== 'entity_templates') {
      let  errorFound = this.checkValidation(Data);
      if(errorFound.status){
        this.setState(prevState=>({
          ...prevState,
          snackbar:{
            open:true,
            message:errorFound.message,
            severity:'info'
          }
        }));
        return;
      }
    }
    
    this.setState({ saveLoad: true });

    let getType = getDataType(enterpriseVSentity)
    let template = {
      custom: customTemplate.customTemplate
      // masters : masterDatas.masters
    }
    let dataList = {
      db_name: `${environment.database}`,
      entity: getType.collection,
      is_metadata: false,
      projectname: sessionStorage.getItem('metadataname')
        ? sessionStorage.getItem('metadataname')
        : 'CRAYOND3',
      doc: {
        metadataId: sessionStorage.getItem('metadataId')
          ? sessionStorage.getItem('metadataId')
          : '0f9dc6ce-1d9b-4abd-a83b-462e319f7aa4',
        [getType.jsonName]: sessionStorage.getItem('projectId')
          ? sessionStorage.getItem('projectId')
          : 'ad8c5c3e-161f-4635-8b46-4b857fa6071e',
        projectname: sessionStorage.getItem('metadataname')
          ? sessionStorage.getItem('metadataname')
          : 'CRAYOND3',
        attributes:
          enterpriseVSentity === 'enterprise_modeler' ? Data : template
      }
    }
    let data = JSON.stringify([dataList])
    var config = {
      method: 'post',
      url: `${environment.api_url}/api/upsert_document`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }
    if (enterpriseVSentity === 'entity_templates') {
      this.publishMasters()
      await this.saveResource()
      return false
    }
    await axios(config)
      .then(async response => {
        this.setState({ 
          saveLoad: false
         });
        let { totalCount, uploadedCount } = this.state
        await createEntity(response, this.props)
        if (uploadedCount === totalCount) {
          this.setState(prevState=>({
            ...prevState,
            snackbar:{
             ... prevState.snackbar,
             open:true,
             severity:'success',
             message:'Save successfully'
            }
          }));
        }
      })
      .catch(err => {
        this.setState(prevState=>({
          ...prevState,
          snackbar:{
           ... prevState.snackbar,
           open:true,
           severity:'error',
           message:'something  went wrong'
          }
        }));
      })
  }

  publishMasters = () => {
    const { masterEntity } = this.props.masterDatas
    let savingDataCount = getUploadedArray(masterEntity)
    if (savingDataCount.length === 0) {
      return false
    }
    this.setState({
      isOpenOverlay: true,
      totalCount: savingDataCount.length,  
      saveLoad: true
    })
    let c = 1
    if (c) {
      this.publishMasterLoopFun(savingDataCount, 0)
    }
  }

  saveResource = async () => {
    this.setState({ saveLoad: true })
    await saveResourceDataTypes(this.props)
    this.setState({ 
      saveLoad: false,
      snackbar:{
        severity:'success',
        message:'Resource save successfully',
        open:true
      }
     })
  }

  publishMasterLoopFun = async (arr, index) => {
    await SaveSchema([arr[index]], '2683048d-67c1-46c3-89cd-417366eb3f05')
    if (arr[index + 1]) {
      this.setState(
        {
          uploadedCount: index + 1
        },
        () => {
          this.publishMasterLoopFun(arr, index + 1)
        }
      )
    } else {
      this.setState({
        uploadedCount: 0,
        totalCount: 0,
        uploadedCount: index + 1,
        isOpenOverlay: false
      })
      const { masterDatas } = this.props
      let master_data = await ExecuteAql()
      masterDatas.setMasters({
        ...masterDatas,
        masterEntity: master_data
      })
      alert('save succesfully')
    }
  }
  snackBarClose = () =>{
    this.setState(prevState=>({
      ...prevState,
      snackbar:{
       ... prevState.snackbar,
       open:false
      }
    }));
  }
  closeSnackBar = () => {
    this.setState({ snackopen: false, saveopen: false })
}
  render () {
    let { classes, handleTab, enterpriseVSentity, value } = this.props
    let { saveLoad, totalCount, saveopen,snacktype,isOpenOverlay,autoHideDuration, snackmsg ,uploadedCount,snackbar , IsLockScreen } = this.state

    return (
      <>
      <Paper className={classes.paper} >
        {snackbar.open && (
          <Alerts
            severity={snackbar.severity}
            open={snackbar.open}
            vertical={'top'}
            horizontal={'right'}
            msg={snackbar.message}
            onclose={() => this.snackBarClose()}
          />
        )}
        {saveopen && (
                                <Alerts
                                    severity={snacktype}
                                    open={saveopen}
                                    vertical={'top'}
                                    horizontal={'right'}
                                    msg={snackmsg}
                                    autoHideDuration={autoHideDuration}
                                    onclose={() => this.closeSnackBar()}
                                />
                            )}
        {/* {this.state.saveerr && (
          <Alerts
            severity={'info'}
            open={this.state.saveerr}
            vertical={'top'}
            horizontal={'right'}
            msg={'Something went wrong!'}
            onclose={() => this.setState({ saveerr: false })}
          />
        )} */}
        
        {/* <Grid container className={classes.root}> */}
        <Grid container className={classes.root} style={!IsLockScreen ? {} : {marginTop:"50px"}}>
          <Grid item md={3} className={classes.left}>

            <Typography>{this.props.username}  </Typography>
            <Divider
              orientation='vertical'
              flexItem
              className={classes.divider}
            />
            {localStorage.getItem("version")}
            
          </Grid>
          <Grid item md={6} className={classes.middle}>
            <Button
              className={
                enterpriseVSentity === 'enterprise_modeler'
                  ? classes.buttonactive
                  : classes.nonebuttonactive
              }
              variant='contained'
              onClick={() => handleTab('enterprise_modeler')}
            >
              Enterprise Modeler
            </Button>
            <Button
              className={
                enterpriseVSentity === 'definition_panel'
                  ? classes.buttonactive
                  : classes.nonebuttonactive
              }
              variant='contained'
              color='primary'
              onClick={() => handleTab('definition_panel')}
            >
              Definition Panel
            </Button>
            <Button
              className={
                enterpriseVSentity === 'entity_templates'
                  ? classes.buttonactive
                  : classes.nonebuttonactive
              }
              variant='contained'
              color='primary'
              onClick={() => handleTab('entity_templates')}
            >
              Entity Templates
            </Button>
          </Grid>
          <Grid item md={3} className={classes.right}>
            {enterpriseVSentity !== 'definition_panel' && (
              <Button
                onClick={() => this.saveEnterprise()}
                size='small'
                className={classes.saveButtonPadding}
                variant='contained'
                color='secondary'
                disabled={saveLoad}
              >
                {saveLoad
                  ? `Saving...`
                  : value !== 0 && enterpriseVSentity !== 'enterprise_modeler'
                  ? `Save`
                  : `Save & publish`}
              </Button>
            )}

            {/* <AvatarGroup className={'topnav-avatar'} max={4}>
              <Avatar className={classes.avatar}>A</Avatar>
              <Avatar className={classes.avatar}>A</Avatar>
              <Avatar className={classes.avatar}>A</Avatar>
              <Avatar className={classes.avatar}>A</Avatar>
              <Avatar className={classes.avatar}>A</Avatar>
            </AvatarGroup> */}
            {/* <StyledBadge badgeContent={11} max={4}>
              <AvatarGroup max={4}>
                {headpersona.map((item, id) => {
                  return (
                    <Avatar
                      key={id}
                      alt='Remy Sharp'
                      src={item.imgUrl}
                      className={classes.small}
                    />
                  )
                })}
              </AvatarGroup>
            </StyledBadge> */}
            {/* <Divider
              orientation='vertical'
              flexItem
              className={classes.divider}
            />
            <Avatar className={classes.avatar}>A</Avatar> */}
          </Grid>
        </Grid>
        {isOpenOverlay && (
          <Grid className={classes.overlayRoot}>
            <Grid className={classes.overlaygrid}>
              <Paper className={classes.overLayPaper}>
                <Grid>
                  <Typography className={classes.overlayText}>
                    {uploadedCount}/{totalCount}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
      </>
    )
  }
}
const getDataType = value => {
  switch (value) {
    case 'enterprise_modeler': {
      return {
        collection: 'EnterpriseModuler',
        jsonName: 'EnterpriceModelerid'
      }
    }
    default: {
      return {
        collection: 'DataTypes',
        jsonName: 'dataTypesId',
        mid: '7008dba4-2e0c-4d6a-be65-1ea3f55a8037',
        pid: 'a14917a7-3a25-4053-ab3b-dbda7076c838'
      }
    }
  }
} 

export default withStyles(useStyles)(withAllContexts(SecondTopNav))