/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/12/202 
 * @modify 12/12/202 
 * @desc Providing the ThemeContext from /src/context which is used in /src/App.js
 */

import React, { useEffect } from "react";
import {
    ResouceTemplates, CustomTemplate, SelectedTemplate, MasterTemplates, ConfirmationContext
} from "../";
import axios from 'axios';
import environment from '../../config';
import { ExecuteAql } from '../../functions';
import { Confirmation } from '../../components';

const resourceJson = [{ "entity": "asasas", "status": "draft", "db_objectname": "asasas", "entity_group_name": "", "attributes": { "isresource": true, "id": "a0c4946c-838d-4b7c-838e-4d18200d29e7", "selected_color": "red" }, "fields": [{ "name": "asas", "properties": { "datatype": "Date" }, "fieldType": "f", "attributes": { "id": "97bb1cc3-3cad-4071-a5ad-a2fdb7368e2a", "table": "asasas", "tableID": "a0c4946c-838d-4b7c-838e-4d18200d29e7", "selected_color": "red" } }, { "name": "sasasa", "properties": { "datatype": "Integer" }, "fieldType": "f", "attributes": { "id": "0fcb65d8-7aa8-4146-8d85-8360edc19e29", "table": "asasas", "tableID": "a0c4946c-838d-4b7c-838e-4d18200d29e7", "selected_color": "red" } }, { "name": "asasa12", "properties": { "datatype": "DigitalObject" }, "fieldType": "f", "attributes": { "id": "f3e38335-f0c6-4a88-9bbe-22fa61c96913", "table": "asasas", "tableID": "a0c4946c-838d-4b7c-838e-4d18200d29e7", "selected_color": "red" } }] }]
const TemplateContext = (props) => {

    let [resouceTemplate, setResouceTemplate] = React.useState({
        resources: []
    });
    let [customEnity, setCustomEnity] = React.useState({
        customTemplate: []
    });
    let [masterData, setMasters] = React.useState({
        masters: {},
        masterEntity: []
    });
    let [selectedTemplate, setTemplateId] = React.useState({
        templateId: null,
        isDefault: null,
    });
    let [confirm, setConfirm] = React.useState({
        open: false,
        title: null,
        dialog: null,
        type: null,
        entity: null,
        data: null
    });
    useEffect(() => {
        async function getMasters() {
            let master_data = await ExecuteAql("2683048d-67c1-46c3-89cd-417366eb3f05");
            if (master_data) {
                setMasters({
                    ...masterData,
                    masterEntity: master_data ?? []
                })
            }
        }
        getMasters()
        async function initail() {
            axios
                .post(`${environment.api_url}/api/read_documents`, {
                    "db_name": `${environment.database}`,
                    "entity": "DataTypes",
                    "projectname": "QdmMasterEntity",
                    "metadataId": "a14917a7-3a25-4053-ab3b-dbda7076c838",
                    "filter": "DataTypes.dataTypeId == '158df5b0-422e-4fb4-99cf-921b8ad45c72'",
                    "return_fields": "{DataTypes}"
                })
                .then((response) => {
                    if (response.data.result.length > 0) {
                        setCustomEnity({
                            customTemplate: response.data.result[response.data.result.length - 1].DataTypes.attributes
                        })
                    }

                })
                .catch((err) => { });
            axios
                .post(`${environment.api_url}/api/read_documents`, {
                    "db_name": `${environment.database}`,
                    "entity": "Resources",
                    "projectname": "QdmMasterEntity",
                    "metadataId": "dad8c864-dc51-4907-b0f7-0df9b6cb6f98",
                    "filter": "Resources.resouceId == '80797261-9fb6-45cf-9d68-e5ec50cf270b' limit 1",
                    "return_fields": "{Resources}"
                })
                .then((response) => {
                    if (response.data.result.length > 0) {
                        let res = response.data.result[response.data.result.length - 1].Resources.attributes
                        setResouceTemplate({
                            resources: res
                        })
                    }

                })
                .catch((err) => { });
        }
        initail()
    }, [sessionStorage.projectId])
    return (
        <CustomTemplate.Provider value={{ ...customEnity, setCustomEnity }}>
            <ResouceTemplates.Provider value={{ ...resouceTemplate, setResouceTemplate }}>
                <SelectedTemplate.Provider value={{ ...selectedTemplate, setTemplateId }}>
                    <MasterTemplates.Provider value={{ ...masterData, setMasters }}>
                        <ConfirmationContext.Provider value={{ ...confirm, setConfirm }}>
                            {props.children}
                            <Confirmation />
                        </ConfirmationContext.Provider>
                    </MasterTemplates.Provider>
                </SelectedTemplate.Provider>
            </ResouceTemplates.Provider>
        </CustomTemplate.Provider>
    );
};

export default TemplateContext;

const customContext = [{ "entity": "tets", "attributes": { "id": "58616ab2-218d-4b79-9fe7-84e3d60f2646", "selected_color": "red" }, "fields": [{ "name": "testesd", "properties": { "datatype": "Date" }, "fieldType": "f", "attributes": { "id": "792e0ac3-b28a-4c57-a735-4924b0308f63", "table": "tets", "tableID": "58616ab2-218d-4b79-9fe7-84e3d60f2646", "selected_color": "red" } }, { "name": "asasqe", "properties": { "datatype": "String" }, "fieldType": "f", "attributes": { "id": "6f1242c5-16bb-48a9-add4-9ed865f8eecf", "table": "tets", "tableID": "58616ab2-218d-4b79-9fe7-84e3d60f2646", "selected_color": "red" } }] }, { "entity": "testing template", "attributes": { "id": "1950dc0e-b5db-445d-816e-eb1ccfb73c01", "selected_color": "red" }, "fields": [{ "name": "test", "properties": { "datatype": "String" }, "fieldType": "f", "attributes": { "id": "b621693e-1fa5-4196-88a9-4ae9b41ce271", "table": "testing template", "tableID": "1950dc0e-b5db-445d-816e-eb1ccfb73c01", "selected_color": "red" } }, { "name": "test", "properties": { "datatype": "Date" }, "fieldType": "f", "attributes": { "id": "73bfcd64-01ee-49ec-8f58-999dae33ce1f", "table": "testing template", "tableID": "1950dc0e-b5db-445d-816e-eb1ccfb73c01", "selected_color": "red" } }] }]