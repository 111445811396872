/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 5/1/2021 
 * @modify 5/1/2021 
 * @desc GraphQL client setup done here using the Apollo Client.
**/

import React, { useContext } from "react";
import { EntityRelationShipContext, EntityGroupRelationShipContext ,DrawLineContext } from './contexts'
import { drawingLine, clearCanvasDrawLines, iscanvasOrNot } from './functions';
import Xarrow from 'react-xarrows';

const DrawLine = (props) => {
    const relation = useContext(EntityRelationShipContext);
    const grouprelation = useContext(EntityGroupRelationShipContext);
    const lineContext = useContext(DrawLineContext);
    React.useEffect(() => {
        // if (relation.list.length > 0) {
            if (iscanvasOrNot()) {
               
                callDraw()
                
            }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineContext.count])


    const callDraw = () => {
        setTimeout(() => {
            if (iscanvasOrNot()) {
                console.log(relation,'well done');
                if (relation.list.length >= 0) {
                    drawingLine(relation.list)
                }
            }else{
                callDraw() 
            }
        }, 300)
    }

    
    // let canvas = document.getElementsByClassName('IframeData')[0];
    React.useEffect(() => {
        callDraw();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [relation.list.length>0])
    React.useEffect(() => {
        clearCanvasDrawLines(relation.list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineContext.clearCount])
    return (
        <React.Fragment>
                {/* {relation.list.map((ar, i) => (
                  ar?.attributes?.currentField?.attributes?.id && 
                  ar?.attributes?.targetTableColumn?.attributes?.id &&
        <Xarrow
          start={ar?.attributes?.currentField?.attributes?.id}
          end={ar?.attributes?.targetTableColumn?.attributes?.id}
          // key={ar.start + '-.' + ar.start}
          path={'smooth'}
          strokeWidth={3}
          lineColor={'#007aff'}
          color={'#007aff'}
          headColor={'#007aff'}
        //   style={{ position: 'relative' }}
          // passProps={{ onClick: e => MorePop(e, ar, i) }}
        />
       ))}  */}
            {props.children}
        </React.Fragment> 
    );
};

export default DrawLine;
